import React, {useRef, useState} from 'react';
import {type ButtonHandle} from '../../components/Button/Button';
import Router from '../../routes/Router';
import RouteNames from '../../routes/RouteNames';
import './Dashboard.css';
import IconButton from '../../components/IconButton/IconButton';
import CircleAvatar from '../../components/CircleAvatar/CircleAvatar';
import Space from '../../components/Space/Space';
import logout from '../../assets/logout.svg';
import toiLogo from '../../assets/toiLogo.png';
import Home from '../../assets/icons/home';
import RestaurantSvg from '../../assets/icons/restaurants';
import OrdersSvg from '../../assets/icons/orders';
import FoodsSvg from '../../assets/icons/foods';
import InventorySvg from '../../assets/icons/inventory';
import DeliveriesSvg from '../../assets/icons/deliveries';
import PluginsSvg from '../../assets/icons/plugins';
import PaymentsSvg from '../../assets/icons/payments';
import OverruleSvg from '../../assets/icons/overrule';
import SettingsSvg from '../../assets/icons/settings';
import MenuButton from '../../components/MenuButton/MenuButton';
import Restaurants from '../Restaurants/Restaurants';
import Overrule from '../Overrule/Overrule';
import Loading from '../Loading/Loading';
import AccessDenied from '../AccessDenied/AccessDenied';
import {useAuthContext} from '../../context/authContext';
import Foods from '../Foods/Foods';
import {usePluginContext} from '../../context/pluginContext';
import Inventory from '../Inventory/Inventory';
import Deliveries from '../Deliveries/Deliveries';
import DirectPosSvg from '../../assets/icons/directpos';
import TableSvg from '../../assets/icons/table';
import Orders from '../Orders/Orders';
import PrinterSvg from '../../assets/icons/printer';
import BillSvg from '../../assets/icons/bill';
import Settings from '../Settings/Settings';
import AuthService from '../../services/auth';
import HomeOwner from '../Home/HomeOwner';
import Plugins from '../Plugins/Plugins';
import Payments from '../Payments/Payments';

const Dashboard = () => {
	const authRes = useAuthContext();
	const plugins = usePluginContext();

	const SpaceBetweenMenuItems = 5;
	const TopMenuIconSize = 15;

	// Assets
	const home = new Home();
	const restaurants = new RestaurantSvg();
	const orders = new OrdersSvg();
	const foods = new FoodsSvg();
	const inventorySvg = new InventorySvg();
	const deliveries = new DeliveriesSvg();
	const pluginSvg = new PluginsSvg();
	const payments = new PaymentsSvg();
	const overrule = new OverruleSvg();
	const settingSvg = new SettingsSvg();

	const [selMenuIndex, setMenuIndex] = useState<number>(1);

	const onLogoutBtn = useRef<ButtonHandle>(null);

	const onLogout = async () => {
		authRes.onLoad(true);
		await new AuthService().logout().then(val => {
			authRes.onLoad(false);
			if (!val.hasError && val.res) {
				localStorage.clear();
				Router.navigateTo!(RouteNames.auth);
			}
		});
	};

	const handleOnMenuClick = (i?: number) => {
		if (selMenuIndex !== i) {
			setMenuIndex(i!);
		}
	};

	const ownerPage = () => <div className='Dashboard'>
		<div className='dash-left-panel'>
			<Space size={15} isAutoResize={true}></Space>
			<MenuButton title='Home' index={1} selIndex={selMenuIndex} icon={home.white()} onClick={handleOnMenuClick} ></MenuButton>
			<Space size={SpaceBetweenMenuItems} isAutoResize={false}></Space>
			<MenuButton title='Restaurants' index={2} selIndex={selMenuIndex} icon={restaurants.white()} onClick={handleOnMenuClick} ></MenuButton>
			<Space size={SpaceBetweenMenuItems} isAutoResize={false}></Space>
			<MenuButton title='Orders' index={3} selIndex={selMenuIndex} icon={orders.white()} onClick={handleOnMenuClick} ></MenuButton>
			<Space size={SpaceBetweenMenuItems} isAutoResize={false}></Space>
			<MenuButton title='Foods' index={4} selIndex={selMenuIndex} icon={foods.white()} onClick={handleOnMenuClick} ></MenuButton>
			{plugins.whiteList.isInventoryAllowed && <Space size={SpaceBetweenMenuItems} isAutoResize={false}></Space>}
			{plugins.whiteList.isInventoryAllowed && <MenuButton title='Inventory' index={5} selIndex={selMenuIndex} icon={inventorySvg.white()} onClick={handleOnMenuClick} ></MenuButton>}
			<Space size={SpaceBetweenMenuItems} isAutoResize={false}></Space>
			<MenuButton title='Deliveries' index={6} selIndex={selMenuIndex} icon={deliveries.white()} onClick={handleOnMenuClick} ></MenuButton>
			<Space size={SpaceBetweenMenuItems} isAutoResize={false}></Space>
			<MenuButton title='Plugins' index={7} selIndex={selMenuIndex} icon={pluginSvg.white()} onClick={handleOnMenuClick} ></MenuButton>
			<Space size={SpaceBetweenMenuItems} isAutoResize={false}></Space>
			<MenuButton title='Payments' index={8} selIndex={selMenuIndex} icon={payments.white()} onClick={handleOnMenuClick} ></MenuButton>
			<Space size={SpaceBetweenMenuItems} isAutoResize={false}></Space>
			<MenuButton title='Overrule' index={9} selIndex={selMenuIndex} icon={overrule.white()} onClick={handleOnMenuClick} ></MenuButton>
			<Space size={SpaceBetweenMenuItems} isAutoResize={false}></Space>
			<MenuButton title='Settings' index={10} selIndex={selMenuIndex} icon={settingSvg.white()} onClick={handleOnMenuClick} ></MenuButton>
		</div>
		<div className='dash-right-panel'>
			<div className='dash-top-panel'>
				<div className='dash-top-panel-left'>
					<IconButton hasSplashBg={true} index={1} selIndex={selMenuIndex} icon={home.white()} iconSize={TopMenuIconSize} onClick={handleOnMenuClick}></IconButton>
					<Space size={SpaceBetweenMenuItems} isAutoResize={false}></Space>
					<IconButton hasSplashBg={true} index={2} selIndex={selMenuIndex} icon={restaurants.white()} iconSize={TopMenuIconSize} onClick={handleOnMenuClick}></IconButton>
					<Space size={SpaceBetweenMenuItems} isAutoResize={false}></Space>
					<IconButton hasSplashBg={true} index={3} selIndex={selMenuIndex} icon={orders.white()} iconSize={TopMenuIconSize} onClick={handleOnMenuClick}></IconButton>
					<Space size={SpaceBetweenMenuItems} isAutoResize={false}></Space>
					<IconButton hasSplashBg={true} index={4} selIndex={selMenuIndex} icon={foods.white()} iconSize={TopMenuIconSize} onClick={handleOnMenuClick} ></IconButton>
					{plugins.whiteList.isInventoryAllowed && <Space size={SpaceBetweenMenuItems} isAutoResize={false}></Space>}
					{plugins.whiteList.isInventoryAllowed && <IconButton hasSplashBg={true} index={5} selIndex={selMenuIndex} icon={inventorySvg.white()} iconSize={TopMenuIconSize} onClick={handleOnMenuClick}></IconButton>}
					<Space size={SpaceBetweenMenuItems} isAutoResize={false}></Space>
					<IconButton hasSplashBg={true} index={6} selIndex={selMenuIndex} icon={deliveries.white()} iconSize={TopMenuIconSize} onClick={handleOnMenuClick} ></IconButton>
					<Space size={SpaceBetweenMenuItems} isAutoResize={false}></Space>
					<IconButton hasSplashBg={true} index={7} selIndex={selMenuIndex} icon={pluginSvg.white()} iconSize={TopMenuIconSize} onClick={handleOnMenuClick} ></IconButton>
					<Space size={SpaceBetweenMenuItems} isAutoResize={false}></Space>
					<IconButton hasSplashBg={true} index={8} selIndex={selMenuIndex} icon={payments.white()} iconSize={TopMenuIconSize} onClick={handleOnMenuClick}></IconButton>
					<Space size={SpaceBetweenMenuItems} isAutoResize={false}></Space>
					<IconButton hasSplashBg={true} index={9} selIndex={selMenuIndex} icon={overrule.white()} iconSize={TopMenuIconSize} onClick={handleOnMenuClick}></IconButton>
					<Space size={SpaceBetweenMenuItems} isAutoResize={false}></Space>
					<IconButton hasSplashBg={true} index={10} selIndex={selMenuIndex} icon={settingSvg.white()} iconSize={TopMenuIconSize} onClick={handleOnMenuClick}></IconButton>
				</div>
				<div className='dash-top-panel-right'>
					<div className='dash-top-namecard'>
						<label className='namecard-head'>{`${authRes.data!.firstName} ${authRes.data!.lastName}`}</label>
						<Space size={3} isAutoResize={false}></Space>
						<div className='namecard-sub'>
							<IconButton ref={onLogoutBtn} icon={logout} onClick={onLogout}></IconButton>
							<Space size={5} isAutoResize={false}></Space>
							<label className='namecard-sub-role'>Owner</label>
						</div>
					</div>
					<Space size={5} isAutoResize={false}></Space>
					<CircleAvatar img={toiLogo}></CircleAvatar>
					<Space size={10} isAutoResize={false}></Space>
				</div>
			</div>
			<div className='dash-right-content'>
				{(() => {
					switch (selMenuIndex) {
						case 1:
							return <HomeOwner />;
						case 2:
							return <Restaurants />;
						case 3:
							return <Orders />;
						case 4:
							return <Foods />;
						case 5:
							return plugins.whiteList.isInventoryAllowed ? <Inventory /> : <div />;
						case 6:
							return <Deliveries />;
						case 7:
							return <Plugins />;
						case 8:
							return <Payments />;
						case 9:
							return <Overrule />;
						case 10:
							return <Settings />;
						default:
							return <div />;
					}
				})()}
			</div>
		</div>
	</div >;

	return (
		authRes.isLoading || plugins.isLoading
			? <Loading />
			: authRes.isAccessApproved ? ownerPage() : <AccessDenied />
	);
};

export default Dashboard;
