class OrdersSvg {
	svgRaw = `<svg width="26" height="26" viewBox="0 0 26 26" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M23.4999 8.16117L17.2617 1.92293C15.9535 0.61469 14.1495 -0.0876282 12.3042 0.00876839L5.41864 0.339271C2.66446 0.46321 0.474875 2.65279 0.337166 5.39321L0.00666303 12.2787C-0.0759626 14.124 0.612585 15.928 1.92082 17.2362L8.15906 23.4745C10.7205 26.0359 14.8793 26.0359 17.4545 23.4745L23.4999 17.429C26.0751 14.8814 26.0751 10.7226 23.4999 8.16117ZM9.27451 13.2289C7.08493 13.2289 5.30848 11.4524 5.30848 9.26284C5.30848 7.07326 7.08493 5.29681 9.27451 5.29681C11.4641 5.29681 13.2406 7.07326 13.2406 9.26284C13.2406 11.4524 11.4641 13.2289 9.27451 13.2289Z" fill="#CF4D4D"/>
    </svg>`;

	replaceColor = '#CF4D4D';

	public normal() {
		return `data:image/svg+xml;utf8,${encodeURIComponent(this.svgRaw)}`;
	}

	public white() {
		return `data:image/svg+xml;utf8,${encodeURIComponent(this.svgRaw.replaceAll(this.replaceColor, '#FFF3F3'))}`;
	}
}

export default OrdersSvg;
