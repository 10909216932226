import React, {createContext} from 'react';
import {type RestaurantModel} from '../models/restaurants';
import RestaurantsService from '../services/restaurants';

export type RestaurantContextResult = {
	data: RestaurantModel[];
	isLoading: boolean;
	fetchData: () => Promise<RestaurantModel[]>;
	setLoading: (b: boolean) => void;
};

type RestaurantProps = {
	children: React.ReactNode;
};

export const RestaurantContext = createContext<RestaurantContextResult>({
	data: [],
	isLoading: true,
	async fetchData() {
		return [];
	},
	setLoading() {
		//
	},
});

type RestaurantState = {
	data: RestaurantModel[];
	isLoading: boolean;
};

export class RestaurantProvider extends React.Component<RestaurantProps, RestaurantState> {
	constructor(props: RestaurantProps) {
		super(props);

		this.state = {
			data: [],
			isLoading: true,
		};
	}

	async componentDidMount() {
		await this.fetchRest();
	}

	fetchRest = async () => {
		if (localStorage.getItem('AUT-OWNER')) {
			if (!this.state.isLoading) {
				this.setState({isLoading: true});
			}

			await new RestaurantsService().get().then(val => {
				if (!val.hasError && val.res !== undefined) {
					if (val.res.data) {
						this.setState({data: val.res.data});
					}
				}
			}).finally(() => {
				this.setState({isLoading: false});
			});
		}
	};

	render(): React.ReactNode {
		return (
			<RestaurantContext.Provider
				value={{
					data: this.state.data,
					fetchData: async () => {
						await this.fetchRest();
						return this.state.data;
					},
					setLoading: (l: boolean) => {
						this.setState({
							isLoading: l,
						});
					},
					isLoading: this.state.isLoading,
				}}>
				{this.props.children}
			</RestaurantContext.Provider>
		);
	}
}
