import React from 'react';
import './HomeOwner.css';
import {useAuthContext} from '../../context/authContext';
import OrdersInfo from '../Analytics/Orders/OrdersInfo';
import InventoryInfo from '../Analytics/Inventory/InventoryInfo';
import Space from '../../components/Space/Space';
import RevenueInfo from '../Analytics/Revenue/RevenueInfo';
import EmployeeInfo from '../Analytics/Employee/EmployeeInfo';

const HomeOwner = () => {
	const authRes = useAuthContext();

	return (
		<div className='HomeOwner'>
			<div className='HomeOwner-column'>
				<Space size={30} isAutoResize={false}></Space>
				<OrdersInfo></OrdersInfo>
				{/* <Space size={30} isAutoResize={false}></Space>
				<RevenueInfo></RevenueInfo>
				<Space size={30} isAutoResize={false}></Space>
				<InventoryInfo></InventoryInfo>
				<Space size={30} isAutoResize={false}></Space>
				<EmployeeInfo></EmployeeInfo>
				<Space size={30} isAutoResize={false}></Space> */}
			</div>
		</div >
	);
};

export default HomeOwner;

