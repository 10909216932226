import axios, {type AxiosError} from 'axios';
import endpoints from '../utils/endpoints';
import messages from '../utils/messages';
import {type ResponseData, type ServiceResponse} from '../models/response';
import {serviceError, serviceSuccess} from '../utils/serviceHandler';
import {type OrderHistoryDetails, type OrderHistory, type OrdersModel} from '../models/orders';

type ServerMessage = {
	message: string;
};

class OrdersService {
	get = async (restId: string): Promise<ServiceResponse<ResponseData<OrdersModel[]>>> => {
		try {
			const response = await axios.post<ResponseData<OrdersModel[]>>(endpoints.orders,
				{restId},
				{
					headers: {
						Authorization: `Bearer ${localStorage.getItem('AUT-OWNER')!}`,
						'Content-Type': 'application/json',
					},
				});
			await serviceSuccess();
			return {hasError: false, res: response.data};
		} catch (error) {
			const axiosError = error as AxiosError<ServerMessage>;
			if (axiosError.response?.data?.message) {
				await serviceError(axiosError.response?.data?.message);

				// Access the error message from the server response
				const errorMessage = axiosError.response.data.message;
				return {hasError: true, errorMsg: errorMessage};
			}

			return {hasError: true, errorMsg: messages.Ise};
		}
	};

	history = async (restId: string): Promise<ServiceResponse<ResponseData<OrderHistory[]>>> => {
		try {
			const response = await axios.post<ResponseData<OrderHistory[]>>(endpoints.orderhistory,
				{restId},
				{
					headers: {
						Authorization: `Bearer ${localStorage.getItem('AUT-OWNER')!}`,
						'Content-Type': 'application/json',
					},
				});
			await serviceSuccess();
			return {hasError: false, res: response.data};
		} catch (error) {
			const axiosError = error as AxiosError<ServerMessage>;
			if (axiosError.response?.data?.message) {
				await serviceError(axiosError.response?.data?.message);

				// Access the error message from the server response
				const errorMessage = axiosError.response.data.message;
				return {hasError: true, errorMsg: errorMessage};
			}

			return {hasError: true, errorMsg: messages.Ise};
		}
	};

	historydets = async (orderId: string): Promise<ServiceResponse<ResponseData<OrderHistoryDetails>>> => {
		try {
			const response = await axios.post<ResponseData<OrderHistoryDetails>>(endpoints.orderhistorydet,
				{orderId},
				{
					headers: {
						Authorization: `Bearer ${localStorage.getItem('AUT-OWNER')!}`,
						'Content-Type': 'application/json',
					},
				});
			await serviceSuccess();
			return {hasError: false, res: response.data};
		} catch (error) {
			const axiosError = error as AxiosError<ServerMessage>;
			if (axiosError.response?.data?.message) {
				await serviceError(axiosError.response?.data?.message);

				// Access the error message from the server response
				const errorMessage = axiosError.response.data.message;
				return {hasError: true, errorMsg: errorMessage};
			}

			return {hasError: true, errorMsg: messages.Ise};
		}
	};

	acrj = async (req: {
		orderId: string;
		acceptOrder: boolean;
		preparationTime: number;
		rejectNote: string | undefined;
	}): Promise<ServiceResponse<ResponseData<undefined>>> => {
		try {
			const response = await axios.patch<ResponseData<undefined>>(endpoints.acceptorrej,
				{
					orderId: req.orderId,
					acceptOrder: req.acceptOrder,
					preparationTime: req.preparationTime,
					rejectNote: req.rejectNote ?? null,
				},
				{
					headers: {
						Authorization: `Bearer ${localStorage.getItem('AUT-OWNER')!}`,
						'Content-Type': 'application/json',
					},
				});
			await serviceSuccess();
			return {hasError: false, res: response.data};
		} catch (error) {
			const axiosError = error as AxiosError<ServerMessage>;
			if (axiosError.response?.data?.message) {
				await serviceError(axiosError.response?.data?.message);

				// Access the error message from the server response
				const errorMessage = axiosError.response.data.message;
				return {hasError: true, errorMsg: errorMessage};
			}

			return {hasError: true, errorMsg: messages.Ise};
		}
	};

	takeawaystatus = async (orderId: string): Promise<ServiceResponse<ResponseData<undefined>>> => {
		try {
			const response = await axios.patch<ResponseData<undefined>>(endpoints.takestate,
				{
					orderId,
				},
				{
					headers: {
						Authorization: `Bearer ${localStorage.getItem('AUT-OWNER')!}`,
						'Content-Type': 'application/json',
					},
				});
			await serviceSuccess();
			return {hasError: false, res: response.data};
		} catch (error) {
			const axiosError = error as AxiosError<ServerMessage>;
			if (axiosError.response?.data?.message) {
				await serviceError(axiosError.response?.data?.message);

				// Access the error message from the server response
				const errorMessage = axiosError.response.data.message;
				return {hasError: true, errorMsg: errorMessage};
			}

			return {hasError: true, errorMsg: messages.Ise};
		}
	};

	takeawaycomp = async (req: {
		orderId: string;
		codAmount: number;
		paymentModeId: string;
	}): Promise<ServiceResponse<ResponseData<undefined>>> => {
		try {
			const response = await axios.patch<ResponseData<undefined>>(endpoints.takecompl,
				req,
				{
					headers: {
						Authorization: `Bearer ${localStorage.getItem('AUT-OWNER')!}`,
						'Content-Type': 'application/json',
					},
				});
			await serviceSuccess();
			return {hasError: false, res: response.data};
		} catch (error) {
			const axiosError = error as AxiosError<ServerMessage>;
			if (axiosError.response?.data?.message) {
				await serviceError(axiosError.response?.data?.message);

				// Access the error message from the server response
				const errorMessage = axiosError.response.data.message;
				return {hasError: true, errorMsg: errorMessage};
			}

			return {hasError: true, errorMsg: messages.Ise};
		}
	};

	deldispatch = async (req: {
		orderId: string;
		delServiceId: string;
		quoteId: string | undefined;
		manifest: {
			size: string;
			length: number;
			height: number;
			depth: number;
			weight: number;
		} | undefined;
	}): Promise<ServiceResponse<ResponseData<undefined>>> => {
		try {
			const response = await axios.patch<ResponseData<undefined>>(endpoints.assigndel,
				{
					orderId: req.orderId,
					delServiceId: req.delServiceId,
					quoteId: req.quoteId ?? null,
					manifest: req.manifest ?? null,
				},
				{
					headers: {
						Authorization: `Bearer ${localStorage.getItem('AUT-OWNER')!}`,
						'Content-Type': 'application/json',
					},
				});
			await serviceSuccess();
			return {hasError: false, res: response.data};
		} catch (error) {
			const axiosError = error as AxiosError<ServerMessage>;
			if (axiosError.response?.data?.message) {
				await serviceError(axiosError.response?.data?.message);

				// Access the error message from the server response
				const errorMessage = axiosError.response.data.message;
				return {hasError: true, errorMsg: errorMessage};
			}

			return {hasError: true, errorMsg: messages.Ise};
		}
	};
}

export default OrdersService;
