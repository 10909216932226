class SettingsSvg {
	svgRaw = `<svg width="26" height="24" viewBox="0 0 26 24" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M23.0038 8.463C20.7035 8.463 19.763 6.83621 20.9068 4.84085C21.5677 3.6843 21.1737 2.21002 20.0172 1.54914L17.8184 0.290918C16.8144 -0.30642 15.5181 0.049441 14.9207 1.05348L14.7809 1.29495C13.6371 3.29031 11.7561 3.29031 10.5996 1.29495L10.4598 1.05348C9.88784 0.049441 8.59149 -0.30642 7.58746 0.290918L5.38875 1.54914C4.2322 2.21002 3.83821 3.69701 4.49909 4.85356C5.65564 6.83621 4.71515 8.463 2.41477 8.463C1.093 8.463 0 9.54329 0 10.8778V13.1146C0 14.4364 1.08029 15.5294 2.41477 15.5294C4.71515 15.5294 5.65564 17.1562 4.49909 19.1515C3.83821 20.3081 4.2322 21.7824 5.38875 22.4432L7.58746 23.7015C8.59149 24.2988 9.88784 23.9429 10.4852 22.9389L10.625 22.6974C11.7688 20.7021 13.6498 20.7021 14.8063 22.6974L14.9461 22.9389C15.5435 23.9429 16.8398 24.2988 17.8439 23.7015L20.0426 22.4432C21.1991 21.7824 21.5931 20.2954 20.9322 19.1515C19.7757 17.1562 20.7162 15.5294 23.0166 15.5294C24.3383 15.5294 25.4313 14.4491 25.4313 13.1146V10.8778C25.4186 9.556 24.3383 8.463 23.0038 8.463ZM12.7093 16.1267C10.4343 16.1267 8.57878 14.2712 8.57878 11.9962C8.57878 9.72122 10.4343 7.86566 12.7093 7.86566C14.9843 7.86566 16.8398 9.72122 16.8398 11.9962C16.8398 14.2712 14.9843 16.1267 12.7093 16.1267Z" fill="#CF4D4D"/>
    </svg>`;

	replaceColor = '#CF4D4D';

	public normal() {
		return `data:image/svg+xml;utf8,${encodeURIComponent(this.svgRaw)}`;
	}

	public white() {
		return `data:image/svg+xml;utf8,${encodeURIComponent(this.svgRaw.replaceAll(this.replaceColor, '#FFF3F3'))}`;
	}
}

export default SettingsSvg;
