// Const base = 'https://localhost:4040/api';
const base = 'https://api.tasteofindia.nz/api';

const endpoints = {
	basews: 'https://api.tasteofindia.nz',
	local: 'https://localhost:5312',
	register: `${base}/owner/send-otp`,
	verifyOtp: `${base}/owner/verify-otp`,
	userDetails: `${base}/owner/user-details`,
	taxes: `${base}/owner/taxes`,
	addtax: `${base}/owner/tax`,
	contacts: `${base}/owner/contacts`,
	sendotpcontact: `${base}/owner/contact`,
	verifyotpcontact: `${base}/owner/verify-contact`,
	resendcontactotp: `${base}/owner/resend-contact-otp`,
	cities: `${base}/owner/cities`,
	addcity: `${base}/owner/city`,
	cityavail: `${base}/owner/city-availability`,
	deliverycharges: `${base}/owner/delivery-charges`,
	deliverycharge: `${base}/owner/delivery-charge`,
	conditions: `${base}/owner/conditions`,
	sitings: `${base}/owner/sitings`,
	siting: `${base}/owner/siting`,
	linksitings: `${base}/owner/link-sitings`,
	restauranttypes: `${base}/owner/restaurant-types`,
	plugins: `${base}/owner/plugins`,
	restaurants: `${base}/owner/restaurants`,
	addrestaurant: `${base}/owner/restaurant`,
	restaurantstatus: `${base}/owner/restaurant-status`,
	restaurantavail: `${base}/owner/restaurant-availability`,
	restauranttimes: `${base}/owner/restaurant-timeslots`,
	restaurantholi: `${base}/owner/restaurant-holiday`,
	restaurantremholi: `${base}/owner/restaurant-remove-holiday`,
	assignplugin: `${base}/owner/assign-plugin`,
	plugindistribute: `${base}/owner/plugin-distribute`,
	plugintrigger: `${base}/owner/trigger-plugin`,
	categories: `${base}/owner/categories`,
	category: `${base}/owner/category`,
	customizations: `${base}/owner/customizations`,
	customization: `${base}/owner/customization`,
	addons: `${base}/owner/addons`,
	addon: `${base}/owner/addon`,
	menus: `${base}/owner/menus`,
	menu: `${base}/owner/menu`,
	materials: `${base}/owner/materials`,
	material: `${base}/owner/material`,
	coalitions: `${base}/owner/coalitions`,
	fooditem: `${base}/owner/food-item`,
	fooditems: `${base}/owner/food-items`,
	foodavail: `${base}/owner/food-item-availability`,
	foodstock: `${base}/owner/food-item-stock`,
	foodoffer: `${base}/owner/food-item-offer`,
	fooddelist: `${base}/owner/food-item-delist`,
	findfooditems: `${base}/owner/find-food-items`,
	coupons: `${base}/owner/coupons`,
	addcoupon: `${base}/owner/coupon`,
	accesscontrols: `${base}/owner/access-controls`,
	accessapprove: `${base}/owner/approve-access`,
	accessupdate: `${base}/owner/update-access`,
	lorul: `${base}/owner/link-or-unlink`,
	changes: `${base}/owner/changes`,
	approvechange: `${base}/owner/approve-change`,
	changeslog: `${base}/owner/changes-log`,
	suppliers: `${base}/owner/suppliers`,
	supplier: `${base}/owner/supplier`,
	purchases: `${base}/owner/purchases`,
	purchase: `${base}/owner/purchase`,
	purchasestock: `${base}/owner/update-purchase-stock`,
	expenses: `${base}/owner/expenses`,
	expense: `${base}/owner/expense`,
	expensecategories: `${base}/owner/expense-categories`,
	payments: `${base}/owner/payments`,
	orders: `${base}/owner/orders`,
	orderhistory: `${base}/owner/orders-history`,
	acceptorrej: `${base}/owner/accept-or-reject-order`,
	takestate: `${base}/owner/takeaway-status`,
	takecompl: `${base}/owner/takeaway-delivered`,
	assigndel: `${base}/owner/assign-delivery`,
	orderhistorydet: `${base}/owner/order-details`,
	cancresv: `${base}/owner/canceled-reservations`,
	deliveries: `${base}/owner/deliveries`,
	dirposgetcarts: `${base}/owner/direct-carts`,
	dirposcrecart: `${base}/owner/direct-create-cart`,
	dirposaddtocart: `${base}/owner/direct-add-to-cart`,
	dirposgetcartdet: `${base}/owner/direct-cart-details`,
	dirposupcartqty: `${base}/owner/update-cart-qty`,
	dirposdelcartitem: `${base}/owner/delete-cart-item`,
	dirposdelcart: `${base}/owner/delete-cart`,
	dirposaddsit: `${base}/owner/add-siting-cart`,
	dirposaddxtrachar: `${base}/owner/add-extra-charges-cart`,
	paymodes: `${base}/owner/payment-modes`,
	delservs: `${base}/owner/delivery-services`,
	dirverifypay: `${base}/owner/verify-payment`,
	resrvgetcarts: `${base}/owner/reservation-carts`,
	resrventry: `${base}/owner/reservation-entry`,
	resrvgetcartdet: `${base}/owner/reservation-cart-details`,
	reservaddtocart: `${base}/owner/reservation-add-to-cart`,
	reservupdsit: `${base}/owner/update-siting-cart`,
	reservdelcartitem: `${base}/owner/reservation-delete-cart-item`,
	reservupcartqty: `${base}/owner/reservation-update-cart-qty`,
	reservaddxtrachar: `${base}/owner/reservation-extra-charges`,
	resrvverifypay: `${base}/owner/reservation-verify-payment`,
	printers: `${base}/owner/printers`,
	printer: `${base}/owner/printer`,
	pauseors: `${base}/owner/pause-start-print`,
	default: `${base}/owner/default-printer`,
	printstate: `${base}/owner/printer-status`,
	settings: `${base}/owner/settings`,
	updatesettings: `${base}/owner/update-settings`,
	logout: `${base}/owner/logout`,
	template: `${base}/owner/bill-template`,
	directprint: `${base}/owner/direct-print`,
	localonprint: '/on-print',
	attendance: `${base}/owner/attendance`,
};

export default endpoints;
