import React, {createContext, useContext, useEffect, useState} from 'react';
import {type PluginsMfModel, type PluginsModel} from '../models/plugins';
import PluginsService from '../services/plugins';
import {useAuthContext} from './authContext';

export type PluginContextResult = {
	data: PluginsModel[];
	isLoading: boolean;
	fetchData: () => Promise<void>;
	whiteList: {
		isInHouseDelAllowed: boolean;
		isTimeKeepAllowed: boolean;
		isLedgerAllowed: boolean;
		isInventoryAllowed: boolean;
		isDirectPosAllowed: boolean;
		isRemotePrintAllowed: boolean;
		isSitAllowed: boolean;
	};
};

const PluginContext = createContext<PluginContextResult>({
	data: [], isLoading: true, async fetchData() {
		//
	},
	whiteList: {
		isInHouseDelAllowed: false,
		isTimeKeepAllowed: false,
		isLedgerAllowed: false,
		isInventoryAllowed: false,
		isDirectPosAllowed: false,
		isRemotePrintAllowed: false,
		isSitAllowed: false,
	},
});

export function usePluginContext(): PluginContextResult {
	return useContext(PluginContext);
}

export function PluginProvider({children}: {children: JSX.Element}) {
	const authRes = useAuthContext();

	const [isLoading, setLoading] = useState<boolean>(true);
	const [data, setData] = useState<PluginsModel[]>([]);

	const [isInHouseDelAllowed, setInHouseAllowed] = useState<boolean>(false);
	const [isTimeKeepAllowed, setTimeKeepAllowed] = useState<boolean>(false);
	const [isLedgerAllowed, setLedgerAllowed] = useState<boolean>(false);
	const [isInventoryAllowed, setInventoryAllowed] = useState<boolean>(false);
	const [isDirectPosAllowed, setDirectPosAllowed] = useState<boolean>(false);
	const [isRemotePrintAllowed, setRemotePrintAllowed] = useState<boolean>(false);
	const [isSitAllowed, setSitAllowed] = useState<boolean>(false);

	useEffect(() => {
		const fetchData = async () => {
			await getPlugins();
		};

		void fetchData();
	}, []);

	const getPlugins = async () => {
		if (!isLoading) {
			setLoading(true);
		}

		await new PluginsService().get().then(val => {
			onGetPlugin(val.hasError, val.res!.data);
		}).finally(() => {
			setLoading(false);
		});
	};

	const onGetPlugin = (hasError: boolean, data: PluginsModel[] | PluginsMfModel[] | undefined) => {
		if (!hasError && data !== undefined) {
			setData(data as PluginsModel[]);
			verifyStatusOwner(data as PluginsModel[]);
		}
	};

	const fetchData = async () => {
		await getPlugins();
	};

	const verifyStatusOwner = (data: PluginsModel[]) => {
		const fdata = data.flatMap(v => v.plugins).filter(v => v.isDistributed && v.isAvailable);
		for (const d of fdata) {
			switch (d.name) {
				case 'InHouse Delivery':
					setInHouseAllowed(true);
					break;
				case 'Timekeeping':
					setTimeKeepAllowed(true);
					break;
				case 'Ledger':
					setLedgerAllowed(true);
					break;
				case 'Ingredient Inventory':
					setInventoryAllowed(true);
					break;
				case 'Direct Pos':
					setDirectPosAllowed(true);
					break;
				case 'Remote Print':
					setRemotePrintAllowed(true);
					break;
				case 'Dining':
					setSitAllowed(true);
					break;
				default:
					break;
			}
		}
	};

	return (
		<PluginContext.Provider value={{
			data, isLoading, fetchData,
			whiteList: {
				isInHouseDelAllowed,
				isTimeKeepAllowed,
				isLedgerAllowed,
				isInventoryAllowed,
				isDirectPosAllowed,
				isRemotePrintAllowed,
				isSitAllowed,
			},
		}}>
			{children}
		</PluginContext.Provider>
	);
}

