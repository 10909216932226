class Home {
	svgRaw = `<svg width='26' height='26' viewBox='0 0 26 26' fill='none' xmlns='http://www.w3.org/2000/svg'>
	<path className='svgs' d='M22.9288 6.11996L15.6119 1.00067C13.6176 -0.396663 10.5561 -0.320445 8.63802 1.1658L2.27383 6.13267C1.00354 7.1235 0 9.15598 0 10.7565V19.5215C0 22.7608 2.62952 25.403 5.86878 25.403H19.5625C22.8018 25.403 25.4313 22.7735 25.4313 19.5342V10.9216C25.4313 9.20679 24.3262 7.09809 22.9288 6.11996ZM18.9147 14.4785C18.9147 14.9739 18.5209 15.3677 18.0255 15.3677C17.5301 15.3677 17.1363 14.9739 17.1363 14.4785V14.2498L13.6811 17.705C13.4905 17.8956 13.2365 17.9845 12.9697 17.9591C12.7156 17.9337 12.4743 17.7812 12.3346 17.5653L11.0388 15.6344L8.01558 18.6577C7.83774 18.8356 7.62179 18.9118 7.39313 18.9118C7.16448 18.9118 6.93583 18.8229 6.77069 18.6577C6.42771 18.3148 6.42771 17.7558 6.77069 17.4001L10.5561 13.6147C10.7467 13.4241 11.0007 13.3352 11.2675 13.3606C11.5343 13.386 11.7756 13.5257 11.9154 13.7544L13.2111 15.6852L15.8914 13.0049H15.6627C15.1673 13.0049 14.7735 12.6111 14.7735 12.1157C14.7735 11.6203 15.1673 11.2265 15.6627 11.2265H18.0255C18.1398 11.2265 18.2541 11.2519 18.3685 11.29C18.5844 11.3789 18.7623 11.5568 18.8512 11.7727C18.902 11.8871 18.9147 12.0014 18.9147 12.1157V14.4785Z' fill='#CF4D4D'/>
</svg>`;

	replaceColor = '#CF4D4D';

	public normal() {
		return `data:image/svg+xml;utf8,${encodeURIComponent(this.svgRaw)}`;
	}

	public white() {
		return `data:image/svg+xml;utf8,${encodeURIComponent(this.svgRaw.replaceAll(this.replaceColor, '#FFF3F3'))}`;
	}
}

export default Home;
