class PluginsSvg {
	svgRaw = `<svg width="26" height="24" viewBox="0 0 26 24" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M24.5395 5.91253H17.4445C16.9486 5.91253 16.5544 5.51836 16.5544 5.02247C16.5544 4.52658 16.9486 4.13242 17.4445 4.13242H24.5395C25.0354 4.13242 25.4296 4.52658 25.4296 5.02247C25.4296 5.51836 25.0354 5.91253 24.5395 5.91253Z" fill="#CF4D4D"/>
    <path d="M5.62007 5.91253H0.890056C0.394168 5.91253 0 5.51836 0 5.02247C0 4.52658 0.394168 4.13242 0.890056 4.13242H5.62007C6.11596 4.13242 6.51012 4.52658 6.51012 5.02247C6.51012 5.51836 6.10324 5.91253 5.62007 5.91253Z" fill="#CF4D4D"/>
    <path d="M10.3494 10.0449C13.1232 10.0449 15.3719 7.79628 15.3719 5.02246C15.3719 2.24863 13.1232 0 10.3494 0C7.57554 0 5.3269 2.24863 5.3269 5.02246C5.3269 7.79628 7.57554 10.0449 10.3494 10.0449Z" fill="#CF4D4D"/>
    <path d="M24.5412 18.9207H19.8112C19.3153 18.9207 18.9211 18.5265 18.9211 18.0307C18.9211 17.5348 19.3153 17.1406 19.8112 17.1406H24.5412C25.0371 17.1406 25.4313 17.5348 25.4313 18.0307C25.4313 18.5265 25.0371 18.9207 24.5412 18.9207Z" fill="#CF4D4D"/>
    <path d="M7.98508 18.9207H0.890056C0.394168 18.9207 0 18.5265 0 18.0307C0 17.5348 0.394168 17.1406 0.890056 17.1406H7.98508C8.48096 17.1406 8.87513 17.5348 8.87513 18.0307C8.87513 18.5265 8.46825 18.9207 7.98508 18.9207Z" fill="#CF4D4D"/>
    <path d="M15.0804 23.068C17.8542 23.068 20.1028 20.8193 20.1028 18.0455C20.1028 15.2717 17.8542 13.0231 15.0804 13.0231C12.3064 13.0231 10.0579 15.2717 10.0579 18.0455C10.0579 20.8193 12.3064 23.068 15.0804 23.068Z" fill="#CF4D4D"/>
    </svg>`;

	replaceColor = '#CF4D4D';

	public normal() {
		return `data:image/svg+xml;utf8,${encodeURIComponent(this.svgRaw)}`;
	}

	public white() {
		return `data:image/svg+xml;utf8,${encodeURIComponent(this.svgRaw.replaceAll(this.replaceColor, '#FFF3F3'))}`;
	}
}

export default PluginsSvg;
