import React, {useRef, useImperativeHandle, forwardRef} from 'react';
import './TextButton.css';

type Props = {
	onClick: () => void;
	label: string;
};

type TextButtonHandle = Record<string, unknown>;

const TextButton = forwardRef<TextButtonHandle, Props>((props, ref) => {
	const btnRef = useRef(null);

	useImperativeHandle(ref, () => ({
	}));

	const handleClick = () => {
		props.onClick();
	};

	return (
		<label className='textButton' onClick={handleClick} ref={btnRef}>
			{props.label}
		</label>
	);
});

TextButton.displayName = 'TextButton';

export default TextButton;
