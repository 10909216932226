import React, {useEffect, useRef, useState} from 'react';
import './CancelReservations.css';
import Space from '../../components/Space/Space';
import ListView from '../../components/ListView/ListView';
import SnackRibbon, {type SnackRibbonHandle} from '../../components/SnackRibbon/SnackRibbon';
import {type CanReservModel} from '../../models/canreservation';
import CancelReserService from '../../services/canreserv';

type CustomStyleType = {
	'--justifyContent': string;
};

type ExtendedDivStyle = React.CSSProperties & CustomStyleType;

const CancelReservations = () => {
	const [data, setData] = useState<CanReservModel[]>([]);
	const [selItemIndex, setItemIndex] = useState<number>(-1);
	const [isListLoading, setListLoading] = useState<boolean>(true);

	const snackBarRibbon = useRef<SnackRibbonHandle>(null);

	useEffect(() => {
		void fetchCanResv();
	}, []);

	async function fetchCanResv() {
		await new CancelReserService().get().then(val => {
			if (val.hasError) {
				snackBarRibbon.current!.trigger(val.hasError, val.errorMsg!);
			} else {
				setData(val.res!.data!);
			}
		}).finally(() => {
			setListLoading(false);
		});
	}

	const customStyle: ExtendedDivStyle = {
		'--justifyContent': selItemIndex === -1 ? 'center' : 'start',
	};

	return (
		<div className='CancelReservations'>
			<div className='CancelReservations-left-panel'>
				{isListLoading ? (<div className='CancelReservations-left-list'>
					<ListView dummy={5} adapter={function (i, item): JSX.Element {
						return <div className='CancelReservations-list-adapter'>
							<label className='CancelReservations-list-adapter-index-load'>00</label>
							<Space size={5} isAutoResize={true}></Space>
							<div className='CancelReservations-list-adapter-card'>
								<label className='CancelReservations-list-adapter-update-load'>{'Tax Percentage'}</label>
							</div>
						</div>;
					}} selItemIndex={-1} adapterHeight={65} />
				</div>) : (<div className='CancelReservations-left-list'>
					<ListView data={data} adapter={function (i, item): JSX.Element {
						const itemData = item as CanReservModel;
						return <div className='CancelReservations-list-adapter'>
							<label className='CancelReservations-list-adapter-index'>{i + 1}</label>
							<Space size={5} isAutoResize={true}></Space>
							<div className='CancelReservations-list-adapter-card'>
								<label className='CancelReservations-list-adapter-perc'>{itemData.reservation.date}</label>
								<Space size={3} isAutoResize={true}></Space>
								<label className='CancelReservations-list-adapter-perc' style={{fontSize: '10px'}}>{itemData.reservation.timeSlot}</label>
								<Space size={5} isAutoResize={true}></Space>
								<label className='CancelReservations-list-adapter-perc' style={{fontSize: '10px'}}>{itemData.createdAt}</label>
							</div>
						</div>;
					}} selItemIndex={selItemIndex} onSelectItem={i => {
						setItemIndex(i);
					}} adapterHeight={65} />
				</div>)}
			</div>
			<div className='CancelReservations-right-panel'>
				<SnackRibbon ref={snackBarRibbon}></SnackRibbon>
				<div className='CancelReservations-right-content' style={customStyle}>
					{selItemIndex === -1 && <label className='CancelReservations-right-empty-content'>No items selected</label>}
					{selItemIndex !== -1 && <div>
						<label className='CancelReservations-detail-no'>{`No : ${selItemIndex + 1}`}</label>
						<Space size={5} isAutoResize={true}></Space>
						<label className='CancelReservations-detail-perc'>{data[selItemIndex].cityName}</label>
						<Space size={5} isAutoResize={true}></Space>
						<label className='CancelReservations-detail-perc'>{`Reservation Date : ${data[selItemIndex].reservation.date}`}</label>
						<Space size={5} isAutoResize={true}></Space>
						<label className='CancelReservations-detail-perc'>{`Time Slot : ${data[selItemIndex].reservation.timeSlot}`}</label>
						<Space size={5} isAutoResize={true}></Space>
						<label className='CancelReservations-detail-perc'>{data[selItemIndex].reservation.note}</label>
						<Space size={5} isAutoResize={true}></Space>
						<label className='CancelReservations-detail-perc'>{`No of persons : ${data[selItemIndex].noOfPersons}`}</label>
						<Space size={5} isAutoResize={true}></Space>
						<label className='CancelReservations-detail-perc'>{`Cancelled at : ${data[selItemIndex].canceledAt}`}</label>
					</div>}
				</div>
			</div>
		</div >
	);
};

export default CancelReservations;
