import React, {createContext} from 'react';
import {io, type Socket} from 'socket.io-client';
import {type ResKiOrder, type ResNewOrder} from '../models/orders';
import endpoints from '../utils/endpoints';
import {type ResAnalytics} from '../models/analytics';
import PrinterService from '../services/printer';
import {type ResLocalPrinter, type ResPrinter} from '../models/printer';
import * as LoDash from 'lodash';

export type PrintAckData = {
	success: boolean;
	failureReason: string;
	pjId: string;
	prnId: string;
};

export type ReqPrintData = {
	prName: string;
	isLand: boolean;
	scale: number;
	pSize: {
		height: number;
		width: number;
	} | undefined;
	htmlStr: string;
	pjId: string;
	prnId: string;
};

export type SocketResponse = {
	status: number;
	route: string;
	data: string | any;
};

export type SocketContextResult = {
	orders: ResNewOrder[];
	request: () => void;
	setLoading: (b: boolean) => void;
	fetchPrinter: (rr: boolean) => Promise<void>;
	analytics: ResAnalytics;
	isLocalLoading: boolean;
	isLocalConnected: boolean;
	cprinter: ResPrinter | undefined;
	isLoading: boolean;
	pdata: ResPrinter[];
	lprinters: ResLocalPrinter[];
};

type SocketProps = {
	children: React.ReactNode;
};

export type SocketPayload = {
	route: string;
	request: string;
	data: Record<string, unknown>;
};

export const SocketContext = createContext<SocketContextResult>({
	orders: [],
	request() {
		return undefined;
	},
	setLoading() {
		return undefined;
	},
	async fetchPrinter() {
		return undefined;
	},
	analytics: {
		total: 0,
		placed: 0,
		packed: 0,
		shipped: 0,
		revenue: 0,
	},
	isLocalLoading: true,
	isLocalConnected: false,
	cprinter: undefined,
	isLoading: true,
	pdata: [],
	lprinters: [],
});

type SocketState = {
	orders: ResNewOrder[];
	analytics: ResAnalytics;
	isLoading: boolean;
	isLocalConnected: boolean;
	isLocalLoading: boolean;
	lprinters: ResLocalPrinter[];
	pdata: ResPrinter[];
	cprinter: ResPrinter | undefined;
	prevJobId: string;
	isAck: boolean;
	printJob: ResKiOrder | undefined;
	isStatusUpdating: boolean;
};

export class SocketProvider extends React.Component<SocketProps, SocketState> {
	private wss: Socket | undefined;
	private readonly sockRequest: string[] = ['fetch-orders', 'fetch-analytics'];

	constructor(props: SocketProps) {
		super(props);

		this.state = {
			orders: [],
			analytics: {
				total: 0,
				placed: 0,
				packed: 0,
				shipped: 0,
				revenue: 0,
			},
			isLoading: true,
			lprinters: [],
			pdata: [],
			cprinter: undefined,
			isLocalConnected: false,
			isLocalLoading: true,
			prevJobId: '',
			isAck: true,
			printJob: undefined,
			isStatusUpdating: false,
		};
	}

	async componentDidMount() {
		await this.connect();
	}

	componentWillUnmount() {
		if (this.wss) {
			this.wss.close();
			this.wss.disconnect();
		}
	}

	async connect() {
		const token = localStorage.getItem('AUT-OWNER');
		if (token !== null) {
			this.wss = io(endpoints.basews, {
				autoConnect: true,
				reconnection: true,
				extraHeaders: {
					authorization: token,
				},
			});

			this._handleWebSocketSetup();
			this.onRequest();
		}
	}

	fetchPrinter = async (rr: boolean) => {
		if (!this.state.isLoading) {
			this.setState({
				isLoading: true,
			});
		}

		await new PrinterService().get().then(val => {
			if (!val.hasError && val.res) {
				if (val.res.data) {
					const cdp = val.res.data.find(v => v.isDefault);
					if (rr && !LoDash.isEqual(JSON.stringify(this.state.cprinter), JSON.stringify(cdp))) {
						this.onRequest();
					}

					this.setState({
						cprinter: cdp,
						pdata: val.res.data,
					});
				}
			}
		}).finally(() => {
			this.setState({
				isLoading: false,
			});
		});
	};

	onRequest = (mode?: string) => {
		if (this.wss) {
			if (mode === undefined || mode === this.sockRequest[1]) {
				this.wss.emit('communication', {
					route: '/owner/analytics',
					request: 'm',
					data: {},
				});
			}
		}
	};

	_handleWebSocketSetup = () => {
		if (this.wss) {
			this.wss.connect();

			this.wss.on('communication', async e => {
				const rd = JSON.parse(e as string) as SocketResponse;
				if (typeof rd.data === 'string' && this.sockRequest.includes(rd.data)) {
					// Request the data
					this.onRequest(rd.data);
				} else if (rd.route === '/owner/analytics') {
					this.setState({analytics: rd.data.data as ResAnalytics});
				}
			});
		}
	};

	render(): React.ReactNode {
		return (
			<SocketContext.Provider
				value={{
					orders: this.state.orders,
					analytics: this.state.analytics,
					isLocalConnected: this.state.isLocalConnected,
					pdata: this.state.pdata,
					lprinters: this.state.lprinters,
					setLoading: (b: boolean) => {
						this.setState({
							isLoading: b,
						});
					},
					request: () => {
						this.onRequest();
					},
					fetchPrinter: async (rr: boolean) => {
						await this.fetchPrinter(rr);
					},
					isLocalLoading: this.state.isLocalLoading,
					cprinter: this.state.cprinter,
					isLoading: this.state.isLoading,
				}}>
				{this.props.children}
			</SocketContext.Provider>
		);
	}
}
