class PaymentsSvg {
	svgRaw = `<svg width="26" height="26" viewBox="0 0 26 26" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M20.6037 6.1247H22.2708C22.0379 5.79374 21.7927 5.48729 21.5353 5.18085L20.6037 6.1247Z" fill="#CF4D4D"/>
    <path d="M20.2499 3.88133C19.9435 3.62391 19.6371 3.37876 19.3061 3.14586V4.81292L20.2499 3.88133Z" fill="#CF4D4D"/>
    <path d="M21.5487 5.182L25.1648 1.56594C25.5203 1.21046 25.5203 0.622085 25.1648 0.266608C24.8093 -0.0888693 24.2209 -0.0888693 23.8654 0.266608L20.2494 3.88267C20.7152 4.28718 21.1442 4.72846 21.5487 5.182Z" fill="#CF4D4D"/>
    <path d="M19.3082 2.14033C19.3082 1.63776 18.8914 1.22099 18.3888 1.22099C17.8985 1.22099 17.5063 1.61324 17.4818 2.0913C18.1192 2.39774 18.732 2.74096 19.3082 3.14547V2.14033Z" fill="#CF4D4D"/>
    <path d="M24.2115 7.04347C24.2115 6.5409 23.7948 6.12413 23.2922 6.12413H22.2748C22.6793 6.70025 23.0348 7.31314 23.329 7.95055C23.8193 7.92603 24.2115 7.53378 24.2115 7.04347Z" fill="#CF4D4D"/>
    <path d="M13.1772 16.5443H13.5449C14.023 16.5443 14.403 16.1152 14.403 15.5882C14.403 14.9262 14.2191 14.8282 13.8023 14.6811L13.1772 14.4604V16.5443Z" fill="#CF4D4D"/>
    <path d="M23.3389 7.95055C23.3267 7.95055 23.3144 7.9628 23.2899 7.9628H18.3867C18.2642 7.9628 18.1538 7.93829 18.0313 7.88926C17.8106 7.79119 17.6268 7.61958 17.5287 7.38669C17.4919 7.27637 17.4674 7.16604 17.4674 7.04347V2.14033C17.4674 2.12808 17.4797 2.11582 17.4797 2.0913C15.8861 1.34357 14.121 0.914551 12.2578 0.914551C5.49151 0.914551 0 6.40606 0 13.1724C0 19.9387 5.49151 25.4302 12.2578 25.4302C19.0242 25.4302 24.5157 19.9387 24.5157 13.1724C24.5157 11.3092 24.0866 9.54406 23.3389 7.95055ZM14.403 12.9517C15.1875 13.2214 16.2416 13.7975 16.2416 15.5994C16.2416 17.1317 15.0281 18.3942 13.5449 18.3942H13.1772V18.7007C13.1772 19.2032 12.7604 19.62 12.2578 19.62C11.7553 19.62 11.3385 19.2032 11.3385 18.7007V18.3942H11.2404C9.61014 18.3942 8.27404 17.0213 8.27404 15.3298C8.27404 14.8149 8.6908 14.3982 9.19337 14.3982C9.69594 14.3982 10.1127 14.8149 10.1127 15.3175C10.1127 15.9917 10.6153 16.5433 11.2404 16.5433H11.3385V13.822L10.1127 13.393C9.32821 13.1234 8.27404 12.5472 8.27404 10.7453C8.27404 9.2131 9.48756 7.95055 10.9708 7.95055H11.3385V7.65636C11.3385 7.15379 11.7553 6.73702 12.2578 6.73702C12.7604 6.73702 13.1772 7.15379 13.1772 7.65636V7.9628H13.2752C14.9055 7.9628 16.2416 9.33568 16.2416 11.0273C16.2416 11.5298 15.8249 11.9466 15.3223 11.9466C14.8197 11.9466 14.403 11.5298 14.403 11.0273C14.403 10.3531 13.9004 9.80148 13.2752 9.80148H13.1772V12.5227L14.403 12.9517Z" fill="#CF4D4D"/>
    <path d="M10.1127 10.7576C10.1127 11.4195 10.2966 11.5176 10.7134 11.6647L11.3385 11.8853V9.80148H10.9708C10.505 9.80148 10.1127 10.2305 10.1127 10.7576Z" fill="#CF4D4D"/>
    </svg>`;

	replaceColor = '#CF4D4D';

	public normal() {
		return `data:image/svg+xml;utf8,${encodeURIComponent(this.svgRaw)}`;
	}

	public white() {
		return `data:image/svg+xml;utf8,${encodeURIComponent(this.svgRaw.replaceAll(this.replaceColor, '#FFF3F3'))}`;
	}
}

export default PaymentsSvg;
