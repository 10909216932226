import React, {useEffect, useRef, useState} from 'react';
import toiLogo from '../../assets/toiLogo.png';
import './Auth.css';
import TextField, {type TextFieldHandle} from '../../components/TextField/TextField';
import OtpField, {type OtpFieldHandle} from '../../components/OtpField/OtpField';
import Space from '../../components/Space/Space';
import Button from '../../components/Button/Button';
import SnackBar, {type SnackBarHandle} from '../../components/SnackBar/SnackBar';
import TextFieldInputTypes from '../../utils/textfield/fieldtypes';
import AuthService from '../../services/auth';
import Router from '../../routes/Router';
import RouteNames from '../../routes/RouteNames';

type OtpPayload = {
	provider?: string;
	key?: string;
	otp?: number;
};

type CustomStyleType = {
	'--algin': string;
};

type ExtendedDivStyle = React.CSSProperties & CustomStyleType;

const Auth = () => {
	const [otpIsSent, setOtpIsSent] = useState<boolean>(false);
	const [otpPayload, setOtpPayload] = useState<OtpPayload>({});
	const [otpIsVerified, setOtpIsVerified] = useState<boolean>(false);
	const [verifyKey, setVerifyKey] = useState<string>('');
	const [email, setEmail] = useState<string>('');

	const snackBar = useRef<SnackBarHandle>(null);
	const mobileOrEmail = useRef<TextFieldHandle>(null);
	const otpField = useRef<OtpFieldHandle>(null);
	const firstName = useRef<TextFieldHandle>(null);
	const lastName = useRef<TextFieldHandle>(null);
	const [isLoading, setLoading] = useState<boolean>(false);

	useEffect(() => {
		cleanup();
	}, []);

	const onContinue = async () => {
		if (!isLoading) {
			setLoading(true);
		}

		if (!otpIsSent && !otpIsVerified) {
			// Call register api when otp not sent
			// validate the fields and notify with snackbar
			const field1 = mobileOrEmail.current!.validate();
			if (!field1.isValid) {
				snackBar.current!.trigger(true, field1.msg);
				setLoading(false);
			}

			if (field1.isValid) {
				if (mobileOrEmail.current) {
					setEmail(mobileOrEmail.current.getValue() ?? '');
				}

				await new AuthService().sendOtp(mobileOrEmail.current!.getValue()).then(val => {
					snackBar.current!.trigger(val.hasError, val.hasError ? val.errorMsg! : val.res!.message);
					setLoading(false);
					// Change the field to otp field
					if (!val.hasError && val.res!.data!.key) {
						setOtpPayload({
							provider: mobileOrEmail.current!.getValue()!,
							key: val.res!.data!.key,
						});
						setOtpIsSent(true);
					} else if ((val.hasError ? val.errorMsg! : val.res!.message).toLowerCase().includes('already')) {
						setOtpIsSent(true);
					}
				});
			}
		}

		if (otpIsSent && !otpIsVerified) {
			const field2 = otpField.current!.validate();
			otpField.current!.loading();
			if (!field2.isValid) {
				snackBar.current!.trigger(true, field2.msg);
				otpField.current!.completed();
				setLoading(false);
			}

			if (field2.isValid) {
				await new AuthService().verifyOtp({
					provider: otpPayload.provider!,
					key: otpPayload.key!,
					otp: Number(otpField.current!.getValue()),
				}).then(val => {
					if (val.hasError) {
						snackBar.current!.trigger(val.hasError, val.errorMsg!);
					} else {
						// Change the otp field to user details
						// for new user
						if (val.res?.data?.key) {
							setOtpIsVerified(true);
							setVerifyKey(val.res?.data.key);
						}

						// For existing user
						if (val.res?.data?.type) {
							onAuthSuccess(val.res.data.token!);
						}
					}

					setLoading(false);
					otpField.current!.completed();
				});
			}
		}

		if (!(!otpIsSent && !otpIsVerified) && !(otpIsSent && !otpIsVerified)) {
			const field3 = firstName.current!.validate();
			const field4 = lastName.current!.validate();
			if (!field3.isValid || !field4.isValid) {
				snackBar.current!.trigger(true, field3.isValid ? field4.msg : field3.msg);
				setLoading(false);
			} else {
				await new AuthService().addUserDetails({
					firstName: firstName.current!.getValue(),
					lastName: lastName.current!.getValue(),
					key: verifyKey,
				}).then(val => {
					if (val.hasError) {
						snackBar.current!.trigger(val.hasError, val.errorMsg!);
					}

					// Change the user field to dashboard page
					// for new user
					if (!val.hasError && val.res?.data?.token) {
						onAuthSuccess(val.res?.data?.token);
					}

					setLoading(false);
				});
			}
		}
	};

	const onResendOtp = async () => {
		if (!isLoading) {
			setLoading(true);
		}

		await new AuthService().sendOtp(email).then(val => {
			snackBar.current!.trigger(val.hasError, val.hasError ? val.errorMsg! : val.res!.message);
			setLoading(false);
			// Change the field to otp field
			if (!val.hasError && val.res!.data!.key) {
				setOtpPayload({
					provider: email,
					key: val.res!.data!.key,
				});
				setOtpIsSent(true);
			}
		});
	};

	const onAuthSuccess = (token: string) => {
		// Storing the token in localStorage
		localStorage.setItem('AUT-OWNER', `${token}`);
		cleanup();
		Router.navigateTo!(RouteNames.dashboard);
	};

	const cleanup = () => {
		mobileOrEmail.current?.clearInput();
		otpField.current?.clearInput();
		firstName.current?.clearInput();
		lastName.current?.clearInput();
		// OnContinueBtn.current = null;
		setOtpIsSent(false);
		setOtpPayload({});
		setOtpIsVerified(false);
		setVerifyKey('');
	};

	const customStyle: ExtendedDivStyle = {
		'--algin': otpIsVerified ? 'start' : 'center',
	};

	return (
		<div className='Auth'>
			<SnackBar ref={snackBar}></SnackBar>
			<div className='contentContainer'>
				<img src={toiLogo} alt='logo' className='toiLogo' />
				<Space size={1} isAutoResize={false}></Space>
				<label className='title'>Create Account or Login</label>
				<Space size={4} isAutoResize={false}></Space>
				{otpIsVerified ? <div className='fieldBtnGroup' style={customStyle}>
					<TextField ref={firstName} isLoading={false}
						label={'Enter your first name'}
						inputType={TextFieldInputTypes.name}></TextField>
					<Space size={5} isAutoResize={false}></Space>
					<TextField ref={lastName} isLoading={false}
						label={'Enter your last name'}
						inputType={TextFieldInputTypes.name}></TextField>
					<Space size={20} isAutoResize={false}></Space>
					<Button label={'Continue'} onClick={onContinue} isLoading={isLoading}></Button>
				</div>
					: <div className='fieldBtnGroup' style={customStyle}>
						{otpIsSent ? <OtpField ref={otpField}
							label={'Enter Mobile Number or Email'}
						></OtpField> : <TextField ref={mobileOrEmail} isLoading={false}
							label={'Enter Mobile Number or Email'}
							inputType={TextFieldInputTypes.mobileoremail}></TextField>}
						<Space size={20} isAutoResize={false}></Space>
						{otpIsSent ? <div className='rowauth'>
							<Button label={'Resend Otp'} onClick={onResendOtp} isLoading={isLoading}></Button>
							<Space size={10} isAutoResize={false}></Space>
							<Button label={'Continue'} onClick={onContinue} isLoading={isLoading}></Button>
						</div> : <Button label={'Continue'} onClick={onContinue} isLoading={isLoading}></Button>}
					</div>
				}
			</div>
			<div className='bottomTip'>
				<p className='bottomTipText'>
					@all rights reserved by <a href='https://catalizo.com' className='bottomTipLink'>catalizo</a>
				</p>
			</div>
		</div >
	);
};

export default Auth;
