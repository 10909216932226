import React, {useEffect, useRef, useState} from 'react';
import './Inventory.css';
import LabelButton from '../../components/LabelButton/LabelButton';
import SnackRibbon, {type SnackRibbonHandle} from '../../components/SnackRibbon/SnackRibbon';
import {type ResFood} from '../../models/foods';
import {type DayTimePickerHandle} from '../../components/DayTimePicker/DayTimePicker';
import Suppliers from '../Suppliers/Suppliers';
import Purchases from '../Purchases/Purchases';
import Expenses from '../Expenses/Expenses';

const Inventory = () => {
	const [selMenuIndex, setMenuIndex] = useState<number>(1);
	const snackBarRibbon = useRef<SnackRibbonHandle>(null);
	const [foodsData, setFoodsData] = useState<ResFood[]>([]);
	const [selFood, setSelFood] = useState<number>(-1);
	const [selVariUpdate, setSelVariUpdate] = useState<number[]>([]);
	const availRef = useRef<DayTimePickerHandle>(null);

	useEffect(() => {
		if (selVariUpdate.length === 1) {
			availRef.current!.setValue(foodsData[selFood].variations[selVariUpdate[0]].availability);
		}
	}, [selVariUpdate]);

	// FUNCTIONS --->

	const handleOnMenuClick = (i?: number) => {
		// Reset the data
		if (i === 1) {
			clearOnEventChanged(2);
		}

		if (selMenuIndex !== i) {
			setMenuIndex(i!);
		}
	};

	const clearOnEventChanged = (i: number) => {
		if (i === 1 || i === 2) {
			// Fetch food or On menu clicked
			setSelFood(-1);
		}

		if (i === 2) {
			// On menu clicked
			setFoodsData([]);
		}

		// Another food selected and comman
		if (availRef.current) {
			availRef.current.setValue([]);
		}

		setSelVariUpdate([]);
	};

	return (
		<div className='Inventory'>
			<SnackRibbon ref={snackBarRibbon}></SnackRibbon>
			<div className='Inventory-menu-bar'>
				<LabelButton onClick={handleOnMenuClick} index={1} selIndex={selMenuIndex} label={'Suppliers'}></LabelButton>
				<LabelButton onClick={handleOnMenuClick} index={2} selIndex={selMenuIndex} label={'Purchases'}></LabelButton>
				<LabelButton onClick={handleOnMenuClick} index={3} selIndex={selMenuIndex} label={'Expenses'}></LabelButton>
			</div>
			<div className='Inventory-menu-content'>{(() => {
				switch (selMenuIndex) {
					case 1:
						return <Suppliers />;
					case 2:
						return <Purchases />;
					case 3:
						return <Expenses />;
					default:
						return <div />;
				}
			})()}</div>
		</div >
	);
};

export default Inventory;
