import axios, {type AxiosError} from 'axios';
import endpoints from '../utils/endpoints';
import messages from '../utils/messages';
import type {CitiesModel} from '../models/cities';
import {type ResponseData, type ServiceResponse} from '../models/response';
import {serviceError, serviceSuccess} from '../utils/serviceHandler';

type ServerMessage = {
	message: string;
};

class CitiesService {
	get = async (): Promise<ServiceResponse<ResponseData<CitiesModel[]>>> => {
		try {
			const response = await axios.get<ResponseData<CitiesModel[]>>(endpoints.cities,
				{
					headers: {
						Authorization: `Bearer ${localStorage.getItem('AUT-OWNER')!}`,
						'Content-Type': 'application/json',
					},
				});
			await serviceSuccess();
			return {hasError: false, res: response.data};
		} catch (error) {
			const axiosError = error as AxiosError<ServerMessage>;
			if (axiosError.response?.data?.message) {
				await serviceError(axiosError.response?.data?.message);

				// Access the error message from the server response
				const errorMessage = axiosError.response.data.message;
				return {hasError: true, errorMsg: errorMessage};
			}

			return {hasError: true, errorMsg: messages.Ise};
		}
	};

	create = async (name: string): Promise<ServiceResponse<ResponseData<undefined>>> => {
		try {
			const response = await axios.post<ResponseData<undefined>>(endpoints.addcity,
				{name},
				{
					headers: {
						Authorization: `Bearer ${localStorage.getItem('AUT-OWNER')!}`,
						'Content-Type': 'application/json',
					},
				});
			await serviceSuccess();
			return {hasError: false, res: response.data};
		} catch (error) {
			const axiosError = error as AxiosError<ServerMessage>;
			if (axiosError.response?.data?.message) {
				await serviceError(axiosError.response?.data?.message);

				// Access the error message from the server response
				const errorMessage = axiosError.response.data.message;
				return {hasError: true, errorMsg: errorMessage};
			}

			return {hasError: true, errorMsg: messages.Ise};
		}
	};

	update = async (cityId: string, isAvailable: boolean): Promise<ServiceResponse<ResponseData<undefined>>> => {
		try {
			const response = await axios.patch<ResponseData<undefined>>(endpoints.cityavail,
				{cityId, isAvailable},
				{
					headers: {
						Authorization: `Bearer ${localStorage.getItem('AUT-OWNER')!}`,
						'Content-Type': 'application/json',
					},
				});
			await serviceSuccess();
			return {hasError: false, res: response.data};
		} catch (error) {
			const axiosError = error as AxiosError<ServerMessage>;
			if (axiosError.response?.data?.message) {
				await serviceError(axiosError.response?.data?.message);

				// Access the error message from the server response
				const errorMessage = axiosError.response.data.message;
				return {hasError: true, errorMsg: errorMessage};
			}

			return {hasError: true, errorMsg: messages.Ise};
		}
	};
}

export default CitiesService;
