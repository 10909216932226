import React, {useEffect, useRef, useState} from 'react';
import './Orders.css';
import LabelButton from '../../components/LabelButton/LabelButton';
import SnackRibbon, {type SnackRibbonHandle} from '../../components/SnackRibbon/SnackRibbon';
import ListView from '../../components/ListView/ListView';
import Space from '../../components/Space/Space';
import {type RestaurantModel} from '../../models/restaurants';
import OrdersService from '../../services/orders';
import {type OrderVariations, type OrderFood, type OrdersModel} from '../../models/orders';
import HorButtons from '../../components/HorButtons/HorButtons';
import CancelReservations from '../CancelReservations/CancelReservations';
import {RestaurantContext, type RestaurantContextResult} from '../../context/restContext';

const Orders = () => {
	const [selMenuIndex, setMenuIndex] = useState<number>(1);
	const [isOrderLoading, setOrderLoading] = useState<boolean>(false);
	const snackBarRibbon = useRef<SnackRibbonHandle>(null);
	const [ordersData, setOrdersData] = useState<OrdersModel[]>([]);
	const [selRest, setSelRest] = useState<number>(-1);
	const [selOrder, setSelOrder] = useState<number>(-1);
	const [selFood, setSelFood] = useState<number>(-1);

	// DATA --->

	async function fetchOrders(restIndex: number, reset: boolean, rest: RestaurantContextResult) {
		if (restIndex !== -1) {
			if (!isOrderLoading) {
				setOrderLoading(true);
			}

			await new OrdersService().get(rest.data[restIndex]._id).then(val => {
				if (val.hasError) {
					snackBarRibbon.current!.trigger(val.hasError, val.errorMsg!);
				} else {
					setOrdersData(val.res!.data!);
				}
			}).finally(() => {
				setOrderLoading(false);
			});
		}
	}

	// FUNCTIONS --->

	const handleOnMenuClick = (i?: number) => {
		// Reset the data
		// if (i === 1) {
		// 	void fetchRests();
		// }

		if (selMenuIndex !== i) {
			setMenuIndex(i!);
			setSelFood(-1);
			setSelFood(-1);
		}
	};

	const mainPage = () => <RestaurantContext.Consumer>{rest => (<div className='Restaurants-main'>
		<div className='rest-main-left-container' style={{width: '20%'}}>
			{rest.isLoading ? (<ListView dummy={5} adapter={function (i, item): JSX.Element {
				return <div className='rest-main-listview'>
					<label className='rest-main-listitem-adapter-index-load'>00</label>
					<Space size={5} isAutoResize={true}></Space>
					<div className='rest-main-listitem-adapter-card'>
						<label className='rest-main-listitem-adapter-title-load'>{'Tax Percentage : ${}%'}</label>
						<Space size={10} isAutoResize={true}></Space>
						<label className='rest-main-listitem-adapter-title-load'>{'Updated : ${}'}</label>
					</div>
				</div>;
			}} selItemIndex={-1} adapterHeight={80} />) : (<ListView data={rest.data} adapter={function (i, item): JSX.Element {
				const itemData = item as RestaurantModel;
				return <div className='rest-main-listview'>
					<label className='rest-main-listitem-adapter-index'>{i + 1}</label>
					<Space size={5} isAutoResize={true}></Space>
					<div className='rest-main-listitem-adapter-card'>
						<label className='rest-main-listitem-adapter-title'>{itemData.name}</label>
						<Space size={5} isAutoResize={true}></Space>
						<label className='rest-main-listitem-adapter-subtitle'>{itemData.address}</label>
					</div>
				</div>;
			}} onSelectItem={i => {
				if (selRest !== i) {
					setSelOrder(-1);
					setSelFood(-1);
					setSelRest(i);
					void fetchOrders(i, true, rest);
				}
			}} selItemIndex={selRest} adapterHeight={80} />)}
		</div>
		<div className='rest-main-left-container' style={{width: '20%', background: 'none'}}>
			{!rest.isLoading && !isOrderLoading ? <ListView data={ordersData} adapter={function (i, item): JSX.Element {
				const itemData = item as OrdersModel;
				return <div className='rest-main-listview'>
					<label className='rest-main-listitem-adapter-index'>{i + 1}</label>
					<Space size={5} isAutoResize={true}></Space>
					<div className='rest-main-listitem-adapter-card'>
						<label className='rest-main-listitem-adapter-title'>{itemData.tokenNo}</label>
						<Space size={5} isAutoResize={true}></Space>
						<label className='rest-main-listitem-adapter-subtitle'>{itemData.resttype.name}</label>
						<Space size={3} isAutoResize={true}></Space>
						<label className='rest-main-listitem-adapter-subtitle' style={{fontWeight: 'bold'}}>{`${itemData.payment.summary.grandTotal}  ${itemData.payment.paymentStatus.toUpperCase()}`}</label>
						<Space size={5} isAutoResize={true}></Space>
						<label className='rest-main-listitem-adapter-subtitle'>{itemData.status}</label>
					</div>
				</div>;
			}} onSelectItem={i => {
				// Reset the variation selection to avoid error while select another order
				setSelOrder(i);
				setSelFood(-1);
			}} selItemIndex={selOrder} adapterHeight={75} /> : (<ListView dummy={5} adapter={function (i, item): JSX.Element {
				return <div className='rest-main-listview'>
					<label className='rest-main-listitem-adapter-index-load'>00</label>
					<Space size={5} isAutoResize={true}></Space>
					<div className='rest-main-listitem-adapter-card'>
						<label className='rest-main-listitem-adapter-title-load'>{'Tax Percentage : ${}%'}</label>
					</div>
				</div>;
			}} selItemIndex={-1} adapterHeight={75} />)}
		</div>
		<div className='rest-main-right-container'>
			{(!rest.isLoading && !isOrderLoading && selOrder !== -1) ? (<div className='rest-main-right-container-cont'>
				<Space size={10} isAutoResize={false}></Space>
				<label className='rest-main-listitem-adapter-title'>{`Order Type : ${ordersData[selOrder].ordertype.name}`}</label>
				<Space size={5} isAutoResize={false}></Space>
				<label className='rest-main-listitem-adapter-title'>{`Service : ${ordersData[selOrder].resttype.name}`}</label>
				<Space size={5} isAutoResize={false}></Space>
				<label className='rest-main-listitem-adapter-title'>{`Status : ${ordersData[selOrder].status}`}</label>
				<Space size={5} isAutoResize={false}></Space>
				{ordersData[selOrder].isRejected && <label className='rest-main-listitem-adapter-title'>{`Rejected : ${ordersData[selOrder].rejectNote}`}</label>}
				<Space size={5} isAutoResize={false}></Space>
				<label className='rest-main-listitem-adapter-title'>{`Preparation Time : ${ordersData[selOrder].preparationTime}`}</label>
				<Space size={5} isAutoResize={false}></Space>
				<label className='rest-main-listitem-adapter-title'>{`Entry At : ${ordersData[selOrder].entryAt}`}</label>
				<Space size={5} isAutoResize={false}></Space>
				<label className='rest-main-listitem-adapter-title'>{`Exit At : ${ordersData[selOrder].exitAt}`}</label>
				<Space size={5} isAutoResize={false}></Space>
				{ordersData[selOrder].isFulfilled && <label className='rest-main-listitem-adapter-title'>{`Fullfilled At : ${ordersData[selOrder].fulfilledAt}`}</label>}
				<Space size={15} isAutoResize={false}></Space>
				<label className='rest-main-listitem-adapter-title'>{'Payment'}</label>
				<Space size={5} isAutoResize={false}></Space>
				<div className='Orders-container-left'>
					<label className='rest-main-listitem-adapter-title'>{`Total : ${ordersData[selOrder].payment.summary.total}`}</label>
					<Space size={3} isAutoResize={false}></Space>
					<label className='rest-main-listitem-adapter-title'>{`Sub Total : ${ordersData[selOrder].payment.summary.subTotal}`}</label>
					<Space size={3} isAutoResize={false}></Space>
					<label className='rest-main-listitem-adapter-title'>{`Tax : ${ordersData[selOrder].payment.summary.tax}`}</label>
					<Space size={3} isAutoResize={false}></Space>
					<label className='rest-main-listitem-adapter-title'>{`Sitig Charge : ${ordersData[selOrder].payment.summary.sitingCharge}`}</label>
					<Space size={3} isAutoResize={false}></Space>
					<label className='rest-main-listitem-adapter-title'>{`Extra Charge : ${ordersData[selOrder].payment.summary.extraCharges}`}</label>
					<Space size={3} isAutoResize={false}></Space>
					<label className='rest-main-listitem-adapter-title'>{`Discount : ${ordersData[selOrder].payment.summary.discount}`}</label>
					<Space size={3} isAutoResize={false}></Space>
					<label className='rest-main-listitem-adapter-title'>{`Delivery Fee : ${ordersData[selOrder].payment.summary.deliveryFee}`}</label>
					<Space size={3} isAutoResize={false}></Space>
					<label className='rest-main-listitem-adapter-title'>{`Coupon Discount : ${ordersData[selOrder].payment.summary.couponDiscount}`}</label>
					<Space size={3} isAutoResize={false}></Space>
					<label className='rest-main-listitem-adapter-title'>{`Grand Total : ${ordersData[selOrder].payment.summary.grandTotal}`}</label>
					<Space size={5} isAutoResize={false}></Space>
					<label className='rest-main-listitem-adapter-title'>{`Recived Amount : ${ordersData[selOrder].payment.recivedAmount}`}</label>
					<Space size={3} isAutoResize={false}></Space>
					<label className='rest-main-listitem-adapter-title'>{`Returned Amount : ${ordersData[selOrder].payment.returnedAmount}`}</label>
					<Space size={3} isAutoResize={false}></Space>
					<label className='rest-main-listitem-adapter-title'>{`Payment Status : ${ordersData[selOrder].payment.paymentStatus}`}</label>
					<Space size={3} isAutoResize={false}></Space>
					<label className='rest-main-listitem-adapter-title'>{`Paid At : ${ordersData[selOrder].payment.paidAt}`}</label>
				</div>
				<Space size={15} isAutoResize={false}></Space>
				<label className='rest-main-listitem-adapter-title'>{'Foods'}</label>
				<HorButtons data={ordersData[selOrder].foods} adapter={function (i: number, item: OrderFood | number): JSX.Element {
					const itemData = item as OrderFood;
					return <div className='listview-item-adapter'>
						<div className='listview-item-adapter-card'>
							<label className='horlistview-item-adapter-title'>{itemData.name}</label>
						</div>
					</div>;
				}} adapterHeight={35}
				selItemIndex={selFood} onSelectItem={(i: number) => {
					setSelFood(i);
				}} />
				<Space size={15} isAutoResize={false}></Space>
				{selFood !== -1 && <div className='Orders-container-left'>
					<label className='rest-main-listitem-adapter-title'>{ordersData[selOrder].foods[selFood].name}</label>
					<Space size={3} isAutoResize={false}></Space>
					<label className='rest-main-listitem-adapter-title'>{ordersData[selOrder].foods[selFood].isCombo ? 'Combo' : ordersData[selOrder].foods[selFood].isFreeClaim ? 'Freeclaim' : ''}</label>
					<Space size={10} isAutoResize={false}></Space>
					<label className='rest-main-listitem-adapter-title'>{'Variations'}</label>
					<Space size={10} isAutoResize={false}></Space>
					<HorButtons data={ordersData[selOrder].foods[selFood].variations} adapter={function (i: number, item: OrderVariations | number): JSX.Element {
						const varData = item as OrderVariations;
						return <div className='listview-item-adapter'>
							<div className='listview-item-adapter-card' style={{flexDirection: 'column'}}>
								<label className='horlistview-item-adapter-title'>{varData.name}</label>
								<Space size={3} isAutoResize={false}></Space>
								<label className='rest-main-listitem-adapter-title'>{`Quantity : ${varData.quantity}`}</label>
								<Space size={5} isAutoResize={false}></Space>
								<label className='horlistview-item-adapter-title'>{varData.hasOffer ? varData.price : varData.discountPrice}</label>
								<Space size={5} isAutoResize={false}></Space>
								{varData.customizations && <label className='horlistview-item-adapter-title'>{varData.customizations.map(v => `[${v.cusName} - ${v.levelName}]`).join(', ')}</label>}
								<Space size={5} isAutoResize={false}></Space>
								{varData.addons && <label className='horlistview-item-adapter-title'>{varData.addons.map(v => `[${v.name} - ${v.price}]`).join(', ')}</label>}
							</div>
						</div>;
					}} adapterHeight={55} />
					<Space size={5} isAutoResize={false}></Space>
					<label className='rest-main-listitem-adapter-title'>{`Extra Charge : ${ordersData[selOrder].payment.summary.extraCharges}`}</label>
					<Space size={3} isAutoResize={false}></Space>
				</div>}
				<Space size={50} isAutoResize={false}></Space>
			</div>) : (<div className='rest-main-right-container-cont' style={{display: 'flex', height: '100%', alignItems: 'center', justifyContent: 'center'}}>
				<label className='rest-main-listitem-adapter-subtitle' style={{textAlign: 'center'}}>Select any restaurant to get the order details</label>
			</div>)}
		</div>
	</div>)}</RestaurantContext.Consumer>;

	return (
		<div className='Orders'>
			<SnackRibbon ref={snackBarRibbon}></SnackRibbon>
			<div className='Orders-menu-bar'>
				<LabelButton onClick={handleOnMenuClick} index={1} selIndex={selMenuIndex} label={'All'}></LabelButton>
				<LabelButton onClick={handleOnMenuClick} index={2} selIndex={selMenuIndex} label={'Cancelled Reservations'}></LabelButton>
			</div>
			<div className='Orders-menu-content'>{(() => {
				switch (selMenuIndex) {
					case 1:
						return mainPage();
					case 2:
						return <CancelReservations />;
					default:
						return <div />;
				}
			})()}</div>
		</div >
	);
};

export default Orders;
