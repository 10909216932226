import React, {useEffect, useRef, useState} from 'react';
import './Changes.css';
import Space from '../../components/Space/Space';
import ListView from '../../components/ListView/ListView';
import SnackRibbon, {type SnackRibbonHandle} from '../../components/SnackRibbon/SnackRibbon';
import ChangesService from '../../services/changes';
import {type ChangesModel} from '../../models/changes';
import JsonDisplay from '../../components/JsonDisplay/JsonDisplay';
import Button from '../../components/Button/Button';

type CustomStyleType = {
	'--justifyContent': string;
};

type ExtendedDivStyle = React.CSSProperties & CustomStyleType;

const Changes = () => {
	const [data, setData] = useState<ChangesModel[]>([]);
	const [selItemIndex, setItemIndex] = useState<number>(-1);
	const [isLoading, setLoading] = useState<boolean>(true);

	const snackBarRibbon = useRef<SnackRibbonHandle>(null);

	useEffect(() => {
		void fetchChanges();
	}, []);

	async function fetchChanges() {
		await new ChangesService().get().then(val => {
			if (val.hasError) {
				snackBarRibbon.current!.trigger(val.hasError, val.errorMsg!);
			} else {
				setData(val.res!.data!);
			}
		}).finally(() => {
			setLoading(false);
		});
	}

	const onApprove = async () => {
		if (!isLoading) {
			setLoading(true);
		}

		await new ChangesService().update(data[selItemIndex]._id).then(async val => {
			if (val.hasError) {
				snackBarRibbon.current!.trigger(val.hasError, val.errorMsg!);
			} else {
				await fetchChanges();
			}
		}).finally(() => {
			setLoading(false);
		});
	};

	const customStyle: ExtendedDivStyle = {
		'--justifyContent': selItemIndex === -1 ? 'center' : 'start',
	};

	return (
		<div className='Changes'>
			<div className='Changes-left-panel'>
				{isLoading ? (<div className='Changes-left-list'>
					<ListView dummy={5} adapter={function (_, __): JSX.Element {
						return <div className='Changes-list-adapter'>
							<label className='Changes-list-adapter-index-load'>00</label>
							<Space size={5} isAutoResize={true}></Space>
							<div className='Changes-list-adapter-card'>
								<label className='Changes-list-adapter-perc-load'>{'Tax Percentage : ${}%'}</label>
								<Space size={10} isAutoResize={false}></Space>
								<label className='Changes-list-adapter-update-load'>{'Updated : ${}'}</label>
							</div>
						</div>;
					}} selItemIndex={-1} adapterHeight={65} />
				</div>) : (<div className='Changes-left-list'>
					<ListView data={data} adapter={function (i, item): JSX.Element {
						const itemData = item as ChangesModel;
						return <div className='Changes-list-adapter'>
							<label className='Changes-list-adapter-index'>{i + 1}</label>
							<Space size={5} isAutoResize={true}></Space>
							<div className='Changes-list-adapter-card'>
								<label className='Changes-list-adapter-perc'>{`${itemData.user.firstName} ${itemData.user.lastName}`}</label>
								<Space size={2} isAutoResize={false}></Space>
								<label className='Changes-list-adapter-update' style={{fontSize: '8px'}}>{itemData.note}</label>
								<Space size={5} isAutoResize={false}></Space>
								<label className='Changes-list-adapter-update'>{itemData.updatedAt}</label>
							</div>
						</div>;
					}} selItemIndex={selItemIndex} onSelectItem={i => {
						setItemIndex(i);
					}} adapterHeight={65} />
				</div>)}
			</div>
			<div className='Changes-right-panel'>
				<SnackRibbon ref={snackBarRibbon}></SnackRibbon>
				<div className='Changes-right-content' style={customStyle}>
					{selItemIndex === -1 && <label className='Changes-right-empty-content'>No items selected</label>}
					{selItemIndex !== -1 && <div>
						<div className='Changes-right-row'>
							<label className='Changes-detail-no' style={{width: '100%'}}>{`No : ${selItemIndex + 1}`}</label>
							<Button onClick={function (): void {
								void onApprove();
							} } label={'Approve'} isLoading={isLoading}></Button>
						</div>
						<Space size={5} isAutoResize={false}></Space>
						<label className='Changes-detail-perc'>{`${data[selItemIndex].user.firstName} ${data[selItemIndex].user.lastName}`}</label>
						<Space size={5} isAutoResize={false}></Space>
						<label className='Changes-detail-perc'>{data[selItemIndex].user.role}</label>
						<Space size={5} isAutoResize={false}></Space>
						<label className='Changes-detail-perc'>{data[selItemIndex].note}</label>
						<Space size={10} isAutoResize={false}></Space>
						<div className='Changes-left-list' style={{border: '1px solid rgb(214, 223, 231)', borderRadius: '4px'}}>
							<ul>
								{data[selItemIndex].data.map((item, i) => <li key={i}>
									<JsonDisplay jsonData={item.value}></JsonDisplay>
								</li>)}
							</ul>
						</div>
						<Space size={10} isAutoResize={false}></Space>
						<label className='Changes-detail-update'>{data[selItemIndex].updatedAt}</label>
						<Space size={15} isAutoResize={false}></Space>
					</div>}
				</div>
			</div>
		</div >
	);
};

export default Changes;
