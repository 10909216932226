import React, {useContext, useEffect, useRef, useState} from 'react';
import './Restaurants.css';
import LabelButton from '../../components/LabelButton/LabelButton';
import Tax from '../Tax/Tax';
import Contacts from '../Contacts/Contacts';
import Cities from '../Cities/Cities';
import DeliveryCharge from '../DeliveryCharge/DeliveryCharge';
import AddRestaurant from '../AddRestaurant/AddRestaurant';
import {usePluginContext} from '../../context/pluginContext';
import SitingsOwner from '../Sitings/Sitings';
import ListView from '../../components/ListView/ListView';
import Space from '../../components/Space/Space';
import RestaurantsService from '../../services/restaurants';
import SnackRibbon, {type SnackRibbonHandle} from '../../components/SnackRibbon/SnackRibbon';
import {type RestaurantModel} from '../../models/restaurants';
import DotStatus from '../../components/DotStatus/DotStatus';
import HorButtons, {type HorButtonsHandle} from '../../components/HorButtons/HorButtons';
import CheckBox from '../../components/CheckBox/CheckBox';
import Button from '../../components/Button/Button';
import TextField, {type TextFieldHandle} from '../../components/TextField/TextField';
import TextFieldInputTypes from '../../utils/textfield/fieldtypes';
import resttypes from '../../config/resttypes';
import DayTimePicker, {type DayTimePickerHandle} from '../../components/DayTimePicker/DayTimePicker';
import DateTimeSlot, {type DateTimeSlotHandle} from '../../components/DateTimeSlot/DateTimeSlot';
import CouponsOwner from '../Coupons/CouponsOwner';
import {RestaurantContext, type RestaurantContextResult} from '../../context/restContext';
import {SitingContext} from '../../context/sitsContext';
import {type SitingsModel} from '../../models/sitings';
import SitingsService from '../../services/sitings';
import TimeSlots, {type TimeSlotsHandle} from '../../components/TimeSlots/TimeSlots';

type RestTypes = {
	_id: string;
	name: string;
};

type DotIndicator = {
	delIsActive: boolean;
	takIsActive: boolean;
	dinIsActive: boolean;
	dirIsActive: boolean;
};

const Restaurants = () => {
	const plugins = usePluginContext();
	const resti = useContext(RestaurantContext);

	const [selMenuIndex, setMenuIndex] = useState<number>(1);
	const [selRest, setSelRest] = useState<number>(-1);
	const [selRsType, setSelRsType] = useState<number[]>([]);
	const [selSitings, setSelSitings] = useState<string[]>([]);
	const [dotIndicator, setDotIndicator] = useState<DotIndicator[]>([]);

	const snackBarRibbon = useRef<SnackRibbonHandle>(null);
	const restTypesRef = useRef<HorButtonsHandle>(null);
	const reasonRef = useRef<TextFieldHandle>(null);
	const availability = useRef<DayTimePickerHandle>(null);
	const holiday = useRef<DateTimeSlotHandle>(null);
	const timeSlots = useRef<TimeSlotsHandle>(null);

	useEffect(() => {
		void fetchRests();
	}, [resti.data, selSitings]);

	const handleOnMenuClick = (i?: number) => {
		// Reset the data
		if (i === 1) {
			setSelRest(-1);
			setSelSitings([]);
			void fetchRests();
			if (timeSlots.current) {
				timeSlots.current.clear();
			}
		}

		if (selMenuIndex !== i) {
			setMenuIndex(i!);
		}
	};

	const fetchRests = async () => {
		setDotIndicator(Array.from({length: resti.data.length}, () => ({
			delIsActive: false,
			takIsActive: false,
			dinIsActive: false,
			dirIsActive: false,
		})));
		dotStatus(resti.data);
	};

	async function restStatus(isOpen: boolean, rest: RestaurantContextResult) {
		if (!rest.isLoading) {
			rest.setLoading(true);
		}

		if ((isOpen ? true : reasonRef.current!.validate().isValid) && selRsType.length !== 0 && rest.data.length !== 0) {
			await new RestaurantsService().status({
				restId: rest.data[selRest]._id,
				status: Array.from({length: selRsType.length}, (__, i: number) => ({
					typeId: rest.data[selRest].restTypes.filter(v => v.name.toLowerCase() !== resttypes.direct)[selRsType[i]]._id,
					isOpen,
					reason: isOpen ? '' : reasonRef.current!.getValue(),
				})),
			}).then(val => {
				if (val.hasError) {
					snackBarRibbon.current!.trigger(val.hasError, val.errorMsg!);
				}
			}).finally(async () => {
				await rest.fetchData();
				void fetchRests();
			});
		} else {
			if (isOpen) {
				snackBarRibbon.current!.trigger(true, 'Select the service');
			} else {
				const field1 = reasonRef.current!.validate();
				snackBarRibbon.current!.trigger(true, field1.isValid ? 'Select the service' : field1.msg);
			}

			rest.setLoading(false);
		}
	}

	function onLoadSitings(sr: number, rt: RestaurantModel[]) {
		if (sr !== -1) {
			// eslint-disable-next-line @typescript-eslint/no-unnecessary-type-assertion
			setSelSitings((rt[sr].sitingIds ?? []) as string[]);
		}
	}

	async function onSaveSitings(rest: RestaurantContextResult) {
		if (!rest.isLoading) {
			rest.setLoading(true);
		}

		await new SitingsService().addsitings(
			rest.data[selRest]._id,
			selSitings,
		).then(val => {
			if (val.hasError) {
				snackBarRibbon.current!.trigger(val.hasError, val.errorMsg!);
			}
		}).finally(async () => {
			await rest.fetchData();
			void fetchRests();
		});
	}

	async function onSaveTimeSlots(rest: RestaurantContextResult) {
		if (!timeSlots.current) {
			snackBarRibbon.current!.trigger(true, 'Select time slots');
		} else if (timeSlots.current.times().length === 0) {
			snackBarRibbon.current!.trigger(true, 'Add time to time slots');
		} else {
			if (!rest.isLoading) {
				rest.setLoading(true);
			}

			await new RestaurantsService().times({
				restId: rest.data[selRest]._id,
				times: timeSlots.current.times(),
			}).then(val => {
				if (val.hasError) {
					snackBarRibbon.current!.trigger(val.hasError, val.errorMsg!);
				}
			}).finally(async () => {
				await rest.fetchData();
				void fetchRests();
			});
		}
	}

	function isOpen(s: string, d: RestaurantModel) {
		switch (s.toLowerCase()) {
			case resttypes.delivery:
				return d.delivery.isOpen;
			case resttypes.takeaway:
				return d.takeaway.isOpen;
			case resttypes.dining:
				return d.dining.isOpen;
			default:
				return false;
		}
	}

	async function restAvail(rest: RestaurantContextResult) {
		if (!rest.isLoading) {
			rest.setLoading(true);
		}

		if (availability.current!.getValue().length !== 0 && selRsType.length !== 0 && rest.data.length !== 0) {
			await new RestaurantsService().availability({
				restId: rest.data[selRest]._id,
				availability: Array.from({length: selRsType.length}, (__, i: number) => ({
					typeId: rest.data[selRest].restTypes.filter(v => v.name.toLowerCase() !== resttypes.direct)[selRsType[i]]._id,
					availability: availability.current!.getValue(),
				})),
			}).then(val => {
				if (val.hasError) {
					snackBarRibbon.current!.trigger(val.hasError, val.errorMsg!);
				}
			}).finally(async () => {
				await rest.fetchData();
				void fetchRests();
			});
		} else {
			if (availability.current!.getValue().length === 0) {
				snackBarRibbon.current!.trigger(true, 'Pick the availability time');
			} else {
				snackBarRibbon.current!.trigger(true, 'Select the service');
			}

			rest.setLoading(false);
		}
	}

	async function restHoliday(rest: RestaurantContextResult) {
		if (!rest.isLoading) {
			rest.setLoading(true);
		}

		if (holiday.current!.getValue().length !== 0 && selRsType.length !== 0 && rest.data.length !== 0) {
			const vals = holiday.current!.getValue();
			const holData: Array<{
				date: string;
				st: number;
				et: number;
				reason: string;
			}> = [];

			if (selRsType.length === 1) {
				if (getHolidayInit(rest)!.length !== vals.length) {
					const mapdte = getHolidayInit(rest)!.map(m => m.date);
					const filt = vals.filter(v => !mapdte.includes(v.date));
					for (const f of filt) {
						holData.push(f);
					}
				}
			} else {
				for (const v of vals) {
					holData.push(v);
				}
			}

			if (holData.length === 0) {
				rest.setLoading(false);
			} else {
				await new RestaurantsService().holiday({
					restId: rest.data[selRest]._id,
					holiday: Array.from({length: selRsType.length}, (__, i: number) => ({
						typeId: rest.data[selRest].restTypes.filter(v => v.name.toLowerCase() !== resttypes.direct)[selRsType[i]]._id,
						holiday: holData,
					})),
				}).then(val => {
					if (val.hasError) {
						snackBarRibbon.current!.trigger(val.hasError, val.errorMsg!);
					}
				}).finally(async () => {
					await rest.fetchData();
					void fetchRests();
				});
			}
		} else {
			if (holiday.current!.getValue().length === 0) {
				snackBarRibbon.current!.trigger(true, 'Pick the holiday');
			} else {
				snackBarRibbon.current!.trigger(true, 'Select the service');
			}

			rest.setLoading(false);
		}
	}

	async function restRemoveHoliday(data: {
		date: string;
		st: number;
		et: number;
		reason: string;
	}, rest: RestaurantContextResult) {
		if (selRsType.length === 1) {
			if (!rest.isLoading) {
				rest.setLoading(true);
			}

			await new RestaurantsService().remholiday({
				restId: rest.data[selRest]._id,
				typeId: rest.data[selRest].restTypes.filter(v => v.name.toLowerCase() !== resttypes.direct)[selRsType[0]]._id,
				holiday: data,
			}).then(val => {
				if (val.hasError) {
					snackBarRibbon.current!.trigger(val.hasError, val.errorMsg!);
				}
			}).finally(async () => {
				await rest.fetchData();
				void fetchRests();
			});
		}
	}

	const getAvailInit = (rest: RestaurantContextResult): Array<{
		day: string;
		st: number;
		et: number;
	}> | undefined => {
		if (selRsType.length === 1) {
			const tyNa = rest.data[selRest].restTypes[selRsType[0]].name.toLowerCase();

			switch (tyNa) {
				case resttypes.delivery:
					return rest.data[selRest].delivery.availability;
				case resttypes.takeaway:
					return rest.data[selRest].takeaway.availability;
				case resttypes.dining:
					return rest.data[selRest].dining.availability;
				default:
					return undefined;
			}
		}
	};

	const getHolidayInit = (rest: RestaurantContextResult): Array<{
		date: string;
		st: number;
		et: number;
		reason: string;
	}> | undefined => {
		if (selRsType.length === 1) {
			const tyNa = rest.data[selRest].restTypes[selRsType[0]].name.toLowerCase();

			switch (tyNa) {
				case resttypes.delivery:
					return rest.data[selRest].delivery.holiday;
				case resttypes.takeaway:
					return rest.data[selRest].takeaway.holiday;
				case resttypes.dining:
					return rest.data[selRest].dining.holiday;
				default:
					return undefined;
			}
		}
	};

	useEffect(() => {
		// Start timer only if restaurants tab open
		if (selMenuIndex === 1) {
			const dotStatusInterval = () => {
				dotStatus(resti.data);
			};

			// 10 seconds
			const intervalId = setInterval(dotStatusInterval, 10000);

			return () => {
				clearInterval(intervalId);
			};
		}
	}, [dotIndicator, selMenuIndex]);

	useEffect(() => {
		if (timeSlots.current && timeSlots.current.times.length === 0) {
			timeSlots.current.setTimes(resti.data[selRest].dining.timeSlots);
		}
	}, [timeSlots.current]);

	const available = (mo: string, dd: RestaurantModel): boolean => {
		const now = new Date();
		const day = now.toLocaleDateString(undefined, {weekday: 'short'}).toLowerCase();

		const deltypes = dd.delivery;
		const delAvailData = deltypes.availability.find(v => v.day === day);

		const taktypes = dd.takeaway;
		const takAvailData = taktypes.availability.find(v => v.day === day);

		const dintypes = dd.dining;
		const dinAvailData = dintypes.availability.find(v => v.day === day);

		switch (mo) {
			case resttypes.delivery:
				return (!delAvailData || (delAvailData && !(delAvailData.st <= parseFloat(`${now.getHours()}.${now.getMinutes()}`) && delAvailData.et > parseFloat(`${now.getHours()}.${now.getMinutes()}`))));
			case resttypes.takeaway:
				return (!takAvailData || (takAvailData && !(takAvailData.st <= parseFloat(`${now.getHours()}.${now.getMinutes()}`) && takAvailData.et > parseFloat(`${now.getHours()}.${now.getMinutes()}`))));
			case resttypes.dining:
				return (!dinAvailData || (dinAvailData && !(dinAvailData.st <= parseFloat(`${now.getHours()}.${now.getMinutes()}`) && dinAvailData.et > parseFloat(`${now.getHours()}.${now.getMinutes()}`))));
			case resttypes.direct:
				return true;
			default:
				return false;
		}
	};

	const isholiday = (mo: string, dd: RestaurantModel): boolean => {
		const now = new Date();
		const month = now.getMonth().toString().length === 1 ? `0${now.getMonth() + 1}` : (now.getMonth() + 1).toString();
		const date = now.getDate().toString().length === 1 ? `0${now.getDate()}` : now.getDate().toString();

		const deltypes = dd.delivery;
		const delHoliData = deltypes.holiday.find(v => v.date === `${now.getFullYear()}:${month}:${date}`);

		const taktypes = dd.takeaway;
		const takHoliData = taktypes.holiday.find(v => v.date === `${now.getFullYear()}:${month}:${date}`);

		const dintypes = dd.dining;
		const dinHoliData = dintypes.holiday.find(v => v.date === `${now.getFullYear()}:${month}:${date}`);

		switch (mo) {
			case resttypes.delivery:
				return (delHoliData !== undefined && (delHoliData.st <= parseFloat(`${now.getHours()}.${now.getMinutes()}`) && (delHoliData.et - 0.01) >= parseFloat(`${now.getHours()}.${now.getMinutes()}`)));
			case resttypes.takeaway:
				return (takHoliData !== undefined && (takHoliData.st <= parseFloat(`${now.getHours()}.${now.getMinutes()}`) && (takHoliData.et - 0.01) >= parseFloat(`${now.getHours()}.${now.getMinutes()}`)));
			case resttypes.dining:
				return (dinHoliData !== undefined && (dinHoliData.st <= parseFloat(`${now.getHours()}.${now.getMinutes()}`) && (dinHoliData.et - 0.01) >= parseFloat(`${now.getHours()}.${now.getMinutes()}`)));
			case resttypes.direct:
				return true;
			default:
				return false;
		}
	};

	const dotStatus = (rests: RestaurantModel[]) => {
		// Const dotStatus = (isInit: boolean, restInitData: RestaurantModel[], rest: RestaurantContextResult) => {
		// Const rests = isInit ? restInitData : restsData;
		for (const dd of rests) {
			const data = {
				delIsActive: false,
				takIsActive: false,
				dinIsActive: false,
				dirIsActive: true,
			};

			if ((!dd.delivery.isOpen) || available(resttypes.delivery, dd) || isholiday(resttypes.delivery, dd)) {
				data.delIsActive = false;
			} else {
				data.delIsActive = true;
			}

			if ((!dd.takeaway.isOpen) || available(resttypes.takeaway, dd) || isholiday(resttypes.takeaway, dd)) {
				data.takIsActive = false;
			} else {
				data.takIsActive = true;
			}

			if ((!dd.dining.isOpen) || available(resttypes.dining, dd) || isholiday(resttypes.dining, dd)) {
				data.dinIsActive = false;
			} else {
				data.dinIsActive = true;
			}

			setDotIndicator(prvVal => prvVal.map((vl, j) => (rests.length !== 0 && j === rests.indexOf(dd)) ? data : vl));
		}
	};

	const selService = (i: number, rest: RestaurantContextResult) => {
		if (!rest.isLoading) {
			if (selRsType.includes(i)) {
				setSelRsType(selRsType.filter(v => v !== i));
			} else {
				setSelRsType([...selRsType, i]);
			}
		}
	};

	const mainPage = () => <RestaurantContext.Consumer>
		{rest => (<div className='Restaurants-main'>
			<div className='rest-main-left-container'>
				{rest.isLoading || dotIndicator.length === 0 ? (<ListView dummy={5} adapter={function (i, item): JSX.Element {
					return <div className='rest-main-listview'>
						<label className='rest-main-listitem-adapter-index-load'>00</label>
						<Space size={5} isAutoResize={true}></Space>
						<div className='rest-main-listitem-adapter-card'>
							<label className='rest-main-listitem-adapter-title-load'>{'Tax Percentage : ${}%'}</label>
							<Space size={10} isAutoResize={true}></Space>
							<label className='rest-main-listitem-adapter-title-load'>{'Updated : ${}'}</label>
						</div>
					</div>;
				}} selItemIndex={-1} adapterHeight={80} />) : (<ListView data={rest.data} adapter={function (i, item): JSX.Element {
					const itemData = item as RestaurantModel;
					return <div className='rest-main-listview'>
						<label className='rest-main-listitem-adapter-index'>{i + 1}</label>
						<Space size={5} isAutoResize={true}></Space>
						<div className='rest-main-listitem-adapter-card'>
							<label className='rest-main-listitem-adapter-title'>{itemData.name}</label>
							<Space size={5} isAutoResize={true}></Space>
							<label className='rest-main-listitem-adapter-subtitle'>{itemData.address}</label>
							<Space size={10} isAutoResize={true}></Space>
							<div className='rest-main-container-row'>
								<DotStatus isActive={dotIndicator[i].delIsActive} size={'4px'}></DotStatus>
								<Space size={5} isAutoResize={false}></Space>
								<label className='rest-main-listitem-adapter-dottitle'>Del</label>
								<Space size={15} isAutoResize={false}></Space>
								<DotStatus isActive={dotIndicator[i].takIsActive} size={'4px'}></DotStatus>
								<Space size={5} isAutoResize={false}></Space>
								<label className='rest-main-listitem-adapter-dottitle'>Tak</label>
								<Space size={15} isAutoResize={false}></Space>
								<DotStatus isActive={dotIndicator[i].dinIsActive} size={'4px'}></DotStatus>
								<Space size={5} isAutoResize={false}></Space>
								<label className='rest-main-listitem-adapter-dottitle'>Din</label>
								<Space size={15} isAutoResize={false}></Space>
								<DotStatus isActive={dotIndicator[i].dirIsActive} size={'4px'}></DotStatus>
								<Space size={5} isAutoResize={false}></Space>
								<label className='rest-main-listitem-adapter-dottitle'>Dir</label>
							</div>
						</div>
					</div>;
				}} onSelectItem={i => {
					setSelRsType([]);
					setSelRest(i);
					onLoadSitings(i, rest.data);
					if (availability.current) {
						availability.current.setValue([]);
					}

					if (timeSlots.current) {
						timeSlots.current.setTimes(rest.data[i].dining.timeSlots);
					}
				}} selItemIndex={selRest} adapterHeight={80} />)}
			</div>
			<div className='rest-main-right-container'>
				{(rest.data.length !== 0 && selRest !== -1) ? (<div className='rest-main-right-container-cont'>
					<label className='rest-main-listitem-adapter-title'>{rest.data[selRest].name}</label>
					<Space size={20} isAutoResize={false}></Space>
					<label className='rest-main-listitem-adapter-subtitle'>{rest.data[selRest].address}</label>
					<Space size={10} isAutoResize={false}></Space>
					<label className='rest-main-listitem-adapter-title'>{`Cities : ${rest.data[selRest].cities.map(v => v.name).toString().replaceAll(',', ',  ')}`}</label>
					<Space size={10} isAutoResize={false}></Space>
					<label className='rest-main-listitem-adapter-title'>{`Contacts : ${rest.data[selRest].contacts.map(v => v.isLandline ? v.contactNumber : v.mobileNumber).toString().replaceAll(',', ',  ')}`}</label>
					<Space size={20} isAutoResize={false}></Space>
					<label className='rest-main-listitem-adapter-title'>{`Services : ${rest.data[selRest].restTypes.map(v => v.name).toString().replaceAll(',', ',  ')}`}</label>
					<Space size={40} isAutoResize={false}></Space>
					<div style={{display: 'flex', flexDirection: 'row', justifyContent: 'space-between'}}>
						<label className='rest-main-listitem-adapter-title'>Sitings (Used for dining and direct services)</label>
						<Button label={'Save'} onClick={async () => {
							await onSaveSitings(rest);
						}} color='green' isLoading={rest.isLoading}></Button>
					</div>
					<Space size={10} isAutoResize={false}></Space>
					{!rest.isLoading && <div className='listview-wrapper' style={{height: '200px'}}>
						<SitingContext.Consumer>
							{sits => (<ListView data={sits.data} adapter={function (k: number, itemr: SitingsModel | number): JSX.Element {
								const sitItem = itemr as SitingsModel;
								return <div className='listview-item-adapter'>
									<label className='listview-item-adapter-index' style={{width: '2%'}}>{k + 1}</label>
									<Space size={5} isAutoResize={true}></Space>
									<div className='listview-item-adapter-card' style={{flexDirection: 'column'}}>
										<label className='listview-item-adapter-title' style={{fontSize: '11px'}}>{sitItem.name}</label>
										<Space size={5} isAutoResize={true}></Space>
										<div style={{display: 'flex', flexDirection: 'row', width: '100%'}}>
											<label className='listview-item-adapter-title' style={{fontSize: '9px'}}>{sitItem.capacity}</label>
											<Space size={3} isAutoResize={true}></Space>
											{sitItem.hasCharge && <label className='listview-item-adapter-title' style={{fontSize: '9px'}}>{`$${sitItem.amount}`}</label>}
										</div>
									</div>
									<CheckBox title='' ovrVal={selSitings.includes(sitItem._id)} onClick={(c: boolean) => {
										if (selSitings.includes(sitItem._id)) {
											setSelSitings(prev => prev.filter(v => v !== sitItem._id));
										} else {
											setSelSitings(prev => [...prev, sitItem._id]);
										}
									}}></CheckBox>
									<Space size={5} isAutoResize={true}></Space>
								</div>;
							}} adapterHeight={38} onSelectItem={i => {
								const dd = sits.data[i];
								if (selSitings.includes(dd._id)) {
									setSelSitings(prev => prev.filter(v => v !== dd._id));
								} else {
									setSelSitings(prev => [...prev, dd._id]);
								}
							}} />)}
						</SitingContext.Consumer>
					</div>}
					<div style={{visibility: rest.data[selRest].dining.timeSlots.length === 0 ? 'hidden' : 'visible', height: rest.data[selRest].dining.timeSlots.length === 0 ? 0 : 'auto'}}>
						<Space size={20} isAutoResize={false}></Space>
						<div style={{display: 'flex', flexDirection: 'row', justifyContent: 'space-between'}}>
							<label className='addrest-label'>Time Slots (24hr format) for dining only</label>
							<Button label={'Save'} onClick={async () => {
								await onSaveTimeSlots(rest);
							}} color='green' isLoading={rest.isLoading}></Button>
						</div>
						<Space size={5} isAutoResize={false}></Space>
						<div className='addrest-container' style={{height: '200px'}}>
							<TimeSlots ref={timeSlots} isLoading={rest.isLoading}></TimeSlots>
						</div>
					</div>
					<Space size={20} isAutoResize={false}></Space>
					<label className='rest-main-listitem-adapter-title'>Services</label>
					<Space size={10} isAutoResize={false}></Space>
					{!rest.isLoading && <div className='horlistview-wrapper'>
						<HorButtons ref={restTypesRef} data={rest.data[selRest].restTypes.filter(v => v.name.toLowerCase() !== resttypes.direct)} adapter={function (i: number, item: RestTypes | number): JSX.Element {
							const itemData = item as RestTypes;
							const opened = isOpen(itemData.name, rest.data[selRest]);
							return <div className='listview-item-adapter'>
								<div className='listview-item-adapter-card'>
									<label className='horlistview-item-adapter-title'>{`${itemData.name}${opened ? ' (Open)' : ' (Closed)'}`}</label>
									<Space size={5} isAutoResize={true}></Space>
									<CheckBox title='' initVal={selRsType.includes(i)} ovrVal={selRsType.includes(i)} onClick={(b: boolean) => {
										selService(i, rest);
									}}></CheckBox>
								</div>
							</div>;
						}} adapterHeight={35} selItemIndex={selRsType} onSelectItem={(i: number) => {
							selService(i, rest);
						}} />
					</div>}
					<Space size={30} isAutoResize={false}></Space>
					<label className='rest-main-listitem-adapter-title'>Close or Open the service (Select all service will close entire restaurant)</label>
					<Space size={10} isAutoResize={false}></Space>
					<div className='rest-main-container-row'>
						<TextField ref={reasonRef} isLoading={rest.isLoading}
							label={'Reason for closing'}
							inputType={TextFieldInputTypes.name} options={{minLength: 2}}></TextField>
						<Space size={5} isAutoResize={false}></Space>
						<Button label={'Close'} onClick={() => {
							void restStatus(false, rest);
						}} color='tomato' isLoading={rest.isLoading}></Button>
						<Space size={20} isAutoResize={false}></Space>
						<label className='rest-main-listitem-adapter-dottitle' style={{backgroundColor: 'transparent'}}>OR</label>
						<Space size={20} isAutoResize={false}></Space>
						<Button label={'Open'} onClick={() => {
							void restStatus(true, rest);
						}} color='green' isLoading={rest.isLoading}></Button>
					</div>
					<Space size={30} isAutoResize={false}></Space>
					<label className='rest-main-listitem-adapter-title'>Service Availability (Select all service will apply one availability to all)</label>
					<Space size={10} isAutoResize={false}></Space>
					<div className='rest-main-container-row'>
						<DayTimePicker ref={availability} init={getAvailInit(rest)} isLoading={rest.isLoading}></DayTimePicker>
						<Space size={20} isAutoResize={false}></Space>
						<Button label={'Save'} onClick={() => {
							void restAvail(rest);
						}} color='green' isLoading={rest.isLoading}></Button>
					</div>
					<Space size={30} isAutoResize={false}></Space>
					<label className='rest-main-listitem-adapter-title'>Holiday Shutdown (Select all service will apply one to all)</label>
					<Space size={10} isAutoResize={false}></Space>
					<DateTimeSlot ref={holiday} isLoading={rest.isLoading} onSave={() => {
						void restHoliday(rest);
					}} onDelItem={(data: {
						date: string;
						st: number;
						et: number;
						reason: string;
					}) => {
						void restRemoveHoliday(data, rest);
					}} proExpDate={true} init={getHolidayInit(rest)} ></DateTimeSlot>
					<Space size={30} isAutoResize={false}></Space>
				</div>) : (<div className='rest-main-right-container-cont' style={{display: 'flex', height: '100%', alignItems: 'center', justifyContent: 'center'}}>
					<label className='rest-main-listitem-adapter-subtitle' style={{textAlign: 'center'}}>Select any restaurant to get the details</label>
				</div>)}
			</div>
		</div>)}
	</RestaurantContext.Consumer>;

	return (
		<div className='Restaurants'>
			<SnackRibbon ref={snackBarRibbon}></SnackRibbon>
			<div className='rest-menu-bar'>
				<LabelButton onClick={handleOnMenuClick} index={1} selIndex={selMenuIndex} label={'Restaurants'}></LabelButton>
				<LabelButton onClick={handleOnMenuClick} index={2} selIndex={selMenuIndex} label={'Add Restaurant'}></LabelButton>
				<LabelButton onClick={handleOnMenuClick} index={3} selIndex={selMenuIndex} label={'Coupons'}></LabelButton>
				<LabelButton onClick={handleOnMenuClick} index={4} selIndex={selMenuIndex} label={'Tax'}></LabelButton>
				<LabelButton onClick={handleOnMenuClick} index={5} selIndex={selMenuIndex} label={'Contacts'}></LabelButton>
				<LabelButton onClick={handleOnMenuClick} index={6} selIndex={selMenuIndex} label={'Cities'}></LabelButton>
				<LabelButton onClick={handleOnMenuClick} index={7} selIndex={selMenuIndex} label={'Delivery Charges'}></LabelButton>
				{plugins.whiteList.isSitAllowed && <LabelButton onClick={handleOnMenuClick} index={8} selIndex={selMenuIndex} label={'Sitings'}></LabelButton>}
			</div>
			<div className='rest-menu-content'>{(() => {
				switch (selMenuIndex) {
					case 1:
						return mainPage();
					case 2:
						return <AddRestaurant />;
					case 3:
						return <CouponsOwner />;
					case 4:
						return <Tax />;
					case 5:
						return <Contacts />;
					case 6:
						return <Cities />;
					case 7:
						return <DeliveryCharge />;
					case 8:
						return plugins.whiteList.isSitAllowed ? <SitingsOwner /> : <div />;
					default:
						return <div />;
				}
			})()}</div>
		</div >
	);
};

export default Restaurants;
