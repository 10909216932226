import React, {createContext, useContext, useEffect, useState} from 'react';
import {type UserDetailsModel} from '../models/auth';
import AuthService from '../services/auth';
import Router from '../routes/Router';
import RouteNames from '../routes/RouteNames';

export type AuthContextResult = {
	data: UserDetailsModel | undefined;
	onLoad: (b: boolean) => void;
	isLoading: boolean;
	isAccessApproved: boolean;
};

const AuthContext = createContext<AuthContextResult>({
	data: undefined, isLoading: true, isAccessApproved: false, onLoad() {
		//
	},
});

export function useAuthContext(): AuthContextResult {
	return useContext(AuthContext);
}

export function AuthProvider({children}: {children: JSX.Element}) {
	const [isLoading, setLoading] = useState<boolean>(true);
	const [data, setData] = useState<UserDetailsModel | undefined>();
	const [isAccessApproved, setAccessApproved] = useState<boolean>(false);

	useEffect(() => {
		const fetchUserData = async () => {
			await getUserDetails(false);
		};

		void fetchUserData();
	}, []);

	const getUserDetails = async (hasChild: boolean) => {
		if (!isLoading) {
			setLoading(true);
		}

		await new AuthService().userDetails().then(val => {
			if (val.errorMsg === 'Account not approved') {
				setAccessApproved(false);
			} else if (val.errorMsg === 'Unauthorized') {
				localStorage.clear();
				Router.navigateTo!(RouteNames.auth);
			} else if (val.res) {
				setAccessApproved(val.res.data!.hasAccess);
				if (val.res.data!.hasAccess) {
					setData(val.res.data);
				}
			}
		}).finally(() => {
			if (!hasChild) {
				setLoading(false);
			}
		});
	};

	const onLoad = (b: boolean) => {
		setLoading(b);
	};

	return (
		<AuthContext.Provider value={{data, onLoad, isLoading, isAccessApproved}}>
			{children}
		</AuthContext.Provider>
	);
}

