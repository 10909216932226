import React, {useEffect, useRef, useState} from 'react';
import './Payments.css';
import Space from '../../components/Space/Space';
import ListView from '../../components/ListView/ListView';
import SnackRibbon, {type SnackRibbonHandle} from '../../components/SnackRibbon/SnackRibbon';
import PaymentsService from '../../services/payments';
import {type PaymentsModel} from '../../models/payments';
import {useAuthContext} from '../../context/authContext';

type CustomStyleType = {
	'--justifyContent': string;
};

type ExtendedDivStyle = React.CSSProperties & CustomStyleType;

const Payments = () => {
	const authRes = useAuthContext();

	const [data, setData] = useState<PaymentsModel[]>([]);
	const [selItemIndex, setItemIndex] = useState<number>(-1);
	const [isLoading, setLoading] = useState<boolean>(true);

	const snackBarRibbon = useRef<SnackRibbonHandle>(null);

	useEffect(() => {
		void fetchExp();
	}, []);

	async function fetchExp() {
		if (!isLoading) {
			setLoading(true);
		}

		await new PaymentsService().get(undefined).then(val => {
			if (val.hasError) {
				snackBarRibbon.current!.trigger(val.hasError, val.errorMsg!);
			} else {
				setData(val.res!.data!);
			}
		}).finally(() => {
			setLoading(false);
		});
	}

	const customStyle: ExtendedDivStyle = {
		'--justifyContent': selItemIndex === -1 ? 'center' : 'start',
	};

	return (
		<div className='Payments'>
			<div className='Payments-left-panel'>
				<div className='Payments-left-tools'>
					<label className='Payments-count-label'>{`Total : ${data.length}`}</label>
				</div>
				{isLoading ? (<div className='Payments-left-list'>
					<ListView dummy={5} adapter={function (i, item): JSX.Element {
						return <div className='Payments-list-adapter'>
							<label className='Payments-list-adapter-index-load'>00</label>
							<Space size={5} isAutoResize={true}></Space>
							<div className='Payments-list-adapter-card'>
								<label className='Payments-list-adapter-update-load'>{'Tax Percentage'}</label>
							</div>
						</div>;
					}} selItemIndex={-1} adapterHeight={85} />
				</div>) : (<div className='Payments-left-list'>
					<ListView data={data} adapter={function (i, item): JSX.Element {
						const itemData = item as PaymentsModel;
						return <div className='Payments-list-adapter'>
							<label className='Payments-list-adapter-index'>{i + 1}</label>
							<Space size={5} isAutoResize={true}></Space>
							<div className='Payments-list-adapter-card'>
								<label className='Payments-list-adapter-perc'>{itemData.ordertype.name}</label>
								<Space size={5} isAutoResize={false}></Space>
								<label className='Payments-list-adapter-update'>{itemData.resttype.name}</label>
								<Space size={5} isAutoResize={false}></Space>
								<div className='Payments-list-adapter-row'>
									<label className='Payments-list-adapter-perc'>{itemData.summary.grandTotal}</label>
									<Space size={10} isAutoResize={false}></Space>
									<label className='Payments-list-adapter-perc'>{itemData.paymentStatus.toUpperCase()}</label>
								</div>
								<Space size={5} isAutoResize={false}></Space>
								<label className='Payments-list-adapter-update'>{itemData.paidAt}</label>
							</div>
						</div>;
					}} selItemIndex={selItemIndex} onSelectItem={i => {
						setItemIndex(i);
					}} adapterHeight={85} />
				</div>)}
			</div>
			<div className='Payments-right-panel'>
				<SnackRibbon ref={snackBarRibbon}></SnackRibbon>
				<div className='Payments-right-content' style={customStyle}>
					{selItemIndex === -1 && <label className='Payments-right-empty-content'>No items selected</label>}
					{selItemIndex !== -1 && <div>
						<label className='Payments-detail-no'>{`No : ${selItemIndex + 1}`}</label>
						<Space size={5} isAutoResize={false}></Space>
						<label className='Payments-detail-perc'>{`Order Type : ${data[selItemIndex].ordertype.name}`}</label>
						<Space size={5} isAutoResize={false}></Space>
						<label className='Payments-detail-perc'>{`Service : ${data[selItemIndex].resttype.name}`}</label>
						<Space size={5} isAutoResize={false}></Space>
						<label className='Payments-detail-perc'>{`Payment Mode : ${data[selItemIndex].paymentMode ? data[selItemIndex].paymentMode.name : ''}`}</label>
						<Space size={5} isAutoResize={false}></Space>
						<label className='Payments-detail-perc'>{`Payment Status : ${data[selItemIndex].paymentStatus.toUpperCase()}`}</label>
						<Space size={5} isAutoResize={false}></Space>
						<label className='Payments-detail-perc'>{`Paid At : ${data[selItemIndex].paidAt}`}</label>
						<Space size={5} isAutoResize={false}></Space>
						<label className='Payments-detail-perc'>{data[selItemIndex].isCod ? 'COD' : 'STRIPE'}</label>
						<Space size={5} isAutoResize={false}></Space>
						<label className='Payments-detail-perc'>{`Recived Amount : ${data[selItemIndex].recivedAmount}`}</label>
						<Space size={5} isAutoResize={false}></Space>
						<label className='Payments-detail-perc'>{`Returned Amount : ${data[selItemIndex].returnedAmount}`}</label>
						<Space size={10} isAutoResize={false}></Space>
						<label className='Payments-detail-perc'>Summary</label>
						<Space size={5} isAutoResize={false}></Space>
						<label className='Payments-detail-perc-move'>{`Total : ${data[selItemIndex].summary.total}`}</label>
						<Space size={5} isAutoResize={false}></Space>
						<label className='Payments-detail-perc-move'>{`Discount : ${data[selItemIndex].summary.discount}`}</label>
						<Space size={5} isAutoResize={false}></Space>
						<label className='Payments-detail-perc-move'>{`Sub Total : ${data[selItemIndex].summary.subTotal}`}</label>
						<Space size={5} isAutoResize={false}></Space>
						<label className='Payments-detail-perc-move'>{`Tax : ${data[selItemIndex].summary.tax}`}</label>
						<Space size={5} isAutoResize={false}></Space>
						<label className='Payments-detail-perc-move'>{`Delivey Fees : ${data[selItemIndex].summary.deliveryFee}`}</label>
						<Space size={5} isAutoResize={false}></Space>
						<label className='Payments-detail-perc-move'>{`Coupon Discount : ${data[selItemIndex].summary.couponDiscount}`}</label>
						<Space size={5} isAutoResize={false}></Space>
						<label className='Payments-detail-perc-move'>{`Siting Charge : ${data[selItemIndex].summary.sitingCharge}`}</label>
						<Space size={5} isAutoResize={false}></Space>
						<label className='Payments-detail-perc-move'>{`Extra Charge : ${data[selItemIndex].summary.extraCharges}`}</label>
						<Space size={5} isAutoResize={false}></Space>
						<label className='Payments-detail-perc-move'>{`Grand Total : ${data[selItemIndex].summary.grandTotal}`}</label>
						<div>
							<Space size={15} isAutoResize={false}></Space>
							<label className='Payments-detail-perc'>Restaurant</label>
							<Space size={5} isAutoResize={false}></Space>
							<label className='Payments-detail-perc-move'>{data[selItemIndex].restaurant.name}</label>
							<Space size={5} isAutoResize={false}></Space>
							<label className='Payments-detail-perc-move'>{data[selItemIndex].restaurant.address}</label>
						</div>
					</div>}
				</div>
			</div>
		</div >
	);
};

export default Payments;
