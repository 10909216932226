import React, {forwardRef, useCallback, useImperativeHandle, useRef, useState} from 'react';
import './GoogleMapMain.css';
import {GoogleMap, useLoadScript, Marker, StandaloneSearchBox, Circle, type Libraries} from '@react-google-maps/api';

type Props = {
	height?: string;
	onMark?: (a: string) => void;
	isLoading?: boolean;
};

export type MapHandle = {
	position: () => string;
	setRadius: (s: string) => void;
	focus: () => void;
};

type CustomStyleType = {
	'--height': string;
};

type ExtendedDivStyle = React.CSSProperties & CustomStyleType;

const libraries: Libraries = ['places'];

const GoogleMapMain = forwardRef<MapHandle, Props>((props, ref) => {
	const [selMap, setSelMap] = useState<google.maps.Map | undefined>();
	const [maxRadius, setMaxRadius] = useState<number>(0);
	const [markerPosition, setMarkerPosition] = useState<{lat: number; lng: number}>({
		lat: 10.99835602,
		lng: 77.01502627,
	});
	const [searchBox, setSearchBox] = useState<google.maps.places.SearchBox | undefined>();

	const mapRef = useRef<HTMLDivElement>(null);

	useImperativeHandle(ref, () => ({
		position() {
			return `${markerPosition.lat}:${markerPosition.lng}`;
		},

		setRadius(s: string) {
			setMaxRadius(Number(s) * 1000);
		},

		focus() {
			mapRef.current!.focus();
		},
	}));

	const {isLoaded} = useLoadScript({
		id: 'google-map-script',
		googleMapsApiKey: `${process.env.REACT_APP_MAP_KEY}`,
		libraries,
	});

	const defaultProps = {
		center: markerPosition,
		zoom: 11,
	};

	const onLoad = useCallback((map: google.maps.Map) => {
		// Const bounds = new window.google.maps.LatLngBounds(defaultProps.center);
		// map.fitBounds(bounds);

		setSelMap(map);
	}, []);

	const onUnmount = useCallback((map: google.maps.Map) => {
		setSelMap(undefined);
	}, []);

	const mapOptions = {
		streetViewControl: false,
		fullscreenControl: false,
		mapTypeControl: false,
		maxZoom: 19,
		minZoom: 9,
		keyboardShortcuts: false,
	};

	const onClick = async (event: google.maps.MapMouseEvent) => {
		if (event.latLng && props.isLoading !== true) {
			setMarkerPosition({lat: event.latLng.lat(), lng: event.latLng.lng()});

			if (props.onMark) {
				const geocoder = new window.google.maps.Geocoder();
				const latLng = new window.google.maps.LatLng(event.latLng.lat(), event.latLng.lng());

				await geocoder.geocode({location: latLng}, (results, status) => {
					if (status === window.google.maps.GeocoderStatus.OK && results && results.length !== 0) {
						props.onMark!(results[0].formatted_address);
					}
				});
			}
		}
	};

	const onSearchBoxLoad = (ref: google.maps.places.SearchBox) => {
		setSearchBox(ref);
	};

	const onPlacesChanged = () => {
		if (searchBox) {
			const places = searchBox.getPlaces();
			if (places && places.length > 0) {
				const {lat, lng} = places[0].geometry!.location!;
				setMarkerPosition({lat: lat(), lng: lng()});
			}
		}
	};

	const customStyle: ExtendedDivStyle = {
		'--height': props.height ?? '100%',
	};

	return (
		<div className='GoogleMapMain' style={customStyle} tabIndex={0} ref={mapRef}>
			{isLoaded && <GoogleMap
				mapContainerClassName='containerStyle'
				center={defaultProps.center}
				zoom={defaultProps.zoom}
				onLoad={onLoad}
				onClick={onClick}
				onUnmount={onUnmount}
				mapTypeId='terrain'
				options={mapOptions}>
				<Marker position={markerPosition} />
				{maxRadius > 0 && (
					<Circle
						center={markerPosition}
						radius={maxRadius}
						options={{
							fillColor: '#FF0000',
							fillOpacity: 0.35,
							strokeColor: '#FF0000',
							strokeOpacity: 0.8,
							strokeWeight: 2,
						}}
					/>
				)}
			</GoogleMap>}
			<div className='addrest-search'>
				{isLoaded && props.isLoading !== true && <StandaloneSearchBox onLoad={onSearchBoxLoad} onPlacesChanged={onPlacesChanged}>
					<input
						type='text'
						placeholder='Search for a location'
						style={{
							boxSizing: 'border-box',
							color: 'rgb(80, 80, 80)',
							fontFamily: 'sans-serif',
							border: '1px solid transparent',
							width: '75%',
							height: '32px',
							padding: '0 8px',
							borderRadius: '4px',
							boxShadow: '0 2px 6px rgba(0, 0, 0, 0.3)',
							fontSize: '12px',
							outline: 'none',
							textOverflow: 'ellipsis',
						}}
					/>
				</StandaloneSearchBox>}</div>
		</div>
	);
});

GoogleMapMain.displayName = 'GoogleMapMain';

export default GoogleMapMain;
