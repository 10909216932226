class OverruleSvg {
	svgRaw = `<svg width="26" height="27" viewBox="0 0 26 27" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M19.7791 7.34731C19.69 7.33458 19.6008 7.33458 19.5117 7.34731C17.538 7.28364 15.9717 5.66647 15.9717 3.68002C15.9717 1.65537 17.6144 0 19.6518 0C21.6764 0 23.3318 1.64264 23.3318 3.68002C23.3191 5.66647 21.7528 7.28364 19.7791 7.34731Z" fill="#CF4D4D"/>
    <path d="M23.9335 16.1722C22.5073 17.1273 20.5081 17.4838 18.6618 17.2419C19.1456 16.1977 19.4003 15.0389 19.413 13.8165C19.413 12.5431 19.1329 11.3335 18.5981 10.2766C20.4827 10.0219 22.4818 10.3784 23.9207 11.3335C25.9327 12.6578 25.9327 14.8352 23.9335 16.1722Z" fill="#CF4D4D"/>
    <path d="M5.65891 7.34731C5.74804 7.33458 5.83718 7.33458 5.92632 7.34731C7.90003 7.28364 9.46627 5.66647 9.46627 3.68002C9.46627 1.64264 7.82363 0 5.78625 0C3.7616 0 2.11896 1.64264 2.11896 3.68002C2.11896 5.66647 3.68519 7.28364 5.65891 7.34731Z" fill="#CF4D4D"/>
    <path d="M5.80017 13.8168C5.80017 15.0519 6.06758 16.2234 6.55146 17.2803C4.75601 17.4713 2.88417 17.0893 1.50894 16.1852C-0.502979 14.8482 -0.502979 12.6707 1.50894 11.3337C2.87144 10.4169 4.79422 10.0476 6.60239 10.2513C6.08031 11.321 5.80017 12.5307 5.80017 13.8168Z" fill="#CF4D4D"/>
    <path d="M12.8924 17.6616C12.7906 17.6488 12.676 17.6488 12.5614 17.6616C10.2184 17.5852 8.3465 15.6624 8.3465 13.2939C8.35923 10.8745 10.3075 8.91354 12.7396 8.91354C15.159 8.91354 17.12 10.8745 17.12 13.2939C17.1073 15.6624 15.2482 17.5852 12.8924 17.6616Z" fill="#CF4D4D"/>
    <path d="M8.75397 20.2982C6.83119 21.5843 6.83119 23.6981 8.75397 24.9714C10.9442 26.4358 14.5351 26.4358 16.7253 24.9714C18.648 23.6853 18.648 21.5715 16.7253 20.2982C14.5478 18.8338 10.9569 18.8338 8.75397 20.2982Z" fill="#CF4D4D"/>
    </svg>`;

	replaceColor = '#CF4D4D';

	public normal() {
		return `data:image/svg+xml;utf8,${encodeURIComponent(this.svgRaw)}`;
	}

	public white() {
		return `data:image/svg+xml;utf8,${encodeURIComponent(this.svgRaw.replaceAll(this.replaceColor, '#FFF3F3'))}`;
	}
}

export default OverruleSvg;
