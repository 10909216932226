import React from 'react';
import './OrdersInfo.css';
import {useAuthContext} from '../../../context/authContext';
import Space from '../../../components/Space/Space';
import {SocketContext} from '../../../context/socketContext';

const OrdersInfo = () => {
	const authRes = useAuthContext();

	return (
		<div className='OrdersInfo'>
			<SocketContext.Consumer>
				{so => (<div className='OrdersInfo-grid'>
					<div className='OrdersInfo-grid-card' style={{backgroundColor: 'tomato'}}>
						<div className='OrdersInfo-main-a'>
							<label className='text-h1'>Total</label>
						</div>
						<Space size={8} isAutoResize={false}></Space>
						<div className='OrdersInfo-main-b'>
							<label className='text-h2'>{so.analytics.total}</label>
						</div>
					</div>
					<div className='OrdersInfo-grid-card' style={{backgroundColor: 'cornflowerblue'}}>
						<div className='OrdersInfo-main-a'>
							<label className='text-h1'>Placed</label>
						</div>
						<Space size={8} isAutoResize={false}></Space>
						<div className='OrdersInfo-main-b'>
							<label className='text-h2'>{so.analytics.placed}</label>
						</div>
					</div>
					<div className='OrdersInfo-grid-card' style={{backgroundColor: 'darkorange'}}>
						<div className='OrdersInfo-main-a'>
							<label className='text-h1'>Packed</label>
						</div>
						<Space size={8} isAutoResize={false}></Space>
						<div className='OrdersInfo-main-b'>
							<label className='text-h2'>{so.analytics.packed}</label>
						</div>
					</div>
					<div className='OrdersInfo-grid-card' style={{backgroundColor: 'limegreen'}}>
						<div className='OrdersInfo-main-a'>
							<label className='text-h1'>Shipped</label>
						</div>
						<Space size={8} isAutoResize={false}></Space>
						<div className='OrdersInfo-main-b'>
							<label className='text-h2'>{so.analytics.shipped}</label>
						</div>
					</div>
					<div className='OrdersInfo-grid-card' style={{backgroundColor: 'royalblue'}}>
						<div className='OrdersInfo-main-a'>
							<label className='text-h1'>Revenue</label>
						</div>
						<Space size={8} isAutoResize={false}></Space>
						<div className='OrdersInfo-main-b'>
							<label className='text-h2'>NZ${so.analytics.revenue}</label>
						</div>
					</div>
				</div>)}
			</SocketContext.Consumer>
		</div >
	);
};

export default OrdersInfo;

