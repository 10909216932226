import React, {useEffect, useRef, useState} from 'react';
import './Deliveries.css';
import Space from '../../components/Space/Space';
import ListView from '../../components/ListView/ListView';
import SnackRibbon, {type SnackRibbonHandle} from '../../components/SnackRibbon/SnackRibbon';
import {type DeliveriesModel} from '../../models/deliveries';
import DeliveriesService from '../../services/deliveries';

type CustomStyleType = {
	'--justifyContent': string;
};

type ExtendedDivStyle = React.CSSProperties & CustomStyleType;

const Deliveries = () => {
	const [data, setData] = useState<DeliveriesModel[]>([]);
	const [selItemIndex, setItemIndex] = useState<number>(-1);
	const [isListLoading, setListLoading] = useState<boolean>(true);

	const snackBarRibbon = useRef<SnackRibbonHandle>(null);

	useEffect(() => {
		void fetchCanResv();
	}, []);

	async function fetchCanResv() {
		await new DeliveriesService().get().then(val => {
			if (val.hasError) {
				snackBarRibbon.current!.trigger(val.hasError, val.errorMsg!);
			} else {
				setData(val.res!.data as DeliveriesModel[]);
			}
		}).finally(() => {
			setListLoading(false);
		});
	}

	const customStyle: ExtendedDivStyle = {
		'--justifyContent': selItemIndex === -1 ? 'center' : 'start',
	};

	return (
		<div className='Deliveries'>
			<div className='Deliveries-left-panel'>
				{isListLoading ? (<div className='Deliveries-left-list'>
					<ListView dummy={5} adapter={function (i, item): JSX.Element {
						return <div className='Deliveries-list-adapter'>
							<label className='Deliveries-list-adapter-index-load'>00</label>
							<Space size={5} isAutoResize={true}></Space>
							<div className='Deliveries-list-adapter-card'>
								<label className='Deliveries-list-adapter-update-load'>{'Tax Percentage'}</label>
							</div>
						</div>;
					}} selItemIndex={-1} adapterHeight={65} />
				</div>) : (<div className='Deliveries-left-list'>
					<ListView data={data} adapter={function (i, item): JSX.Element {
						const itemData = item as DeliveriesModel;
						return <div className='Deliveries-list-adapter'>
							<label className='Deliveries-list-adapter-index'>{i + 1}</label>
							<Space size={5} isAutoResize={true}></Space>
							<div className='Deliveries-list-adapter-card'>
								<label className='Deliveries-list-adapter-perc'>{itemData.address.streetAddress}</label>
							</div>
						</div>;
					}} selItemIndex={selItemIndex} onSelectItem={i => {
						setItemIndex(i);
					}} adapterHeight={65} />
				</div>)}
			</div>
			<div className='Deliveries-right-panel'>
				<SnackRibbon ref={snackBarRibbon}></SnackRibbon>
				<div className='Deliveries-right-content' style={customStyle}>
					{selItemIndex === -1 && <label className='Deliveries-right-empty-content'>No items selected</label>}
					{selItemIndex !== -1 && <div>
						<label className='Deliveries-detail-no'>{`No : ${selItemIndex + 1}`}</label>
						<Space size={5} isAutoResize={true}></Space>
						<label className='Deliveries-detail-perc'>{data[selItemIndex].address.nearBy}</label>
						<Space size={5} isAutoResize={true}></Space>
						<label className='Deliveries-detail-perc'>{data[selItemIndex].address.streetAddress}</label>
						<Space size={5} isAutoResize={true}></Space>
						<label className='Deliveries-detail-perc'>{data[selItemIndex].address.gps.cityName}</label>
						<Space size={5} isAutoResize={true}></Space>
						<label className='Deliveries-detail-perc'>{data[selItemIndex].address.contact.isLandline ? data[selItemIndex].address.contact.contactNumber : data[selItemIndex].address.contact.mobileNumber}</label>
					</div>}
				</div>
			</div>
		</div >
	);
};

export default Deliveries;
