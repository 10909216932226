import React, {useContext, useEffect, useRef, useState} from 'react';
import './Settings.css';
import LabelButton from '../../components/LabelButton/LabelButton';
import SnackRibbon, {type SnackRibbonHandle} from '../../components/SnackRibbon/SnackRibbon';
import ListView from '../../components/ListView/ListView';
import Space from '../../components/Space/Space';
import {type RestaurantModel} from '../../models/restaurants';
import {type SettingsModel} from '../../models/settings';
import SettingsService from '../../services/settings';
import ToogleButton from '../../components/ToogleButton/ToogleButton';
import {RestaurantContext} from '../../context/restContext';

const Settings = () => {
	const rests = useContext(RestaurantContext);
	const [selMenuIndex, setMenuIndex] = useState<number>(1);
	const [isSettingLoading, setSettingLoading] = useState<boolean>(false);
	const snackBarRibbon = useRef<SnackRibbonHandle>(null);
	const [settingsData, setSettingsData] = useState<SettingsModel>();
	const [selRest, setSelRest] = useState<number>(-1);

	// DATA --->

	async function fetchSettings(restIndex: number, rest: RestaurantModel[]) {
		if (restIndex !== -1) {
			if (!isSettingLoading) {
				setSettingLoading(true);
			}

			await new SettingsService().get(rest[restIndex]._id).then(val => {
				if (val.hasError || !val.res) {
					snackBarRibbon.current!.trigger(val.hasError, val.errorMsg!);
				} else {
					setSettingsData(val.res.data);
				}
			}).finally(() => {
				setSettingLoading(false);
			});
		}
	}

	const updateSetting = async (b: boolean, rest: RestaurantModel[]) => {
		if (!rests.isLoading && settingsData) {
			if (!isSettingLoading) {
				setSettingLoading(true);
			}

			await new SettingsService().update(settingsData._id, settingsData.restId, b).then(async val => {
				if (val.hasError || !val.res) {
					setSettingLoading(false);
					snackBarRibbon.current!.trigger(val.hasError, val.errorMsg!);
				} else {
					await fetchSettings(selRest, rest);
				}
			});
		}
	};

	// FUNCTIONS --->

	const handleOnMenuClick = (i?: number) => {
		// // Reset the data
		// if (i === 1) {
		// 	void fetchRests();
		// }

		if (selMenuIndex !== i) {
			setMenuIndex(i!);
		}
	};

	const loadState = !rests.isLoading && !isSettingLoading;

	const mainPage = () => <RestaurantContext.Consumer>{
		rest => (<div className='Restaurants-main'>
			<div className='rest-main-left-container' style={{width: '20%'}}>
				{rests.isLoading ? (<ListView dummy={5} adapter={function (i, item): JSX.Element {
					return <div className='rest-main-listview'>
						<label className='rest-main-listitem-adapter-index-load'>00</label>
						<Space size={5} isAutoResize={true}></Space>
						<div className='rest-main-listitem-adapter-card'>
							<label className='rest-main-listitem-adapter-title-load'>{'Tax Percentage : ${}%'}</label>
							<Space size={10} isAutoResize={true}></Space>
							<label className='rest-main-listitem-adapter-title-load'>{'Updated : ${}'}</label>
						</div>
					</div>;
				}} selItemIndex={-1} adapterHeight={80} />) : (<ListView data={rest.data} adapter={function (i, item): JSX.Element {
					const itemData = item as RestaurantModel;
					return <div className='rest-main-listview'>
						<label className='rest-main-listitem-adapter-index'>{i + 1}</label>
						<Space size={5} isAutoResize={true}></Space>
						<div className='rest-main-listitem-adapter-card'>
							<label className='rest-main-listitem-adapter-title'>{itemData.name}</label>
							<Space size={5} isAutoResize={true}></Space>
							<label className='rest-main-listitem-adapter-subtitle'>{itemData.address}</label>
						</div>
					</div>;
				}} onSelectItem={i => {
					if (selRest !== i) {
						setSelRest(i);
						setSettingsData(undefined);
						void fetchSettings(i, rest.data);
					}
				}} selItemIndex={selRest} adapterHeight={80} />)}
			</div>
			<div className='rest-main-right-container'>
				{loadState && settingsData ? (<div className='rest-main-right-container-cont'>
					<Space size={10} isAutoResize={false}></Space>
					<label className='rest-main-listitem-adapter-title'>{`Last Token No : ${settingsData.lastToken}`}</label>
					<Space size={5} isAutoResize={false}></Space>
					<div className='rest-main-listitem-row' style={{width: '30%'}}>
						<label className='rest-main-listitem-adapter-title'>{'Auto Accept Order :'}</label>
						<ToogleButton onClick={async (b: boolean) => {
							await updateSetting(b, rest.data);
						}} isLoading={rests.isLoading} init={settingsData.autoAcceptOrder}></ToogleButton>
					</div>
					<Space size={5} isAutoResize={false}></Space>
				</div>) : (<div className='rest-main-right-container-cont' style={{display: 'flex', height: '100%', alignItems: 'center', justifyContent: 'center'}}>
					<label className='rest-main-listitem-adapter-subtitle' style={{textAlign: 'center'}}>Select any restaurant to get the order details</label>
				</div>)}
			</div>
		</div>)}</RestaurantContext.Consumer>;

	return (
		<div className='Settings'>
			<SnackRibbon ref={snackBarRibbon}></SnackRibbon>
			<div className='Settings-menu-bar'>
				<LabelButton onClick={handleOnMenuClick} index={1} selIndex={selMenuIndex} label={'All'}></LabelButton>
			</div>
			<div className='Settings-menu-content'>{(() => {
				switch (selMenuIndex) {
					case 1:
						return mainPage();
					default:
						return <div />;
				}
			})()}</div>
		</div >
	);
};

export default Settings;
