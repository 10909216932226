import React, {useEffect, useRef, useState} from 'react';
import './Timekeeping.css';
import Space from '../../components/Space/Space';
import ListView from '../../components/ListView/ListView';
import SnackRibbon, {type SnackRibbonHandle} from '../../components/SnackRibbon/SnackRibbon';
import Button from '../../components/Button/Button';
import OverruleService from '../../services/overrule';
import {type AttendanceMonthModel, type AttendanceModel, type AttendanceLogsModel, type AttendanceBreaksModel} from '../../models/attendance';

type CustomStyleType = {
	'--justifyContent': string;
};

type ExtendedDivStyle = React.CSSProperties & CustomStyleType;

const Timekeeping = () => {
	const [data, setData] = useState<AttendanceModel[]>([]);
	const [selUserIndex, setUserIndex] = useState<number>(-1);
	const [selMonthIndex, setMonthIndex] = useState<number>(-1);
	const [isLoading, setLoading] = useState<boolean>(true);
	const snackBarRibbon = useRef<SnackRibbonHandle>(null);

	useEffect(() => {
		void fetchData();
	}, []);

	async function fetchData() {
		if (!isLoading) {
			setLoading(true);
		}

		await new OverruleService().gettimekeeping().then(val => {
			if (val.hasError || !val.res || (val.res && !val.res.data)) {
				snackBarRibbon.current!.trigger(val.hasError, val.errorMsg!);
			} else {
				setData(val.res.data!);
			}
		}).finally(() => {
			setLoading(false);
		});
	}

	const customStyle: ExtendedDivStyle = {
		'--justifyContent': selUserIndex === -1 || selMonthIndex === -1 ? 'center' : 'start',
	};

	// FUNCTIONS ---->

	return (
		<div className='Restaurants'>
			<SnackRibbon ref={snackBarRibbon}></SnackRibbon>
			<div className='Timekeeping'>
				<div className='rest-main-left-container' style={{width: '20%'}}>
					{isLoading ? (<div className='Timekeeping-left-list'>
						<ListView dummy={5} adapter={function (i, item): JSX.Element {
							return <div className='Timekeeping-list-adapter'>
								<label className='Timekeeping-list-adapter-index-load'>00</label>
								<Space size={5} isAutoResize={true}></Space>
								<div className='Timekeeping-list-adapter-card'>
									<label className='Timekeeping-list-adapter-update-load'>{'Tax Percentage'}</label>
								</div>
							</div>;
						}} selItemIndex={-1} adapterHeight={45} />
					</div>) : (<div className='Timekeeping-left-list'>
						<ListView data={data} adapter={function (i, item): JSX.Element {
							const itemData = item as AttendanceModel;
							return <div className='Timekeeping-list-adapter'>
								<label className='Timekeeping-list-adapter-index'>{i + 1}</label>
								<Space size={5} isAutoResize={true}></Space>
								<div className='Timekeeping-list-adapter-card'>
									<label className='Timekeeping-list-adapter-perc'>{itemData._id}</label>
								</div>
							</div>;
						}} selItemIndex={selUserIndex} onSelectItem={i => {
							setUserIndex(i);
						}} adapterHeight={45} />
					</div>)}
				</div>
				{selUserIndex !== -1 && <div className='rest-main-left-container' style={{width: '20%'}}>
					{isLoading ? (<div className='Timekeeping-left-list'>
						<ListView dummy={5} adapter={function (i, item): JSX.Element {
							return <div className='Timekeeping-list-adapter'>
								<label className='Timekeeping-list-adapter-index-load'>00</label>
								<Space size={5} isAutoResize={true}></Space>
								<div className='Timekeeping-list-adapter-card'>
									<label className='Timekeeping-list-adapter-update-load'>{'Tax Percentage'}</label>
								</div>
							</div>;
						}} selItemIndex={-1} adapterHeight={45} />
					</div>) : (<div className='Timekeeping-left-list'>
						<ListView data={data[selUserIndex].monthlyData} adapter={function (i, item): JSX.Element {
							return <div className='Timekeeping-list-adapter'>
								<label className='Timekeeping-list-adapter-index'>{i + 1}</label>
								<Space size={5} isAutoResize={true}></Space>
								<div className='Timekeeping-list-adapter-card'>
									<label className='Timekeeping-list-adapter-perc'>{(item as AttendanceMonthModel).yearMonth}</label>
									<Space size={3} isAutoResize={false}></Space>
									<label className='Timekeeping-list-adapter-perc'>{`${(item as AttendanceMonthModel).totalHours} hr`}</label>
								</div>
							</div>;
						}} selItemIndex={selMonthIndex} onSelectItem={i => {
							setMonthIndex(i);
						}} adapterHeight={45} />
					</div>)}
				</div>}
				<div className='rest-main-right-container'>
					<SnackRibbon ref={snackBarRibbon}></SnackRibbon>
					<div className='Timekeeping-right-content' style={customStyle}>
						{(selUserIndex === -1 || selMonthIndex === -1) && <label className='Timekeeping-right-empty-content'>No items selected</label>}
						{selUserIndex !== -1 && selMonthIndex !== -1 && <div>
							<label className='Timekeeping-detail-perc'>{data[selUserIndex].monthlyData[selMonthIndex].yearMonth}</label>
							<Space size={5} isAutoResize={false}></Space>
							<label className='Timekeeping-detail-perc'>{`Total Hour : ${data[selUserIndex].monthlyData[selMonthIndex].totalHours}`}</label>
							<Space size={15} isAutoResize={false}></Space>
							<ListView data={data[selUserIndex].monthlyData[selMonthIndex].logs} adapter={function (i, item): JSX.Element {
								return <div className='Timekeeping-list-adapter'>
									<label className='Timekeeping-list-adapter-index'>{i + 1}</label>
									<Space size={5} isAutoResize={true}></Space>
									<div className='Timekeeping-list-adapter-card' style={{width: '90%'}}>
										<label className='Timekeeping-list-adapter-perc'>{(item as AttendanceLogsModel).date}</label>
										<Space size={3} isAutoResize={false}></Space>
										<label className='Timekeeping-list-adapter-update'>{(item as AttendanceLogsModel).punchIn}</label>
										<Space size={3} isAutoResize={false}></Space>
										<label className='Timekeeping-list-adapter-update'>{(item as AttendanceLogsModel).punchOut}</label>
										{(item.breaks as AttendanceLogsModel[]).length !== 0 && <Space size={5} isAutoResize={false}></Space>}
										{(item.breaks as AttendanceLogsModel[]).length !== 0 && <label className='Timekeeping-list-adapter-update'>Breaks</label>}
										{(item.breaks as AttendanceLogsModel[]).length !== 0 && <Space size={3} isAutoResize={false}></Space>}
										{(item.breaks as AttendanceLogsModel[]).length !== 0 && <div className='Timekeeping-list-adapter-break'>
											<ListView data={item.breaks as AttendanceLogsModel[]} adapter={function (i, item): JSX.Element {
												return <div className='Timekeeping-list-adapter' style={{width: 210}}>
													<label className='Timekeeping-list-adapter-index'>{i + 1}</label>
													<Space size={5} isAutoResize={true}></Space>
													<div className='Timekeeping-list-adapter-card'>
														<label className='Timekeeping-list-adapter-update'>{(item as AttendanceBreaksModel).startAt}</label>
														<Space size={3} isAutoResize={false}></Space>
														<label className='Timekeeping-list-adapter-update'>{(item as AttendanceBreaksModel).endAt}</label>
													</div>
												</div>;
											}} isHorizontal={true} />
										</div>}
									</div>
								</div>;
							}} adapterHeight={150} />
							<Space size={10} isAutoResize={false}></Space>
						</div>}
					</div>
				</div>
			</div >

		</div >
	);
};

export default Timekeeping;
