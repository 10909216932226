import React, {useRef, useImperativeHandle, forwardRef, useState, useEffect} from 'react';
import './DateTimeSlot.css';
import Space from '../Space/Space';
import TimePicker, {type TimePickerHandle} from '../TimePicker/TimePicker';
import ListView from '../ListView/ListView';
import Button from '../Button/Button';
import IconButton from '../IconButton/IconButton';
import Delete from '../../assets/icons/delete';
import TextField, {type TextFieldHandle} from '../TextField/TextField';
import TextFieldStyles from '../../utils/textfield/fieldstyles';
import TextFieldInputTypes from '../../utils/textfield/fieldtypes';

type Props = {
	isLoading?: boolean;
	color?: string;
	onSave: () => void;
	onDelItem: (d: DateTimes) => void;
	proExpDate?: boolean;
	init?: DateTimes[];
};

export type DateTimeSlotHandle = {
	getValue: () => DateTimes[];
	focus: () => void;
};

type CustomStyleType = {
	'--marBottom': string;
	'--isActive': string;
};

type ExtendedDivStyle = React.CSSProperties & CustomStyleType;

type DateTimes = {
	date: string;
	st: number;
	et: number;
	reason: string;
};

const DateTimeSlot = forwardRef<DateTimeSlotHandle, Props>((props, ref) => {
	const [dateTimes, setDateTimes] = useState<DateTimes[]>([]);
	const sTimeRef = useRef<TimePickerHandle>(null);
	const eTimeRef = useRef<TimePickerHandle>(null);
	const mainref = useRef<HTMLDivElement>(null);
	const delIcon = new Delete();
	const dateRef = useRef<HTMLInputElement>(null);
	const [selDate, setSelDate] = useState<string>('---- -- --');
	const inputRef = useRef<TextFieldHandle>(null);

	useImperativeHandle(ref, () => ({
		getValue() {
			return dateTimes;
		},

		focus() {
			mainref.current!.focus();
		},
	}));

	useEffect(() => {
		const sets = () => {
			if (props.init) {
				setDateTimes(props.init);
			} else {
				setDateTimes([]);
			}
		};

		sets();
	}, [props.init]);

	const handleAddTime = () => {
		if (props.isLoading !== true) {
			const reason = inputRef.current!.validate();

			const newStRaw = sTimeRef.current!.pickedTime();
			const newEtRaw = eTimeRef.current!.pickedTime();

			const newStime = parseFloat(sTimeRef.current!.pickedTime().replace(':', '.'));
			const newEtime = parseFloat(eTimeRef.current!.pickedTime().replace(':', '.'));

			if (reason.isValid && dateRef.current && Number(newStRaw.split(':')[0]) !== 0 && (Number(newStRaw.split(':')[0]) === Number(newEtRaw.split(':')[0])) && (Number(newStRaw.split(':')[1]) < Number(newEtRaw.split(':')[1]))) {
				const date = dateRef.current.value.replaceAll('-', ':');
				if (newStime !== 0 && newEtime !== 0 && selDate !== '---- -- --' && !dateTimes.map(v => v.date).includes(date)) {
					setDateTimes([...dateTimes, {date, st: newStime, et: newEtime, reason: inputRef.current!.getValue()}]);
				}
			} else if (reason.isValid && dateRef.current && Number(newStRaw.split(':')[0]) !== 0 && Number(newStRaw.split(':')[0]) < Number(newEtRaw.split(':')[0])) {
				const date = dateRef.current.value.replaceAll('-', ':');
				if (newStime !== 0 && newEtime !== 0 && selDate !== '---- -- --' && !dateTimes.map(v => v.date).includes(date)) {
					setDateTimes([...dateTimes, {date, st: newStime, et: newEtime, reason: inputRef.current!.getValue()}]);
				}
			}
		}
	};

	const handleDelTime = (i: number) => {
		if (props.isLoading !== true) {
			if (dateTimes[i]) {
				setDateTimes(dateTimes.filter(v => v !== dateTimes[i]));
				props.onDelItem(dateTimes[i]);
			}
		}
	};

	const protectExpDate = (dt: string): boolean => {
		// Protect expired date from deleting
		if (props.proExpDate === true) {
			const now = new Date();
			const hol = new Date(dt.replaceAll(':', '-'));

			return hol > now;
		}

		return true;
	};

	const month = new Date().getMonth() + 1;
	const date = new Date().getDate() + 1;
	const today = `${new Date().getFullYear()}-${month.toString().length === 1 ? '0' + month : month}-${date.toString().length === 1 ? '0' + date : date}`;

	return (
		<div className='DateTimeSlot' tabIndex={0} ref={mainref}>
			<div className='DateTimeSlot-cont-col'>
				<div className='DateTimeSlot-cont'>
					<TextField ref={inputRef} isLoading={props.isLoading === true}
						label={'Enter shutdown reason'} styles={TextFieldStyles.normal}
						inputType={TextFieldInputTypes.name}></TextField>
					<Space size={15} isAutoResize={false}></Space>
					<label className='DateTimeSlot-label'>Date</label>
					<Space size={5} isAutoResize={false}></Space>
					<input ref={dateRef} className='DateTimeSlot-date' type='date' min={today} onChange={() => {
						setSelDate(dateRef.current!.valueAsDate!.toDateString());
						if (!navigator.userAgent.includes('Chrome')) {
							dateRef.current!.type = 'text';
							dateRef.current!.type = 'date';
						}
					}}></input>
					<div className='DateTimeSlot-date-pick' onClick={() => {
						if (props.isLoading !== true) {
							dateRef.current!.focus();
							dateRef.current!.showPicker();
							dateRef.current!.click();
						}
					}}>{selDate}</div>
				</div>
				<Space size={10} isAutoResize={false}></Space>
				<div className='DateTimeSlot-cont'>
					<label className='DateTimeSlot-label'>From</label>
					<Space size={5} isAutoResize={false}></Space>
					<TimePicker ref={sTimeRef} isLoading={props.isLoading === true} onChange={() => {
						//
					}}></TimePicker>
					<Space size={10} isAutoResize={false}></Space>
					<label className='DateTimeSlot-label'>Until</label>
					<Space size={5} isAutoResize={false}></Space>
					<TimePicker ref={eTimeRef} isLoading={props.isLoading === true} onChange={() => {
						//
					}}></TimePicker>
					<Space size={10} isAutoResize={false}></Space>
					<Button isLoading={props.isLoading} label={'Add'} onClick={handleAddTime} color='tomato'></Button>
					<Space size={10} isAutoResize={false}></Space>
					<Button isLoading={props.isLoading} label={'Save'} onClick={() => {
						if (props.isLoading !== true) {
							props.onSave();
						}
					}}></Button>
				</div>
			</div>
			<Space size={20} isAutoResize={false}></Space>
			<div className='DateTimeSlot-listview'><ListView data={dateTimes} adapter={function (i: number, item: DateTimes | number): JSX.Element {
				const itemData = item as DateTimes;
				return <div className='DateTimeSlot-list-adapter'>
					<label className='DateTimeSlot-list-adapter-index'>{i + 1}</label>
					<div className='DateTimeSlot-list-adapter-card'>
						<label className='DateTimeSlot-list-adapter-title'>{`${itemData.date} | ${itemData.st} | ${itemData.et} || ${itemData.reason}`}</label>
						<Space size={10} isAutoResize={true}></Space>
						{protectExpDate(itemData.date) && <IconButton hasSplashBg={true} icon={delIcon.white()} bgColor='rgb(251, 16, 51)' iconSize={10} onClick={() => {
							handleDelTime(i);
						}}></IconButton>}
						<Space size={10} isAutoResize={false}></Space>
					</div>
				</div>;
			}} adapterHeight={35} /></div>
		</div>

	);
});

DateTimeSlot.displayName = 'DateTimeSlot';

export default DateTimeSlot;
