import React, {useRef, useImperativeHandle, forwardRef, useState, useEffect} from 'react';
import './DatePicker.css';
import Space from '../Space/Space';

type Props = {
	isLoading?: boolean;
	color?: string;
	init?: string;
	label: string;
	hasPast?: boolean;
};

export type DatePickerHandle = {
	getValue: () => string | undefined;
	setValue: (v: string) => void;
	focus: () => void;
};

type CustomStyleType = {
	'--marBottom': string;
	'--isActive': string;
};

type ExtendedDivStyle = React.CSSProperties & CustomStyleType;

const months = [
	{mo: 'Jan', id: '01'},
	{mo: 'Feb', id: '02'},
	{mo: 'Mar', id: '03'},
	{mo: 'Apr', id: '04'},
	{mo: 'May', id: '05'},
	{mo: 'Jun', id: '06'},
	{mo: 'Jul', id: '07'},
	{mo: 'Aug', id: '08'},
	{mo: 'Sep', id: '09'},
	{mo: 'Oct', id: '10'},
	{mo: 'Nov', id: '11'},
	{mo: 'Dec', id: '12'},
];

const DatePicker = forwardRef<DatePickerHandle, Props>((props, ref) => {
	const mainref = useRef<HTMLDivElement>(null);
	const dateStRef = useRef<HTMLInputElement>(null);
	const [selStDate, setSelStDate] = useState<string>('---- -- --');
	const [initVal, setInitVal] = useState<string | undefined>(undefined);

	useEffect(() => {
		if (props.init) {
			setInitVal(props.init);
		}
	}, [props.init]);

	useImperativeHandle(ref, () => ({
		getValue() {
			return result();
		},

		setValue(v: string) {
			setInitVal(v);
		},

		focus() {
			mainref.current!.focus();
		},
	}));

	const result = () => {
		if (props.isLoading !== true) {
			if (selStDate === '---- -- --') {
				return undefined;
			}

			// 2023:10:28-12:00-2023:10:30-11:00
			// 2023:10:05-05:10-2023:10:18-20:09
			// Thu Oct 05 2023-05:06:00-Wed Oct 18 2023-020:09:00
			const stD = `${selStDate.replaceAll('-', ':').split(' ')[3]}:${months.find(f => f.mo === selStDate.replaceAll('-', ':').split(' ')[1])!.id}:${selStDate.replaceAll('-', ':').split(' ')[2]}`;

			return stD;
		}
	};

	const month = new Date().getMonth() + 1;
	const date = new Date().getDate();
	const today = `${new Date().getFullYear()}-${month.toString().length === 1 ? '0' + month : month}-${date.toString().length === 1 ? '0' + date : date}`;

	return (
		<div className='DatePicker' tabIndex={0} ref={mainref}>
			<label className='DatePicker-title'>{props.label}</label>
			<Space size={5} isAutoResize={false}></Space>
			<div className='DatePicker-cont'>
				<input ref={dateStRef} className='DatePicker-date' type='date' min={props.hasPast ? undefined : today} onChange={() => {
					setSelStDate(dateStRef.current!.valueAsDate!.toDateString());
					if (!navigator.userAgent.includes('Chrome')) {
						dateStRef.current!.type = 'text';
						dateStRef.current!.type = 'date';
					}
				}}></input>
				<div className='DatePicker-date-pick' onClick={() => {
					if (props.isLoading !== true) {
						dateStRef.current!.focus();
						dateStRef.current!.showPicker();
						dateStRef.current!.click();
					}
				}}>{selStDate}</div>
			</div>
		</div>

	);
});

DatePicker.displayName = 'DatePicker';

export default DatePicker;
