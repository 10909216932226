class InventorySvg {
	svgRaw = `<svg width="26" height="27" viewBox="0 0 26 27" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M12.6387 0.265741L9.49886 7.57463H6.35903C5.8379 7.57463 5.32979 7.61372 4.83472 7.71794L6.13755 4.59115L6.18966 4.47389L6.26783 4.26544C6.30692 4.17424 6.33297 4.09607 6.37206 4.03093C7.88335 0.526307 9.57703 -0.568072 12.6387 0.265741Z" fill="#CF4D4D"/>
    <path d="M21.4874 7.92347L21.4613 7.91044C20.6796 7.68896 19.8849 7.5717 19.0771 7.5717H10.9214L13.8528 0.757887L13.8918 0.666689C14.0742 0.731831 14.2697 0.823029 14.4651 0.88817L17.3444 2.0998C18.9468 2.76425 20.0673 3.45475 20.7578 4.28856C20.875 4.4449 20.9793 4.58822 21.0835 4.75758C21.2007 4.93998 21.2919 5.12238 21.344 5.3178C21.3962 5.43506 21.4352 5.53928 21.4613 5.65654C21.6567 6.32098 21.6698 7.07663 21.4874 7.92347Z" fill="#CF4D4D"/>
    <path d="M13.3947 20.3897H13.7204C14.1112 20.3897 14.4369 20.0379 14.4369 19.608C14.4369 19.0608 14.2806 18.9827 13.9418 18.8524L13.3947 18.6569V20.3897Z" fill="#CF4D4D"/>
    <path d="M20.9105 9.78753C20.3242 9.61816 19.7119 9.52696 19.0735 9.52696H6.35782C5.4719 9.52696 4.63808 9.69633 3.85638 10.0351C1.58946 11.0122 0 13.2661 0 15.8848V18.4253C0 18.738 0.0260566 19.0376 0.0651416 19.3503C0.351765 23.4933 2.56658 25.7081 6.70959 25.9817C7.00924 26.0208 7.30889 26.0469 7.6346 26.0469H17.7967C22.6172 26.0469 25.1577 23.7539 25.4053 19.194C25.4183 18.9464 25.4313 18.6859 25.4313 18.4253V15.8848C25.4313 13.0055 23.5162 10.5822 20.9105 9.78753ZM14.3833 17.5785C14.9826 17.7869 15.7904 18.2299 15.7904 19.6109C15.7904 20.7965 14.8653 21.7475 13.7189 21.7475H13.3931V22.0341C13.3931 22.412 13.0935 22.7116 12.7157 22.7116C12.3379 22.7116 12.0382 22.412 12.0382 22.0341V21.7475H11.9209C10.6702 21.7475 9.64096 20.6922 9.64096 19.3894C9.64096 19.0116 9.94061 18.7119 10.3185 18.7119C10.6963 18.7119 10.9959 19.0116 10.9959 19.3894C10.9959 19.9366 11.4128 20.3926 11.9209 20.3926H12.0382V18.1908L11.048 17.839C10.4487 17.6306 9.64096 17.1876 9.64096 15.8066C9.64096 14.621 10.566 13.67 11.7125 13.67H12.0382V13.3833C12.0382 13.0055 12.3379 12.7059 12.7157 12.7059C13.0935 12.7059 13.3931 13.0055 13.3931 13.3833V13.67H13.5104C14.7611 13.67 15.7904 14.7253 15.7904 16.0281C15.7904 16.4059 15.4907 16.7056 15.1129 16.7056C14.7351 16.7056 14.4354 16.4059 14.4354 16.0281C14.4354 15.4809 14.0185 15.0249 13.5104 15.0249H13.3931V17.2267L14.3833 17.5785Z" fill="#CF4D4D"/>
    <path d="M10.9977 15.805C10.9977 16.3522 11.1541 16.4304 11.4928 16.5607L12.04 16.7561V15.0233H11.7143C11.3104 15.0233 10.9977 15.3751 10.9977 15.805Z" fill="#CF4D4D"/>
    </svg>`;

	replaceColor = '#CF4D4D';

	public normal() {
		return `data:image/svg+xml;utf8,${encodeURIComponent(this.svgRaw)}`;
	}

	public white() {
		return `data:image/svg+xml;utf8,${encodeURIComponent(this.svgRaw.replaceAll(this.replaceColor, '#FFF3F3'))}`;
	}
}

export default InventorySvg;
