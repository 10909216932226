import axios, {type AxiosError} from 'axios';
import endpoints from '../utils/endpoints';
import messages from '../utils/messages';
import {type ResponseData, type ServiceResponse} from '../models/response';
import {serviceError, serviceSuccess} from '../utils/serviceHandler';
import {type ReqPurchasesModel, type PurchasesModel, type ReqPurchasesStockModel} from '../models/purchases';

type ServerMessage = {
	message: string;
};

class PurchasesService {
	get = async (restId: string | undefined): Promise<ServiceResponse<ResponseData<PurchasesModel[]>>> => {
		try {
			const response = restId ? await axios.post<ResponseData<PurchasesModel[]>>(endpoints.purchases,
				{restId},
				{
					headers: {
						Authorization: `Bearer ${localStorage.getItem('AUT-OWNER')!}`,
						'Content-Type': 'application/json',
					},
				}) : await axios.get<ResponseData<PurchasesModel[]>>(endpoints.purchases,
				{
					headers: {
						Authorization: `Bearer ${localStorage.getItem('AUT-OWNER')!}`,
						'Content-Type': 'application/json',
					},
				});

			await serviceSuccess();
			return {hasError: false, res: response.data};
		} catch (error) {
			const axiosError = error as AxiosError<ServerMessage>;
			if (axiosError.response?.data?.message) {
				await serviceError(axiosError.response?.data?.message);

				// Access the error message from the server response
				const errorMessage = axiosError.response.data.message;
				return {hasError: true, errorMsg: errorMessage};
			}

			return {hasError: true, errorMsg: messages.Ise};
		}
	};

	create = async (req: ReqPurchasesModel): Promise<ServiceResponse<ResponseData<undefined>>> => {
		try {
			const response = await axios.post<ResponseData<undefined>>(endpoints.purchase,
				req,
				{
					headers: {
						Authorization: `Bearer ${localStorage.getItem('AUT-OWNER')!}`,
						'Content-Type': 'application/json',
					},
				});
			await serviceSuccess();
			return {hasError: false, res: response.data};
		} catch (error) {
			const axiosError = error as AxiosError<ServerMessage>;
			if (axiosError.response?.data?.message) {
				await serviceError(axiosError.response?.data?.message);

				// Access the error message from the server response
				const errorMessage = axiosError.response.data.message;
				return {hasError: true, errorMsg: errorMessage};
			}

			return {hasError: true, errorMsg: messages.Ise};
		}
	};

	stockupdate = async (req: ReqPurchasesStockModel): Promise<ServiceResponse<ResponseData<undefined>>> => {
		try {
			const response = await axios.patch<ResponseData<undefined>>(endpoints.purchasestock,
				req,
				{
					headers: {
						Authorization: `Bearer ${localStorage.getItem('AUT-OWNER')!}`,
						'Content-Type': 'application/json',
					},
				});
			await serviceSuccess();
			return {hasError: false, res: response.data};
		} catch (error) {
			const axiosError = error as AxiosError<ServerMessage>;
			if (axiosError.response?.data?.message) {
				await serviceError(axiosError.response?.data?.message);

				// Access the error message from the server response
				const errorMessage = axiosError.response.data.message;
				return {hasError: true, errorMsg: errorMessage};
			}

			return {hasError: true, errorMsg: messages.Ise};
		}
	};
}

export default PurchasesService;
