import React, {useRef, useState, useEffect} from 'react';
import './Plugins.css';
import {usePluginContext} from '../../context/pluginContext';
import {type PluginsModel} from '../../models/plugins';
import Space from '../../components/Space/Space';
import ToogleButton from '../../components/ToogleButton/ToogleButton';
import {type RestaurantModel} from '../../models/restaurants';
import ListView from '../../components/ListView/ListView';
import CheckBox from '../../components/CheckBox/CheckBox';
import SnackRibbon, {type SnackRibbonHandle} from '../../components/SnackRibbon/SnackRibbon';
import PluginsService from '../../services/plugins';
import {useAuthContext} from '../../context/authContext';
import {RestaurantContext} from '../../context/restContext';

const Plugins = () => {
	const plugins = usePluginContext();
	const authRes = useAuthContext();

	const [isRestLoading, setRestLoad] = useState<boolean>(false);
	const refSnack = useRef<SnackRibbonHandle>(null);

	const assginRest = async (plugId: string, restId: string) => {
		if (!isRestLoading) {
			setRestLoad(true);
		}

		await new PluginsService().assgin(plugId, restId).then(async val => {
			if (val.hasError) {
				refSnack.current!.trigger(val.hasError, val.errorMsg!);
			} else {
				refSnack.current!.trigger(false, val.res!.message);
				await plugins.fetchData();
			}
		}).finally(() => {
			setRestLoad(false);
		});
	};

	const distribute = async (plugId: string, isDistribute: boolean) => {
		if (!isRestLoading) {
			setRestLoad(true);
		}

		await new PluginsService().distribute(plugId, isDistribute).then(async val => {
			if (val.hasError) {
				refSnack.current!.trigger(val.hasError, val.errorMsg!);
			} else {
				refSnack.current!.trigger(false, val.res!.message);
				await plugins.fetchData();
			}
		}).finally(() => {
			setRestLoad(false);
		});
	};

	const trigger = async (plugId: string, isEnable: boolean) => {
		// If (!isRestLoading) {
		// 	setRestLoad(true);
		// }

		// await new PluginsService().trigger(authRes.restData![authRes.selRestRef]._id, plugId, isEnable).then(async val => {
		// 	if (val.hasError) {
		// 		refSnack.current!.trigger(val.hasError, val.errorMsg!);
		// 	} else {
		// 		refSnack.current!.trigger(false, val.res!.message);
		// 		await plugins.fetchData();
		// 	}
		// }).finally(() => {
		// 	setRestLoad(false);
		// });
	};

	return (
		<div className='Plugins'>
			<SnackRibbon ref={refSnack}></SnackRibbon>
			<div className='Plugins-content'>
				{!plugins.isLoading && plugins.data.map((item: PluginsModel, i: number) => (<div key={i}>
					<div className='Plugins-wrapper'>
						<label className='Plugins-title'>{item._id}</label>
						<RestaurantContext.Consumer>
							{rest => (
								<div className='Plugins-card'>
									{item.plugins.map((plugItem, j) =>
										// Const availableTo = authRes.restData ? plugItem.availableTo.find(v => v.restaurant._id === authRes.restData![authRes.selRestRef]._id) : undefined;
										(<div key={j} className='Plugins-card-item'>
											<div className='Plugins-card-item-row'>
												<img className='Plugins-card-item-icon' src={plugItem.icon}>{plugItem.icon}</img>
												<Space size={15} isAutoResize={false}></Space>
												<div className='Plugins-card-item-column' style={{width: '73%'}}>
													<label className='Plugins-card-item-title'>{plugItem.name}</label>
													<Space size={4} isAutoResize={false}></Space>
													<label className='Plugins-card-item-subtitle'>
														{plugItem.isAvailable ? 'available' : 'not-available'}</label>
												</div>
												<Space size={15} isAutoResize={false}></Space>
												<div className='Plugins-card-item-column' style={{width: '15%'}}>
													<ToogleButton onClick={(c: boolean) => {
														void distribute(plugItem._id, c);
													}} width='28px' init={plugItem.isDistributed} isDisabled={!plugItem.isAvailable}></ToogleButton>
													<Space size={10} isAutoResize={false}></Space>
													{plugItem.isAvailable && <label className='Plugins-card-item-subtitle'>
														{plugItem.isDistributed ? 'distributed' : 'not-distributed'}</label>}
												</div>
												{/* {!isOwner && availableTo && authRes.restData && <div className='Plugins-card-item-column' style={{width: '15%'}}>
											<ToogleButton onClick={(c: boolean) => {
												void trigger(plugItem._id, c);
											}} width='28px' init={availableTo.isSelected} isDisabled={!plugItem.isAvailable || !plugItem.isDistributed}></ToogleButton>
											<Space size={10} isAutoResize={false}></Space>
											{plugItem.isAvailable && plugItem.isDistributed && <label className='Plugins-card-item-subtitle'>
												{availableTo.isSelected ? 'triggered' : 'not-triggered'}</label>}
										</div>} */}
											</div>
											<Space size={15} isAutoResize={false}></Space>
											<ul>
												<li className='Plugins-card-item-title' style={{fontSize: '10px'}}>{'The features only available when distributed is enabled'}</li>
												<li className='Plugins-card-item-title' style={{fontSize: '10px'}}>{'If configuration is mandatory, must fill the configuration before enable distributed'}</li>
												<li className='Plugins-card-item-title' style={{fontSize: '10px'}}>{'Mandatory to add restaurant and manager'}</li>
												<li className='Plugins-card-item-title' style={{fontSize: '10px'}}>{'When distributed is enabled, manager whoever is added to this plugin must enable the plugin from their dashboard'}</li>
											</ul>
											{!plugItem.isAvailable && <div className='Plugins-card-item-column'>
												<Space size={15} isAutoResize={false}></Space>
												<label className='Plugins-card-item-title' style={{fontSize: '12px', color: 'rgb(228, 58, 58)'}}>{'Please contact Catalizo to use this plugin'}</label></div>}
											{plugItem.isDistributed && plugItem.isAvailable && <div className='Plugins-card-item-column'>
												<Space size={15} isAutoResize={false}></Space>
												<label className='Plugins-card-item-title'>Assgin</label>
												<Space size={5} isAutoResize={false}></Space>
												<label className='Plugins-card-item-subtitle'>(selected restuarants only can access this plugin)</label>
												<Space size={15} isAutoResize={false}></Space>
												{rest.isLoading || isRestLoading ? (<div className='listview-wrapper' style={{height: 'auto'}}>
													<ListView dummy={2} adapter={function (__, ___): JSX.Element {
														return <div className='listview-item-adapter'>
															<label className='listview-item-adapter-index-load'>00</label>
															<Space size={5} isAutoResize={true}></Space>
															<div className='listview-item-adapter-card-load'>
																<label className='listview-item-adapter-title-load'>{'Tax Percentage : ${}%'}</label>
															</div>
														</div>;
													}} selItemIndex={-1} adapterHeight={60} />
												</div>) : (<div className='listview-wrapper' style={{height: 'auto'}}>
													<ListView data={rest.data} adapter={function (k: number, itemr: RestaurantModel | number): JSX.Element {
														const restItem = itemr as RestaurantModel;
														const findTri = plugItem.availableTo.find(v => v.restaurant !== undefined && v.restaurant._id === restItem._id);
														const mng = findTri ? findTri.triggerBy ? `Manager : ${findTri.triggerBy.firstName ?? ''} ${findTri.triggerBy.lastName ?? ''}` : '' : '';
														const availData = plugItem.availableTo.map(v => {
															if (v.restaurant !== undefined) {
																return v.restaurant._id;
															}

															return '';
														});
														return <div className='listview-item-adapter'>
															<label className='listview-item-adapter-index' style={{width: '2%'}}>{k + 1}</label>
															<Space size={5} isAutoResize={true}></Space>
															<div className='listview-item-adapter-card' style={{flexDirection: 'column'}}>
																<label className='listview-item-adapter-title' style={{fontSize: '11px'}}>{restItem.name}</label>
																<Space size={5} isAutoResize={true}></Space>
																<label className='listview-item-adapter-title' style={{fontSize: '9px'}}>{restItem.address}</label>
																<Space size={3} isAutoResize={true}></Space>
																<label className='listview-item-adapter-title' style={{fontSize: '9px'}}>{mng}</label>
															</div>
															<CheckBox title='' initVal={availData.includes(restItem._id)} ovrVal={availData.includes(restItem._id)} onClick={(c: boolean) => {
																void assginRest(plugItem._id, restItem._id);
															}}></CheckBox>
															<Space size={5} isAutoResize={true}></Space>
														</div>;
													}} adapterHeight={60} />
												</div>)}
											</div>}
										</div>),
									)}
								</div>
							)}
						</RestaurantContext.Consumer>
					</div>
				</div>))}</div>
		</div >
	);
};

export default Plugins;
