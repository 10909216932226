class DeliveriesSvg {
	svgRaw = `<svg width="26" height="24" viewBox="0 0 26 24" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M24.8467 15.785C25.1741 15.785 25.4313 16.0422 25.4313 16.3696V17.5389C25.4313 19.4798 23.8645 21.0466 21.9236 21.0466C21.9236 19.1174 20.3451 17.5389 18.4158 17.5389C16.4865 17.5389 14.908 19.1174 14.908 21.0466H12.5695C12.5695 19.1174 10.991 17.5389 9.06174 17.5389C7.13247 17.5389 5.55397 19.1174 5.55397 21.0466C3.61301 21.0466 2.0462 19.4798 2.0462 17.5389V15.2003C2.0462 14.5573 2.57237 14.0311 3.21546 14.0311H14.3234C15.937 14.0311 17.2465 12.7215 17.2465 11.1079V4.67704C17.2465 4.03395 17.7727 3.50778 18.4158 3.50778H19.398C20.2398 3.50778 21.0115 3.96379 21.4325 4.68873L22.1808 5.9983C22.286 6.18538 22.1457 6.43092 21.9236 6.43092C20.31 6.43092 19.0004 7.74049 19.0004 9.35406V12.8618C19.0004 14.4754 20.31 15.785 21.9236 15.785H24.8467Z" fill="#CF4D4D"/>
    <path d="M9.06172 23.3851C10.3532 23.3851 11.4002 22.3382 11.4002 21.0466C11.4002 19.7551 10.3532 18.7081 9.06172 18.7081C7.77019 18.7081 6.72321 19.7551 6.72321 21.0466C6.72321 22.3382 7.77019 23.3851 9.06172 23.3851Z" fill="#CF4D4D"/>
    <path d="M18.4158 23.3851C19.7073 23.3851 20.7543 22.3382 20.7543 21.0466C20.7543 19.7551 19.7073 18.7081 18.4158 18.7081C17.1242 18.7081 16.0773 19.7551 16.0773 21.0466C16.0773 22.3382 17.1242 23.3851 18.4158 23.3851Z" fill="#CF4D4D"/>
    <path d="M25.4313 12.3123V14.0311H21.9235C21.2804 14.0311 20.7543 13.5049 20.7543 12.8618V9.35404C20.7543 8.71095 21.2804 8.18478 21.9235 8.18478H23.4319L25.1273 11.1547C25.3261 11.5055 25.4313 11.903 25.4313 12.3123Z" fill="#CF4D4D"/>
    <path d="M15.0016 0H6.36076C4.26779 0 2.51391 1.49665 2.12805 3.48438H7.2377C7.68202 3.48438 8.0328 3.84685 8.0328 4.29117C8.0328 4.73549 7.68202 5.08626 7.2377 5.08626H2.0462V6.69984H5.08627C5.53059 6.69984 5.89306 7.06231 5.89306 7.50662C5.89306 7.95094 5.53059 8.30172 5.08627 8.30172H2.0462V9.91529H2.94653C3.39085 9.91529 3.75332 10.2778 3.75332 10.7221C3.75332 11.1664 3.39085 11.5172 2.94653 11.5172H2.0462V11.7861C2.0462 12.4292 2.57237 12.9554 3.21546 12.9554H13.9142C15.1068 12.9554 16.0773 11.9849 16.0773 10.7922V1.07572C16.0773 0.479395 15.5979 0 15.0016 0Z" fill="#CF4D4D"/>
    <path d="M2.12805 3.48492H1.95266H0.806787C0.362469 3.48492 0 3.84739 0 4.29171C0 4.73603 0.362469 5.08681 0.806787 5.08681H1.87081H2.0462V4.3151C2.0462 4.03447 2.08128 3.75385 2.12805 3.48492Z" fill="#CF4D4D"/>
    <path d="M1.87081 6.70038H0.806787C0.362469 6.70038 0 7.06285 0 7.50717C0 7.95148 0.362469 8.30226 0.806787 8.30226H1.87081H2.0462V6.70038H1.87081Z" fill="#CF4D4D"/>
    <path d="M1.87081 9.91586H0.806787C0.362469 9.91586 0 10.2783 0 10.7226C0 11.167 0.362469 11.5177 0.806787 11.5177H1.87081H2.0462V9.91586H1.87081Z" fill="#CF4D4D"/>
    </svg>`;

	replaceColor = '#CF4D4D';

	public normal() {
		return `data:image/svg+xml;utf8,${encodeURIComponent(this.svgRaw)}`;
	}

	public white() {
		return `data:image/svg+xml;utf8,${encodeURIComponent(this.svgRaw.replaceAll(this.replaceColor, '#FFF3F3'))}`;
	}
}

export default DeliveriesSvg;
