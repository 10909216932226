import React, {useRef, useImperativeHandle, forwardRef, useState} from 'react';
import './JsonDisplay.css';

type Props = {
	isLoading?: boolean;
	jsonData: unknown;
};

export type JsonDisplayHandle = {
	complete: () => boolean;
};

const JsonDisplay = forwardRef<JsonDisplayHandle, Props>((props, ref) => {
	const formattedData = JSON.stringify(props.jsonData, null, 2);

	useImperativeHandle(ref, () => ({
		complete() {
			// SetLoading(false);
			// return isLoading;
			return false;
		},
	}));

	return (
		<div className='JsonDisplay-details'>
			<pre style={{width: '100%', whiteSpace: 'pre-wrap', fontSize: '14px', padding: '10px'}}>{formattedData}</pre>
		</div >
	);
});

JsonDisplay.displayName = 'JsonDisplay';

export default JsonDisplay;
