import React, {useEffect, useRef, useState} from 'react';
import './DeliveryCharge.css';
import Space from '../../components/Space/Space';
import OutlineButton from '../../components/OutlineButton/OutlineButton';
import ListView from '../../components/ListView/ListView';
import {type DeliveryChargeModel} from '../../models/deliverycharge';
import TextField, {type TextFieldHandle} from '../../components/TextField/TextField';
import Button, {type ButtonHandle} from '../../components/Button/Button';
import TextFieldInputTypes from '../../utils/textfield/fieldtypes';
import SnackRibbon, {type SnackRibbonHandle} from '../../components/SnackRibbon/SnackRibbon';
import DeliveryChargeService from '../../services/deliverycharge';
import ConditionsService from '../../services/conditions';
import {type ConditionModel} from '../../models/condition';
import CheckBox, {type CheckBoxHandle} from '../../components/CheckBox/CheckBox';
import RadioButton from '../../components/RadioButton/RadioButton';

type Fields = {
	id: number;
	label: string;
};

type CustomStyleType = {
	'--justifyContent': string;
};

type ExtendedDivStyle = React.CSSProperties & CustomStyleType;

const DeliveryCharge = () => {
	const [isAddDelCharge, setAddDelCharge] = useState<boolean>(false);
	const [delCharData, setDelCharData] = useState<DeliveryChargeModel[]>([]);
	const [condData, setCondData] = useState<ConditionModel[]>([]);
	const [selItemIndex, setItemIndex] = useState<number>(-1);
	const [isDelCharLoading, setDelCharLoading] = useState<boolean>(true);
	const [isCondLoading, setCondLoading] = useState<boolean>(true);
	const [hasFreeship, setHasFreeship] = useState(false);
	const [selCondIndex, setSelCondIndex] = useState<number>(-1);
	const [fields, setFields] = useState<Fields[]>([]);

	const snackBarRibbon = useRef<SnackRibbonHandle>(null);
	const perKmCharge = useRef<TextFieldHandle>(null);
	const perKgCharge = useRef<TextFieldHandle>(null);
	const onSaveBtn = useRef<ButtonHandle>(null);
	const freeshipCheck = useRef<CheckBoxHandle>(null);
	const fieldsRef = Array.from({length: 10}, () => useRef<TextFieldHandle>(null));

	useEffect(() => {
		void fetchDelCharge();
		void fetchConditions();
	}, []);

	async function fetchDelCharge() {
		await new DeliveryChargeService().get().then(val => {
			if (val.hasError) {
				snackBarRibbon.current!.trigger(val.hasError, val.errorMsg!);
			} else {
				setDelCharData(val.res!.data!);
			}
		}).finally(() => {
			setDelCharLoading(false);
		});
	}

	async function fetchConditions() {
		await new ConditionsService().get().then(val => {
			if (val.hasError) {
				snackBarRibbon.current!.trigger(val.hasError, val.errorMsg!);
			} else {
				setCondData(val.res!.data!);
			}
		}).finally(() => {
			setCondLoading(false);
		});
	}

	const handleOnAddlick = () => {
		setAddDelCharge(true);
		setItemIndex(-1);
	};

	const onSave = async () => {
		const field1 = perKmCharge.current!.validate();
		const field2 = perKgCharge.current!.validate();
		if (!field1.isValid || !field2.isValid) {
			snackBarRibbon.current!.trigger(true, field1.isValid ? field2.msg : field1.msg);
			onSaveBtn.current!.complete();
		}

		const freeShipObjects: Record<string, unknown> = {};
		const objectValid = [];
		if (hasFreeship) {
			if (selCondIndex === -1) {
				// Select any condition
				snackBarRibbon.current!.trigger(true, 'Select any condition');
				onSaveBtn.current!.complete();
			}

			if (selCondIndex !== -1) {
				const schLen = Object.keys(condData[selCondIndex].schema);
				// Verify schema data
				for (let i = 0; i < schLen.length; i++) {
					const field = fieldsRef[i].current!.validate();
					if (!field.isValid) {
						snackBarRibbon.current!.trigger(true, field.msg);
						onSaveBtn.current!.complete();
					}

					if (field.isValid) {
						freeShipObjects[schLen[i]] = Number(fieldsRef[i].current!.getValue());
						objectValid.push(0);
					}
				}
			}
		}

		if (field1.isValid && field2.isValid && (!hasFreeship || (hasFreeship && (objectValid.length === Object.keys(condData[selCondIndex].schema).length)))) {
			setDelCharLoading(true);
			setCondLoading(true);
			await new DeliveryChargeService().create(Number(perKmCharge.current!.getValue()!), Number(perKgCharge.current!.getValue()!), hasFreeship ? {conditionId: condData[selCondIndex]._id, conditionValue: freeShipObjects} : {conditionId: undefined, conditionValue: undefined}).then(async val => {
				if (val.hasError) {
					snackBarRibbon.current!.trigger(val.hasError, val.errorMsg!);
				} else {
					await fetchDelCharge();
					await fetchConditions();

					snackBarRibbon.current!.trigger(false, val.res!.message);
				}

				onSaveBtn.current!.complete();
				perKmCharge.current!.clearInput();
				perKgCharge.current!.clearInput();
				for (let i = 0; i < objectValid.length; i++) {
					fieldsRef[i].current!.clearInput();
				}
			});
		}
	};

	const onPrepareFields = (j: number) => {
		const schemLen = Object.keys(condData[j].schema);

		const fie: Fields[] = [];
		for (let i = 0; i < schemLen.length; i++) {
			fie.push({id: i, label: schemLen[i]});
		}

		setFields(fie);
	};

	const customStyle: ExtendedDivStyle = {
		'--justifyContent': (selItemIndex === -1 && !isAddDelCharge) ? 'center' : 'start',
	};

	return (
		<div className='DeliveryCharge'>
			<div className='deliveryCharge-left-panel'>
				<div className='deliveryCharge-left-tools'>
					<label className='deliveryCharge-count-label'>{`Total : ${delCharData.length}`}</label>
					<OutlineButton onClick={handleOnAddlick} label={'Add Charge'}></OutlineButton>
				</div>
				{isDelCharLoading ? (<div className='deliveryCharge-left-list'>
					<ListView dummy={5} adapter={function (i, item): JSX.Element {
						return <div className='deliveryCharge-list-adapter'>
							<label className='deliveryCharge-list-adapter-index-load'>00</label>
							<Space size={5} isAutoResize={true}></Space>
							<div className='deliveryCharge-list-adapter-card'>
								<label className='deliveryCharge-list-adapter-perc-load'>{'Tax Percentage : ${}%'}</label>
								<Space size={10} isAutoResize={true}></Space>
								<label className='deliveryCharge-list-adapter-update-load'>{'Updated : ${}'}</label>
							</div>
						</div>;
					}} selItemIndex={-1} adapterHeight={60} />
				</div>) : (<div className='deliveryCharge-left-list'>
					<ListView data={delCharData} adapter={function (i, item): JSX.Element {
						const itemData = item as DeliveryChargeModel;
						return <div className='deliveryCharge-list-adapter'>
							<label className='deliveryCharge-list-adapter-index'>{i + 1}</label>
							<Space size={5} isAutoResize={true}></Space>
							<div className='deliveryCharge-list-adapter-card'>
								<label className='deliveryCharge-list-adapter-perc'>{`Per Km Charge : ${itemData.perKmCharge}`}</label>
								<Space size={5} isAutoResize={true}></Space>
								<label className='deliveryCharge-list-adapter-perc'>{`Per Kg Charge : ${itemData.perKgCharge}`}</label>
								<Space size={10} isAutoResize={true}></Space>
								{/* <label className='deliveryCharge-list-adapter-update'>{`Updated : ${itemData.updatedAt.substring(0, 10)}`}</label> */}
							</div>
						</div>;
					}} selItemIndex={selItemIndex} onSelectItem={i => {
						setAddDelCharge(false);
						setItemIndex(i);
					}} adapterHeight={60} />
				</div>)}
			</div>
			<div className='deliveryCharge-right-panel'>
				<SnackRibbon ref={snackBarRibbon}></SnackRibbon>
				<div className='deliveryCharge-right-content' style={customStyle}>
					{selItemIndex === -1 && !isAddDelCharge && <label className='deliveryCharge-right-empty-content'>No items selected</label>}
					{selItemIndex !== -1 && <div>
						<label className='deliveryCharge-detail-no'>{`No : ${selItemIndex + 1}`}</label>
						<Space size={5} isAutoResize={true}></Space>
						<label className='deliveryCharge-detail-perc'>{`Per Km Charge : ${delCharData[selItemIndex].perKmCharge}`}</label>
						<Space size={5} isAutoResize={true}></Space>
						<label className='deliveryCharge-detail-perc'>{`Per Kg Charge : ${delCharData[selItemIndex].perKgCharge}`}</label>
					</div>}
					{isAddDelCharge && <div>
						<Button ref={onSaveBtn} label={'Save'} onClick={onSave}></Button>
						<Space size={10} isAutoResize={false}></Space>
						<TextField ref={perKmCharge} isLoading={isCondLoading}
							label={'Per Km Charge'}
							inputType={TextFieldInputTypes.floatnumber} options={{maxLength: 5}}></TextField>
						<Space size={5} isAutoResize={false}></Space>
						<TextField ref={perKgCharge} isLoading={isCondLoading}
							label={'Per Kg Charge'}
							inputType={TextFieldInputTypes.floatnumber} options={{maxLength: 5}}></TextField>
						<Space size={15} isAutoResize={false}></Space>
						<label className='deliveryCharge-list-adapter-perc'>Freeship Condition (select only if want to enable freeship with condition for this delivery)</label>
						<Space size={10} isAutoResize={false}></Space>
						<CheckBox ref={freeshipCheck} title='Has Freeship?' onClick={c => {
							setHasFreeship(c);
						}}></CheckBox>
						{hasFreeship && selCondIndex !== -1 && <div>
							<Space size={20} isAutoResize={false}></Space>
							<label className='deliveryCharge-list-adapter-perc'>Fill the below paramaters for who can get the free shipping</label>
							<Space size={10} isAutoResize={false}></Space>
							<div className='deliveryCharge-cond-field-list'>
								{<div className='deliveryCharge-left-list'>
									<ListView data={fields} adapter={function (i, item): JSX.Element {
										const itemData = item as Fields;
										return <div className='deliveryCharge-list-adapter'>
											<label className='deliveryCharge-list-adapter-ti-label'>{fields[i].label}</label>
											<Space size={5} isAutoResize={true}></Space>
											<TextField ref={fieldsRef[i]} isLoading={isCondLoading}
												label={`Enter the ${fields[i].label}`}
												inputType={TextFieldInputTypes.floatnumber} options={{maxLength: 5}}></TextField>
										</div>;
									}} adapterHeight={55} />
								</div>}
							</div>
						</div>}
						{hasFreeship && <Space size={25} isAutoResize={false}></Space>}
						{hasFreeship && <div className='deliveryCharge-cond-list'>
							{isCondLoading ? (<div className='deliveryCharge-left-list'>
								<ListView dummy={5} adapter={function (i, item): JSX.Element {
									return <div className='deliveryCharge-list-adapter'>
										<label className='deliveryCharge-list-adapter-index-load'>00</label>
										<Space size={5} isAutoResize={true}></Space>
										<div className='deliveryCharge-list-adapter-card'>
											<label className='deliveryCharge-list-adapter-perc-load'>{'Tax Percentage : ${}%'}</label>
											<Space size={10} isAutoResize={true}></Space>
											<label className='deliveryCharge-list-adapter-update-load'>{'Updated : ${}'}</label>
										</div>
									</div>;
								}} selItemIndex={-1} adapterHeight={60} />
							</div>) : (<div className='deliveryCharge-left-list'>
								<ListView data={condData} adapter={function (i, item): JSX.Element {
									const itemData = item as ConditionModel;
									return <div className='deliveryCharge-list-adapter'>
										<label className='deliveryCharge-list-adapter-index'>{i + 1}</label>
										<Space size={5} isAutoResize={true}></Space>
										<div className='deliveryCharge-list-adapter-card'>
											<label className='deliveryCharge-list-adapter-perc'>{itemData.name}</label>
										</div>
										<RadioButton title='' index={i} selIndex={selCondIndex} onClick={i => {
											setSelCondIndex(i);
											onPrepareFields(i);
										}}></RadioButton>
										<Space size={5} isAutoResize={true}></Space>
									</div>;
								}} selItemIndex={selCondIndex} onSelectItem={i => {
									setSelCondIndex(i);
									onPrepareFields(i);
								}} adapterHeight={60} />
							</div>)}
						</div>}
						<Space size={20} isAutoResize={false}></Space>
					</div>}
				</div>
			</div>
		</div >
	);
};

export default DeliveryCharge;
