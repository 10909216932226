import React, {useEffect, useRef, useState} from 'react';
import './Contacts.css';
import Space from '../../components/Space/Space';
import OutlineButton from '../../components/OutlineButton/OutlineButton';
import ListView from '../../components/ListView/ListView';
import {type ContactsModel} from '../../models/contacts';
import TextField, {type TextFieldHandle} from '../../components/TextField/TextField';
import Button, {type ButtonHandle} from '../../components/Button/Button';
import TextFieldInputTypes from '../../utils/textfield/fieldtypes';
import SnackRibbon, {type SnackRibbonHandle} from '../../components/SnackRibbon/SnackRibbon';
import ContactsService from '../../services/contacts';
import CheckBox, {type CheckBoxHandle} from '../../components/CheckBox/CheckBox';
import OtpField, {type OtpFieldHandle} from '../../components/OtpField/OtpField';
import TextButton from '../../components/TextButton/TextButton';

type CustomStyleType = {
	'--justifyContent': string;
};

type ExtendedDivStyle = React.CSSProperties & CustomStyleType;

const Contacts = () => {
	const [isAddContacts, setAddContacts] = useState<boolean>(false);
	const [data, setData] = useState<ContactsModel[]>([]);
	const [selItemIndex, setItemIndex] = useState(-1);
	const [isListLoading, setListLoading] = useState(true);
	const [isLandline, setLandline] = useState(false);
	const [isOtpSent, setOtpSent] = useState(false);
	const [otpData, setOtpData] = useState('');
	const [phNum, setPhNum] = useState('');

	const snackBarRibbon = useRef<SnackRibbonHandle>(null);
	const phoneNumber = useRef<TextFieldHandle>(null);
	const landlineCheck = useRef<CheckBoxHandle>(null);
	const otpField = useRef<OtpFieldHandle>(null);
	const onBtnClick = useRef<ButtonHandle>(null);

	useEffect(() => {
		void fetchContacts();
	}, []);

	async function fetchContacts() {
		await new ContactsService().get().then(val => {
			if (val.hasError) {
				snackBarRibbon.current!.trigger(val.hasError, val.errorMsg!);
			}

			setData(val.res!.data!);
		}).finally(() => {
			setListLoading(false);
		});
	}

	const handleOnAddlick = () => {
		setAddContacts(true);
		setItemIndex(-1);
	};

	const sendVerifyOtp = async () => {
		if (!isOtpSent) {
			const field1 = phoneNumber.current!.validate();
			if (!field1.isValid) {
				snackBarRibbon.current!.trigger(true, field1.msg);
				onBtnClick.current!.complete();
			}

			if (field1.isValid) {
				setListLoading(true);
				await new ContactsService().sendotp(phoneNumber.current!.getValue()!, isLandline).then(async val => {
					if (val.hasError) {
						snackBarRibbon.current!.trigger(val.hasError, val.errorMsg!);
						setListLoading(false);
					}

					if (!val.hasError && !isLandline) {
						setOtpData(val.res!.data!.key);

						snackBarRibbon.current!.trigger(false, val.res!.message);

						if (!isLandline) {
							setPhNum(phoneNumber.current!.getValue()!);
						}

						setOtpSent(true);
					}

					if (isLandline) {
						await fetchContacts();
						snackBarRibbon.current!.trigger(false, val.res!.message);
						cleanFields();
					}

					onBtnClick.current!.complete();
				});
			}
		}

		if (isOtpSent) {
			const field2 = otpField.current!.validate();
			otpField.current!.loading();
			if (!field2.isValid) {
				snackBarRibbon.current!.trigger(true, field2.msg);
				otpField.current!.completed();
				onBtnClick.current!.complete();
			}

			if (field2.isValid) {
				setListLoading(true);
				await new ContactsService().verifyotp(phNum, otpData, Number(otpField.current!.getValue())).then(async val => {
					if (val.hasError) {
						snackBarRibbon.current!.trigger(val.hasError, val.errorMsg!);
						setListLoading(false);
					} else {
						await fetchContacts();

						snackBarRibbon.current!.trigger(false, val.res!.message);

						cleanFields();
					}

					onBtnClick.current!.complete();
				});
			}
		}
	};

	const sendVerifyOtpBy = async (pn: string, key: string) => {
		const field2 = otpField.current!.validate();
		otpField.current!.loading();
		if (!field2.isValid) {
			snackBarRibbon.current!.trigger(true, field2.msg);
			otpField.current!.completed();
			onBtnClick.current!.complete();
		}

		if (field2.isValid) {
			setListLoading(true);
			await new ContactsService().verifyotp(pn, key, Number(otpField.current!.getValue())).then(async val => {
				if (val.hasError) {
					snackBarRibbon.current!.trigger(val.hasError, val.errorMsg!);
					setListLoading(false);
					otpField.current!.completed();
					onBtnClick.current!.complete();
				} else {
					await fetchContacts();

					snackBarRibbon.current!.trigger(false, val.res!.message);

					cleanFields();
				}

				onBtnClick.current!.complete();
				setListLoading(false);
			});
		}
	};

	const resendOtp = async (pn?: string, key?: string) => {
		setListLoading(true);
		await new ContactsService().reSendOtp(pn ?? phNum, key ?? otpData).then(async val => {
			if (val.hasError) {
				snackBarRibbon.current!.trigger(val.hasError, val.errorMsg!);
			} else {
				setOtpData(val.res!.data!.key);

				snackBarRibbon.current!.trigger(false, val.res!.message);
			}

			onBtnClick.current!.complete();
			setListLoading(false);
			otpField.current!.completed();
			onBtnClick.current!.complete();
		});
	};

	const cleanFields = () => {
		phoneNumber.current?.clearInput();
		otpField.current?.clearInput();
		setOtpSent(false);
		setOtpData('');
		setLandline(false);
		setPhNum('');
	};

	const customStyle: ExtendedDivStyle = {
		'--justifyContent': (selItemIndex === -1 && !isAddContacts) ? 'center' : 'start',
	};

	return (
		<div className='Contacts'>
			<div className='contacts-left-panel'>
				<div className='contacts-left-tools'>
					<label className='contacts-count-label'>{`Total : ${data.length}`}</label>
					<OutlineButton onClick={handleOnAddlick} label={'Add Contact'}></OutlineButton>
				</div>
				{isListLoading ? (<div className='contacts-left-list'>
					<ListView dummy={5} adapter={function (i, item): JSX.Element {
						return <div className='contacts-list-adapter'>
							<label className='contacts-list-adapter-index-load'>00</label>
							<Space size={5} isAutoResize={true}></Space>
							<div className='contacts-list-adapter-card'>
								<label className='contacts-list-adapter-perc-load'>{'Contacts Percentage : ${}%'}</label>
								<Space size={10} isAutoResize={true}></Space>
								<label className='contacts-list-adapter-update-load'>{'Updated : ${}'}</label>
							</div>
						</div>;
					}} selItemIndex={-1} adapterHeight={60} />
				</div>) : (<div className='contacts-left-list'>
					<ListView data={data} adapter={function (i, item): JSX.Element {
						const itemData = item as ContactsModel;
						return <div className='contacts-list-adapter'>
							<label className='contacts-list-adapter-index'>{i + 1}</label>
							<Space size={5} isAutoResize={true}></Space>
							<div className='contacts-list-adapter-card'>
								<label className='contacts-list-adapter-perc'>{itemData.isLandline ? itemData.contactNumber : itemData.mobileNumber}</label>
								<Space size={10} isAutoResize={true}></Space>
								<label className='contacts-list-adapter-update'>{`Verified : ${itemData.isLandline ? itemData.isVerified : itemData.isMobileVerified}`}</label>
							</div>
						</div>;
					}} selItemIndex={selItemIndex} onSelectItem={i => {
						setAddContacts(false);
						setItemIndex(i);
					}} adapterHeight={60} />
				</div>)}
			</div>
			<div className='contacts-right-panel'>
				<SnackRibbon ref={snackBarRibbon}></SnackRibbon>
				<div className='contacts-right-content' style={customStyle}>
					{selItemIndex === -1 && !isAddContacts && <label className='contacts-right-empty-content'>No items selected</label>}
					{selItemIndex !== -1 && <div>
						<label className='contacts-detail-no'>{`No : ${selItemIndex + 1}`}</label>
						<Space size={5} isAutoResize={true}></Space>
						<label className='contacts-detail-perc'>{data[selItemIndex].isLandline ? `Landline Number : ${data[selItemIndex].contactNumber}` : `Mobile Number : ${data[selItemIndex].mobileNumber}`}</label>
						<Space size={10} isAutoResize={true}></Space>
						{!data[selItemIndex].isMobileVerified && !data[selItemIndex].isLandline && <div className='contacts-row'>
							<OtpField ref={otpField} label={''}></OtpField>
							<Space size={10} isAutoResize={false}></Space>
							<TextButton label='resend otp' onClick={() => {
								if (data[selItemIndex].key) {
									void resendOtp(`${data[selItemIndex].mobileNumber}`, `${data[selItemIndex].key}`);
								}
							}}></TextButton>
							<Space size={15} isAutoResize={false}></Space>
							<Button ref={onBtnClick} label={'Verify Otp'} onClick={() => {
								if (data[selItemIndex].key) {
									void sendVerifyOtpBy(`${data[selItemIndex].mobileNumber}`, `${data[selItemIndex].key}`);
								}
							}}></Button>
						</div>}
						<Space size={10} isAutoResize={true}></Space>
						<label className='contacts-detail-update'>{`Verified : ${data[selItemIndex].isLandline ? data[selItemIndex].isVerified : data[selItemIndex].isMobileVerified}`}</label>
					</div>}
					{isAddContacts && (isOtpSent ? <div>
						<OtpField ref={otpField} label={''}></OtpField>
						<Space size={10} isAutoResize={false}></Space>
						<TextButton label='resend otp' onClick={resendOtp}></TextButton>
						<Space size={15} isAutoResize={false}></Space>
						<Button ref={onBtnClick} label={'Verify Otp'} onClick={sendVerifyOtp}></Button>
					</div> : <div>
						<TextField ref={phoneNumber} isLoading={isListLoading}
							label={'Enter the contact number'}
							inputType={TextFieldInputTypes.mobile}></TextField>
						<label className='contacts-detail-no'>Enter with country code and symbol +</label>
						<Space size={10} isAutoResize={false}></Space>
						<CheckBox ref={landlineCheck} title='Is Landline?' onClick={c => {
							setLandline(c);
						}}></CheckBox>
						<Space size={15} isAutoResize={false}></Space>
						<Button ref={onBtnClick} label={isLandline ? 'Save' : 'Send Otp'} onClick={sendVerifyOtp}></Button>
					</div>)}
				</div>
			</div>
		</div >
	);
};

export default Contacts;
