import React, {useEffect, useRef, useState} from 'react';
import './Tax.css';
import Space from '../../components/Space/Space';
import OutlineButton from '../../components/OutlineButton/OutlineButton';
import ListView from '../../components/ListView/ListView';
import {type TaxModel} from '../../models/tax';
import TextField, {type TextFieldHandle} from '../../components/TextField/TextField';
import Button, {type ButtonHandle} from '../../components/Button/Button';
import TextFieldInputTypes from '../../utils/textfield/fieldtypes';
import SnackRibbon, {type SnackRibbonHandle} from '../../components/SnackRibbon/SnackRibbon';
import TaxService from '../../services/tax';

type CustomStyleType = {
	'--justifyContent': string;
};

type ExtendedDivStyle = React.CSSProperties & CustomStyleType;

const Tax = () => {
	const [isAddTax, setAddTax] = useState<boolean>(false);
	const [data, setData] = useState<TaxModel[]>([]);
	const [selItemIndex, setItemIndex] = useState<number>(-1);
	const [isListLoading, setListLoading] = useState<boolean>(true);

	const snackBarRibbon = useRef<SnackRibbonHandle>(null);
	const taxPercentage = useRef<TextFieldHandle>(null);
	const onSaveBtn = useRef<ButtonHandle>(null);

	useEffect(() => {
		void fetchTax();
	}, []);

	async function fetchTax() {
		await new TaxService().get().then(val => {
			if (val.hasError) {
				snackBarRibbon.current!.trigger(val.hasError, val.errorMsg!);
			} else {
				setData(val.res!.data!);
			}
		}).finally(() => {
			setListLoading(false);
		});
	}

	const handleOnAddlick = () => {
		setAddTax(true);
		setItemIndex(-1);
	};

	const onSave = async () => {
		const field1 = taxPercentage.current!.validate();
		if (!field1.isValid) {
			snackBarRibbon.current!.trigger(true, field1.msg);
			onSaveBtn.current!.complete();
		}

		if (field1.isValid) {
			setListLoading(true);
			await new TaxService().create(Number(taxPercentage.current!.getValue()!)).then(async val => {
				if (val.hasError) {
					snackBarRibbon.current!.trigger(val.hasError, val.errorMsg!);
				} else {
					await fetchTax();

					snackBarRibbon.current!.trigger(false, val.res!.message);
				}

				onSaveBtn.current!.complete();
				taxPercentage.current!.clearInput();
			});
		}
	};

	const customStyle: ExtendedDivStyle = {
		'--justifyContent': (selItemIndex === -1 && !isAddTax) ? 'center' : 'start',
	};

	return (
		<div className='Tax'>
			<div className='tax-left-panel'>
				<div className='tax-left-tools'>
					<label className='tax-count-label'>{`Total : ${data.length}`}</label>
					<OutlineButton onClick={handleOnAddlick} label={'Add Tax'}></OutlineButton>
				</div>
				{isListLoading ? (<div className='tax-left-list'>
					<ListView dummy={5} adapter={function (i, item): JSX.Element {
						return <div className='tax-list-adapter'>
							<label className='tax-list-adapter-index-load'>00</label>
							<Space size={5} isAutoResize={true}></Space>
							<div className='tax-list-adapter-card'>
								<label className='tax-list-adapter-perc-load'>{'Tax Percentage : ${}%'}</label>
								<Space size={10} isAutoResize={true}></Space>
								<label className='tax-list-adapter-update-load'>{'Updated : ${}'}</label>
							</div>
						</div>;
					}} selItemIndex={-1} adapterHeight={50} />
				</div>) : (<div className='tax-left-list'>
					<ListView data={data} adapter={function (i, item): JSX.Element {
						const itemData = item as TaxModel;
						return <div className='tax-list-adapter'>
							<label className='tax-list-adapter-index'>{i + 1}</label>
							<Space size={5} isAutoResize={true}></Space>
							<div className='tax-list-adapter-card'>
								<label className='tax-list-adapter-perc'>{`Tax Percentage : ${itemData.percentage}%`}</label>
								<Space size={10} isAutoResize={true}></Space>
								<label className='tax-list-adapter-update'>{`Updated : ${itemData.updatedAt.substring(0, 10)}`}</label>
							</div>
						</div>;
					}} selItemIndex={selItemIndex} onSelectItem={i => {
						setAddTax(false);
						setItemIndex(i);
					}} adapterHeight={50} />
				</div>)}
			</div>
			<div className='tax-right-panel'>
				<SnackRibbon ref={snackBarRibbon}></SnackRibbon>
				<div className='tax-right-content' style={customStyle}>
					{selItemIndex === -1 && !isAddTax && <label className='tax-right-empty-content'>No items selected</label>}
					{selItemIndex !== -1 && <div>
						<label className='tax-detail-no'>{`No : ${selItemIndex + 1}`}</label>
						<Space size={5} isAutoResize={true}></Space>
						<label className='tax-detail-perc'>{`Tax Percentage : ${data[selItemIndex].percentage}%`}</label>
						<Space size={10} isAutoResize={true}></Space>
						<label className='tax-detail-update'>{`Updated : ${data[selItemIndex].updatedAt}`}</label>
					</div>}
					{isAddTax && <div>
						<TextField ref={taxPercentage} isLoading={isListLoading}
							label={'Enter the new percentage'}
							inputType={TextFieldInputTypes.number} options={{maxLength: 2}}></TextField>
						<Space size={5} isAutoResize={false}></Space>
						<Button ref={onSaveBtn} label={'Save'} onClick={onSave}></Button>
					</div>}
				</div>
			</div>
		</div >
	);
};

export default Tax;
