import React, {useEffect, useRef, useState} from 'react';
import './Cities.css';
import Space from '../../components/Space/Space';
import OutlineButton from '../../components/OutlineButton/OutlineButton';
import ListView from '../../components/ListView/ListView';
import {type CitiesModel} from '../../models/cities';
import TextField, {type TextFieldHandle} from '../../components/TextField/TextField';
import Button, {type ButtonHandle} from '../../components/Button/Button';
import TextFieldInputTypes from '../../utils/textfield/fieldtypes';
import SnackRibbon, {type SnackRibbonHandle} from '../../components/SnackRibbon/SnackRibbon';
import CitiesService from '../../services/cities';

type CustomStyleType = {
	'--justifyContent': string;
};

type ExtendedDivStyle = React.CSSProperties & CustomStyleType;

const Cities = () => {
	const [isAddCity, setAddCity] = useState<boolean>(false);
	const [data, setData] = useState<CitiesModel[]>([]);
	const [selItemIndex, setItemIndex] = useState<number>(-1);
	const [isListLoading, setListLoading] = useState<boolean>(true);

	const snackBarRibbon = useRef<SnackRibbonHandle>(null);
	const cityName = useRef<TextFieldHandle>(null);
	const onSaveBtn = useRef<ButtonHandle>(null);
	const onDisableEnableBtn = useRef<ButtonHandle>(null);

	useEffect(() => {
		void fetchCities();
	}, []);

	async function fetchCities() {
		await new CitiesService().get().then(val => {
			if (val.hasError) {
				snackBarRibbon.current!.trigger(val.hasError, val.errorMsg!);
			} else {
				setData(val.res!.data!);
			}
		}).finally(() => {
			setListLoading(false);
		});
	}

	const handleOnAddlick = () => {
		setAddCity(true);
		setItemIndex(-1);
	};

	const onSave = async () => {
		const field1 = cityName.current!.validate();
		if (!field1.isValid) {
			snackBarRibbon.current!.trigger(true, field1.msg);
			onSaveBtn.current!.complete();
		}

		if (field1.isValid) {
			setListLoading(true);
			await new CitiesService().create(cityName.current!.getValue()!).then(async val => {
				if (val.hasError) {
					snackBarRibbon.current!.trigger(val.hasError, val.errorMsg!);
				} else {
					await fetchCities();

					snackBarRibbon.current!.trigger(false, val.res!.message);
				}

				onSaveBtn.current!.complete();
				cityName.current!.clearInput();
			});
		}
	};

	const onUpdate = async () => {
		setListLoading(true);
		await new CitiesService().update(data[selItemIndex]._id, !data[selItemIndex].isAvailable).then(async val => {
			if (val.hasError) {
				snackBarRibbon.current!.trigger(val.hasError, val.errorMsg!);
			} else {
				await fetchCities();

				snackBarRibbon.current!.trigger(false, val.res!.message);
			}

			onDisableEnableBtn.current!.complete();
		});
	};

	const customStyle: ExtendedDivStyle = {
		'--justifyContent': (selItemIndex === -1 && !isAddCity) ? 'center' : 'start',
	};

	return (
		<div className='Cities'>
			<div className='cities-left-panel'>
				<div className='cities-left-tools'>
					<label className='cities-count-label'>{`Total : ${data.length}`}</label>
					<OutlineButton onClick={handleOnAddlick} label={'Add City'}></OutlineButton>
				</div>
				{isListLoading ? (<div className='cities-left-list'>
					<ListView dummy={5} adapter={function (i, item): JSX.Element {
						return <div className='cities-list-adapter'>
							<label className='cities-list-adapter-index-load'>00</label>
							<Space size={5} isAutoResize={true}></Space>
							<div className='cities-list-adapter-card'>
								<label className='cities-list-adapter-perc-load'>{'Tax Percentage : ${}%'}</label>
								<Space size={10} isAutoResize={true}></Space>
								<label className='cities-list-adapter-update-load'>{'Updated : ${}'}</label>
							</div>
						</div>;
					}} selItemIndex={-1} adapterHeight={50} />
				</div>) : (<div className='cities-left-list'>
					<ListView data={data} adapter={function (i, item): JSX.Element {
						const itemData = item as CitiesModel;
						return <div className='cities-list-adapter'>
							<label className='cities-list-adapter-index'>{i + 1}</label>
							<Space size={5} isAutoResize={true}></Space>
							<div className='cities-list-adapter-card'>
								<label className='cities-list-adapter-perc'>{`City Name : ${itemData.name}`}</label>
								<Space size={10} isAutoResize={true}></Space>
								<label className='cities-list-adapter-update'>{`Is Available : ${itemData.isAvailable}`}</label>
							</div>
						</div>;
					}} selItemIndex={selItemIndex} onSelectItem={i => {
						setAddCity(false);
						setItemIndex(i);
					}} adapterHeight={50} />
				</div>)}
			</div>
			<div className='cities-right-panel'>
				<SnackRibbon ref={snackBarRibbon}></SnackRibbon>
				<div className='cities-right-content' style={customStyle}>
					{selItemIndex === -1 && !isAddCity && <label className='cities-right-empty-content'>No items selected</label>}
					{selItemIndex !== -1 && <div>
						<label className='cities-detail-no'>{`No : ${selItemIndex + 1}`}</label>
						<Space size={5} isAutoResize={true}></Space>
						<label className='cities-detail-perc'>{`City Name : ${data[selItemIndex].name}`}</label>
						<Space size={10} isAutoResize={true}></Space>
						<label className='cities-detail-update'>{`Is Available : ${data[selItemIndex].isAvailable}`}</label>
						<Space size={15} isAutoResize={true}></Space>
						<Button ref={onDisableEnableBtn} color={data[selItemIndex].isAvailable ? 'rgb(196, 0, 42)' : 'rgb(0, 138, 7)'} label={data[selItemIndex].isAvailable ? 'Disable' : 'Enable'} onClick={onUpdate}></Button>
					</div>}
					{isAddCity && <div>
						<TextField ref={cityName} isLoading={isListLoading}
							label={'Enter city name'}
							inputType={TextFieldInputTypes.purename} options={{maxLength: 2}}></TextField>
						<Space size={5} isAutoResize={false}></Space>
						<Button ref={onSaveBtn} label={'Save'} onClick={onSave}></Button>
					</div>}
				</div>
			</div>
		</div >
	);
};

export default Cities;
