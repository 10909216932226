import React, {useEffect, useRef, useState} from 'react';
import './AddRestaurant.css';
import GoogleMapMain, {type MapHandle} from '../../components/GoogleMap/GoogleMapMain';
import TextField, {type TextFieldHandle} from '../../components/TextField/TextField';
import TextFieldInputTypes from '../../utils/textfield/fieldtypes';
import TextFieldStyles from '../../utils/textfield/fieldstyles';
import Space from '../../components/Space/Space';
import DayTimePicker, {type DayTimePickerHandle} from '../../components/DayTimePicker/DayTimePicker';
import CheckBox, {type CheckBoxHandle} from '../../components/CheckBox/CheckBox';
import TaxService from '../../services/tax';
import {type TaxModel} from '../../models/tax';
import ListView, {type ListViewHandle} from '../../components/ListView/ListView';
import RadioButton from '../../components/RadioButton/RadioButton';
import DeliveryChargeService from '../../services/deliverycharge';
import {type DeliveryChargeModel} from '../../models/deliverycharge';
import TimeSlots, {type TimeSlotsHandle} from '../../components/TimeSlots/TimeSlots';
import ListSlots, {type ListSlotsHandle} from '../../components/ListSlots/ListSlots';
import {type SitingsModel} from '../../models/sitings';
import SitingsService from '../../services/sitings';
import {type CitiesModel} from '../../models/cities';
import CitiesService from '../../services/cities';
import {type ContactsModel} from '../../models/contacts';
import ContactsService from '../../services/contacts';
import RestTypesService from '../../services/resttypes';
import {type RestaurantTypesModel} from '../../models/resttypes';
import ImageImport, {type ImageImportHandle} from '../../components/ImageImport/ImageImport';
import RelToast, {type RelToastHandle} from '../../components/RelToast/RelToast';
import OutlineButton from '../../components/OutlineButton/OutlineButton';
import HorButtons, {type HorButtonsHandle} from '../../components/HorButtons/HorButtons';
import {usePluginContext} from '../../context/pluginContext';
import RestaurantsService from '../../services/restaurants';
import {RestaurantContext, type RestaurantContextResult} from '../../context/restContext';

type SelectedTypes = {
	index: number;
	name: string;
};

const AddRestaurant = () => {
	const labelBetWeenContainerSize = 15;
	const betWeenContainerSize = 25;

	const prevScrollY = useRef(0);
	const [isToolHide, setToolHide] = useState<boolean>(false);
	const relToast = useRef<RelToastHandle>(null);
	const plugins = usePluginContext();
	const [isLive, setLive] = useState<boolean>(false);

	// Fetching data
	const [cityData, setCityData] = useState<CitiesModel[]>([]);
	const [contactData, setContactData] = useState<ContactsModel[]>([]);
	const [restTypesData, setRestTypesData] = useState<RestaurantTypesModel[]>([]);
	const [taxData, setTaxData] = useState<TaxModel[]>([]);
	const [delChrData, setDelChrData] = useState<DeliveryChargeModel[]>([]);
	const [sitiData, setSitiData] = useState<SitingsModel[]>([]);

	const delTaxCheck = useRef<CheckBoxHandle>(null);
	const taxTaxCheck = useRef<CheckBoxHandle>(null);
	const dinTaxCheck = useRef<CheckBoxHandle>(null);
	const dirTaxCheck = useRef<CheckBoxHandle>(null);

	// Loading
	const [isLoading, setLoading] = useState<boolean>(false);
	const [cityLoading, setCityLoad] = useState<boolean>(true);
	const [contLoading, setContLoad] = useState<boolean>(true);
	const [rsTypeLoading, setRsTypeLoad] = useState<boolean>(true);
	const [taxListLoading, setTaxListLoad] = useState<boolean>(false);
	const [delChListLoading, setdelChListLoad] = useState<boolean>(false);
	const [sitiListLoading, setSitiListLoad] = useState<boolean>(false);

	// Refs
	const cityRef = useRef<ListViewHandle>(null);
	const contactRef = useRef<ListViewHandle>(null);
	const restTypesRef = useRef<HorButtonsHandle>(null);
	const delTaxRef = useRef<ListViewHandle>(null);
	const delCharRef = useRef<ListViewHandle>(null);
	const takTaxRef = useRef<ListViewHandle>(null);
	const dinTaxRef = useRef<ListViewHandle>(null);
	const sitiRef = useRef<ListViewHandle>(null);
	const dirTaxRef = useRef<ListViewHandle>(null);

	// Values
	const nameField = useRef<TextFieldHandle>(null);
	const [selCity, setSelCity] = useState<number[]>([]);
	const [selContact, setSelContact] = useState<number[]>([]);
	const mapLocation = useRef<MapHandle>(null);
	const addressField = useRef<TextFieldHandle>(null);
	const storeImg = useRef<ImageImportHandle>(null);
	const [selRsType, setSelRsType] = useState<SelectedTypes[]>([]);
	const maxDelRadiusField = useRef<TextFieldHandle>(null);
	const [hasDelTax, setHasDelTax] = useState<boolean>(false);
	const [selDelTax, setSelDelTax] = useState<number>(-1);
	const delAvailability = useRef<DayTimePickerHandle>(null);
	const [selDelChar, setSelDelChar] = useState<number>(-1);
	const takAvailability = useRef<DayTimePickerHandle>(null);
	const [hasTakTax, setHasTakTax] = useState<boolean>(false);
	const [selTakTax, setSelTakTax] = useState<number>(-1);
	const dinAvailability = useRef<DayTimePickerHandle>(null);
	const [hasDinTax, setHasDinTax] = useState<boolean>(false);
	const [selDinTax, setSelDinTax] = useState<number>(-1);
	const timeSlots = useRef<TimeSlotsHandle>(null);
	const facilities = useRef<ListSlotsHandle>(null);
	const [selSitings, setSelSiting] = useState<number[]>([]);
	const photoImg = useRef<ImageImportHandle>(null);
	const vibesImg = useRef<ImageImportHandle>(null);
	const [hasDirTax, setHasDirTax] = useState<boolean>(false);
	const [selDirTax, setSelDirTax] = useState<number>(-1);

	useEffect(() => {
		void getCities();
		void getContacts();
		void getRestTypes();
		void getDeliveryCharge();
		if (plugins.whiteList.isSitAllowed) {
			void getSitings();
		}
	}, []);

	const getCities = async () => {
		setCityLoad(true);
		await new CitiesService().get().then(val => {
			if (val.hasError && relToast.current) {
				relToast.current.trigger(val.hasError, val.errorMsg!);
			} else {
				setCityData(val.res!.data!);
			}
		}).finally(() => {
			setCityLoad(false);
		});
	};

	const getContacts = async () => {
		setContLoad(true);
		await new ContactsService().get().then(val => {
			if (val.hasError && relToast.current) {
				relToast.current.trigger(val.hasError, val.errorMsg!);
			} else {
				setContactData(val.res!.data!);
			}
		}).finally(() => {
			setContLoad(false);
		});
	};

	const getRestTypes = async () => {
		setRsTypeLoad(true);
		await new RestTypesService().get().then(val => {
			if (val.hasError && relToast.current) {
				relToast.current.trigger(val.hasError, val.errorMsg!);
			} else {
				setRestTypesData(val.res!.data!);
			}
		}).finally(() => {
			setRsTypeLoad(false);
		});
	};

	const getTax = async () => {
		setTaxListLoad(true);
		await new TaxService().get().then(val => {
			if (val.hasError && relToast.current) {
				relToast.current.trigger(val.hasError, val.errorMsg!);
			} else {
				setTaxData(val.res!.data!);
			}
		}).finally(() => {
			setTaxListLoad(false);
		});
	};

	const getDeliveryCharge = async () => {
		setdelChListLoad(true);
		await new DeliveryChargeService().get().then(val => {
			if (val.hasError && relToast.current) {
				relToast.current.trigger(val.hasError, val.errorMsg!);
			} else {
				setDelChrData(val.res!.data!);
			}
		}).finally(() => {
			setdelChListLoad(false);
		});
	};

	const getSitings = async () => {
		setSitiListLoad(true);
		await new SitingsService().get().then(val => {
			if (val.hasError && relToast.current) {
				relToast.current.trigger(val.hasError, val.errorMsg!);
			} else {
				setSitiData(val.res!.data!);
			}
		}).finally(() => {
			setSitiListLoad(false);
		});
	};

	const onScroll = (e: React.UIEvent<HTMLDivElement>) => {
		if (e.currentTarget) {
			const {scrollTop, scrollHeight, clientHeight} = e.currentTarget;
			const isNearBottom = Math.abs(scrollHeight - clientHeight - scrollTop) < 1;

			if ((scrollTop > prevScrollY.current) && !isToolHide && !isNearBottom) {
				setToolHide(true);
			} else if ((scrollTop < prevScrollY.current) && isToolHide && !isNearBottom) {
				setToolHide(false);
			}

			prevScrollY.current = scrollTop;
		}
	};

	const onAdd = async (rest: RestaurantContextResult) => {
		const field1 = nameField.current!.validate();
		const field2 = addressField.current!.validate();

		if (!field1.isValid) {
			relToast.current!.trigger(true, field1.msg);
		} else if (selCity.length === 0) {
			relToast.current!.trigger(true, 'Select the city');
			cityRef.current!.focus();
		} else if (selContact.length === 0) {
			relToast.current!.trigger(true, 'Select the store contact number');
			contactRef.current!.focus();
		} else if (!field2.isValid) {
			relToast.current!.trigger(true, field2.msg);
		} else if (storeImg.current!.selFiles()[0].file === undefined) {
			relToast.current!.trigger(true, 'Select the store image');
			storeImg.current!.focus();
		} else if (selRsType.length === 0) {
			relToast.current!.trigger(true, 'Select any service type');
			restTypesRef.current!.focus();
		} else {
			await validatePartA(rest);
		}
	};

	const validatePartA = async (rest: RestaurantContextResult) => {
		const hasDelivery = selRsType.find(v => v.name === 'delivery');

		if (hasDelivery && !maxDelRadiusField.current!.validate().isValid) {
			relToast.current!.trigger(true, maxDelRadiusField.current!.validate().msg);
		} else if (hasDelivery && hasDelTax && selDelTax === -1) {
			relToast.current!.trigger(true, 'Select the tax for delivery');
			delTaxRef.current!.focus();
		} else if (hasDelivery && delAvailability.current!.getValue().length === 0) {
			relToast.current!.trigger(true, 'Select delivery availability');
			delAvailability.current!.focus();
		} else if (hasDelivery && selDelChar === -1) {
			relToast.current!.trigger(true, 'Select the delivery charge');
			delCharRef.current!.focus();
		} else {
			await validatePartB(rest);
		}
	};

	const validatePartB = async (rest: RestaurantContextResult) => {
		const hasTakeaway = selRsType.find(v => v.name === 'takeaway');

		if (hasTakeaway && hasTakTax && selTakTax === -1) {
			relToast.current!.trigger(true, 'Select the tax for takeaway');
			takTaxRef.current!.focus();
		} else if (hasTakeaway && takAvailability.current!.getValue().length === 0) {
			relToast.current!.trigger(true, 'Select takeaway availability');
			takAvailability.current!.focus();
		} else {
			await validatePartC(rest);
		}
	};

	const validatePartC = async (rest: RestaurantContextResult) => {
		const hasDining = selRsType.find(v => v.name === 'dining');

		if (hasDining && hasDinTax && selDinTax === -1) {
			relToast.current!.trigger(true, 'Select the tax for dining');
			dinTaxRef.current!.focus();
		} else if (hasDining && timeSlots.current!.times().length === 0) {
			relToast.current!.trigger(true, 'Add the timeslot');
			timeSlots.current!.focus();
		} else if (hasDining && facilities.current!.data().length === 0) {
			relToast.current!.trigger(true, 'Add the facilities');
			facilities.current!.focus();
		} else if (hasDining && selSitings.length === 0) {
			relToast.current!.trigger(true, 'Add the table');
			sitiRef.current!.focus();
		} else if (hasDining && photoImg.current!.selFiles().some(v => v.file === undefined)) {
			relToast.current!.trigger(true, 'Add atleast an photo of dining');
			photoImg.current!.focus();
		} else if (hasDining && vibesImg.current!.selFiles().some(v => v.file === undefined)) {
			relToast.current!.trigger(true, 'Add atleast an vibe of dining');
			vibesImg.current!.focus();
		} else {
			await validatePartD(rest);
		}
	};

	const validatePartD = async (rest: RestaurantContextResult) => {
		const hasDirect = selRsType.find(v => v.name === 'direct');

		if (hasDirect && hasDirTax && selDirTax === -1) {
			relToast.current!.trigger(true, 'Select the tax for direct');
			dirTaxRef.current!.focus();
		} else if (hasDirect && selSitings.length === 0) {
			relToast.current!.trigger(true, 'Add the table');
			sitiRef.current!.focus();
		} else {
			await finalUpload(rest);
		}
	};

	const finalUpload = async (rest: RestaurantContextResult) => {
		const formData = new FormData();
		const hasDelivery = selRsType.find(v => v.name === 'delivery');
		const hasTakeaway = selRsType.find(v => v.name === 'takeaway');
		const hasDining = selRsType.find(v => v.name === 'dining');
		const hasDirect = selRsType.find(v => v.name === 'direct');

		formData.append('data', JSON.stringify({
			name: nameField.current!.getValue(),
			gpsCoordinates: mapLocation.current!.position(),
			address: addressField.current!.getValue(),
			delivery: hasDelivery ? {
				availability: delAvailability.current!.getValue(),
				hasTax: hasDelTax,
				taxId: hasDelTax ? taxData[selDelTax]._id : null,
				deliveryCharId: delChrData[selDelChar]._id,
				maxDelRadius: Number(maxDelRadiusField.current!.getValue()),
			} : null,
			takeaway: hasTakeaway ? {
				availability: takAvailability.current!.getValue(),
				hasTax: hasTakTax,
				taxId: hasDelTax ? taxData[selTakTax]._id : null,
			} : null,
			dining: hasDining ? {
				availability: dinAvailability.current!.getValue(),
				timeSlots: timeSlots.current!.times(),
				facilities: facilities.current!.data(),
				hasTax: hasDinTax,
				taxId: hasDinTax ? taxData[selDinTax]._id : null,
			} : null,
			direct: hasDirect ? {
				hasTax: hasDirTax,
				taxId: hasDirTax ? taxData[selDirTax]._id : null,
			} : null,
			isOpen: isLive,
			cityIds: selCity.map(v => cityData[v]._id),
			sitingIds: selSitings.length === 0 ? null : selSitings.map(v => sitiData[v]._id),
			contactIds: selContact.map(v => contactData[v]._id),
			restTypeIds: selRsType.map(v => restTypesData[v.index]._id),
		}));

		formData.append('image', storeImg.current!.selFiles()[0].file!);

		if (photoImg.current) {
			const photos = [];
			for (const v of photoImg.current.selFiles()) {
				photos.push(v.file!);
			}

			formData.append('photos', new Blob(photos));
		}

		if (vibesImg.current) {
			const vibes = [];
			for (const v of vibesImg.current.selFiles()) {
				vibes.push(v.file!);
			}

			formData.append('vibes', new Blob(vibes));
		}

		await new RestaurantsService().create(formData).then(async val => {
			relToast.current!.trigger(val.hasError, val.hasError ? val.errorMsg! : val.res!.message);
			if (!val.hasError && val.res) {
				await rest.fetchData();
				await plugins.fetchData();
			}
		});
	};

	const loadingScreen = () => isLoading && <div className='addrest-container-row' style={{width: '180px', justifyContent: 'flex-end', alignItems: 'center'}}>
		<label className='loading-title'>Please Wait....</label>
		<Space size={5} isAutoResize={false}></Space>
		<div className='cupertino-spinner' style={{height: '15px', width: '15px'}}></div>
	</div>;

	return (
		<div className='AddRestaurant'>
			<div className={isToolHide ? 'addrest-tools-hide' : 'addrest-tools'}>
				<div className='AddRestaurant-toast'>
					<RelToast ref={relToast}></RelToast>
				</div>
				<div className='addrest-container-row' style={{justifyContent: 'flex-end', alignItems: 'center'}}>
					{loadingScreen()}
					<Space size={25} isAutoResize={false}></Space>
					<CheckBox title='Make Live? ' initVal={isLive} onClick={(b: boolean) => {
						if (!isLoading) {
							setLive(b);
						}
					}}></CheckBox>
					<Space size={15} isAutoResize={false}></Space>
					<RestaurantContext.Consumer>
						{rest => (
							<OutlineButton onClick={() => {
								if (!isLoading) {
									setLoading(true);
									void onAdd(rest).finally(() => {
										setLoading(false);
									});
								}
							}} isLoading={isLoading} label={'Add Restaurant'} padTopBottom='8px' btnColor='rgb(70, 125, 203)'></OutlineButton>
						)}
					</RestaurantContext.Consumer>
				</div>
			</div>
			<div onScroll={onScroll} className='addrest-content'>
				<div className='addrest-container-row'>
					<TextField ref={nameField} isLoading={isLoading}
						label={'Store Name'} styles={TextFieldStyles.indiTitle} placeholder='I.e: TOI City Store'
						inputType={TextFieldInputTypes.name}></TextField>
				</div>
				<Space size={betWeenContainerSize} isAutoResize={false}></Space>
				<div className='addrest-container-row'>
					<div className='addrest-container-column'>
						<label className='addrest-label'>Select the cities (resturant service area)</label>
						<Space size={labelBetWeenContainerSize} isAutoResize={false}></Space>
						{cityLoading ? (<div className='listview-wrapper'>
							<ListView dummy={3} adapter={function (i, item): JSX.Element {
								return <div className='listview-item-adapter'>
									<label className='listview-item-adapter-index-load'>00</label>
									<Space size={5} isAutoResize={true}></Space>
									<div className='listview-item-adapter-card-load'>
										<label className='listview-item-adapter-title-load'>{'Tax Percentage : ${}%'}</label>
									</div>
								</div>;
							}} selItemIndex={-1} adapterHeight={35} />
						</div>) : (<div className='listview-wrapper'>
							<ListView ref={cityRef} data={cityData} adapter={function (i: number, item: CitiesModel | number): JSX.Element {
								const itemData = item as CitiesModel;
								return <div className='listview-item-adapter'>
									<label className='listview-item-adapter-index'>{i + 1}</label>
									<Space size={5} isAutoResize={true}></Space>
									<div className='listview-item-adapter-card'>
										<label className='listview-item-adapter-title'>{`${itemData.name}`}</label>
										<CheckBox title='' initVal={selCity.includes(i)} ovrVal={selCity.includes(i)} onClick={(b: boolean) => {
											if (!isLoading) {
												if (selCity.includes(i)) {
													setSelCity(selCity.filter(v => v !== i));
												} else {
													setSelCity([...selCity, i]);
												}
											}
										}}></CheckBox>
										<Space size={5} isAutoResize={true}></Space>
									</div>
								</div>;
							}} adapterHeight={35} selItemIndex={selCity} onSelectItem={(i: number) => {
								if (!isLoading) {
									if (selCity.includes(i)) {
										setSelCity(selCity.filter(v => v !== i));
									} else {
										setSelCity([...selCity, i]);
									}
								}
							}} />
						</div>)}
					</div>
					<Space size={5} isAutoResize={true}></Space>
					<div className='addrest-container-column'>
						<label className='addrest-label'>Select the contacts (resturant contact numbers)</label>
						<Space size={labelBetWeenContainerSize} isAutoResize={false}></Space>
						{contLoading ? (<div className='listview-wrapper'>
							<ListView dummy={3} adapter={function (i, item): JSX.Element {
								return <div className='listview-item-adapter'>
									<label className='listview-item-adapter-index-load'>00</label>
									<Space size={5} isAutoResize={true}></Space>
									<div className='listview-item-adapter-card-load'>
										<label className='listview-item-adapter-title-load'>{'Tax Percentage : ${}%'}</label>
									</div>
								</div>;
							}} selItemIndex={-1} adapterHeight={35} />
						</div>) : (<div className='listview-wrapper'>
							<ListView ref={contactRef} data={contactData} adapter={function (i: number, item: ContactsModel | number): JSX.Element {
								const itemData = item as ContactsModel;
								return <div className='listview-item-adapter'>
									<label className='listview-item-adapter-index'>{i + 1}</label>
									<Space size={5} isAutoResize={true}></Space>
									<div className='listview-item-adapter-card'>
										<label className='listview-item-adapter-title'>{`${itemData.mobileNumber ?? itemData.contactNumber}`}</label>
										<CheckBox title='' initVal={selContact.includes(i)} ovrVal={selContact.includes(i)} onClick={(b: boolean) => {
											if (!isLoading) {
												if (selContact.includes(i)) {
													setSelContact(selContact.filter(v => v !== i));
												} else {
													setSelContact([...selContact, i]);
												}
											}
										}}></CheckBox>
										<Space size={5} isAutoResize={true}></Space>
									</div>
								</div>;
							}} adapterHeight={35} selItemIndex={selContact} onSelectItem={(i: number) => {
								if (!isLoading) {
									if (selContact.includes(i)) {
										setSelContact(selContact.filter(v => v !== i));
									} else {
										setSelContact([...selContact, i]);
									}
								}
							}} />
						</div>)}
					</div>
				</div>
				<Space size={betWeenContainerSize} isAutoResize={false}></Space>
				<div className='addrest-container-row'>
					<div className='addrest-location-map'>
						<label className='addrest-label'>Restaurant Location</label>
						<Space size={labelBetWeenContainerSize} isAutoResize={false}></Space>
						<GoogleMapMain ref={mapLocation} isLoading={isLoading} height='88%' onMark={(s: string) => {
							addressField.current!.initValue(s);
						}}></GoogleMapMain>
					</div>
					<Space size={5} isAutoResize={true}></Space>
					<div className='addrest-container-column'>
						<TextField ref={addressField} isLoading={isLoading}
							label={'Enter the store address'} styles={TextFieldStyles.indiTitle} placeholder='I.e: Street, City'
							inputType={TextFieldInputTypes.name}></TextField>
						<Space size={20} isAutoResize={false}></Space>
						<ImageImport ref={storeImg} label={'Store Image (choose one)'} totalImages={1} isLoading={isLoading}></ImageImport>
					</div>
				</div>
				<Space size={betWeenContainerSize} isAutoResize={false}></Space>
				<div className='addrest-container-column'>
					<Space size={labelBetWeenContainerSize} isAutoResize={false}></Space>
					<label className='addrest-label'>Sitings Details</label>
					<Space size={labelBetWeenContainerSize} isAutoResize={false}></Space>
					{sitiListLoading ? (<div className='listview-wrapper'>
						<ListView dummy={5} adapter={function (i, item): JSX.Element {
							return <div className='listview-item-adapter'>
								<label className='listview-item-adapter-index-load'>00</label>
								<Space size={5} isAutoResize={true}></Space>
								<div className='listview-item-adapter-card-load'>
									<label className='listview-item-adapter-title-load'>{'Tax Percentage : ${}%'}</label>
								</div>
							</div>;
						}} selItemIndex={-1} adapterHeight={35} />
					</div>) : (<div className='listview-wrapper'>
						<ListView ref={sitiRef} data={sitiData} adapter={function (i: number, item: SitingsModel | number): JSX.Element {
							const itemData = item as SitingsModel;
							return <div className='listview-item-adapter'>
								<label className='listview-item-adapter-index' style={{width: '2%'}}>{i + 1}</label>
								<Space size={5} isAutoResize={true}></Space>
								<div className='listview-item-adapter-card'>
									<label className='listview-item-adapter-title' style={{width: '50%'}}>{itemData.name}</label>
									<label className='listview-item-adapter-title'>{itemData.hasCharge ? `with charge : ${itemData.amount}` : 'No charge'}</label>
									<label className='listview-item-adapter-title'>{`capacity : ${itemData.capacity}`}</label>
								</div>
								<CheckBox title='' initVal={selSitings.includes(i)} ovrVal={selSitings.includes(i)} onClick={(b: boolean) => {
									if (!isLoading) {
										if (selSitings.includes(i)) {
											setSelSiting(selSitings.filter(v => v !== i));
										} else {
											setSelSiting([...selSitings, i]);
										}
									}
								}}></CheckBox>
								<Space size={5} isAutoResize={true}></Space>
							</div>;
						}} adapterHeight={35} selItemIndex={selSitings} onSelectItem={(i: number) => {
							if (!isLoading) {
								if (selSitings.includes(i)) {
									setSelSiting(selSitings.filter(v => v !== i));
								} else {
									setSelSiting([...selSitings, i]);
								}
							}
						}} />
					</div>)}
				</div>
				<Space size={betWeenContainerSize} isAutoResize={false}></Space>
				<div className='addrest-container-column'>
					<label className='addrest-label'>Availabe Service (select the types below)</label>
					<Space size={labelBetWeenContainerSize} isAutoResize={false}></Space>
					{rsTypeLoading ? (<div className='horlistview-wrapper'>
						<HorButtons dummy={4} adapter={function (i, item): JSX.Element {
							return <div className='listview-item-adapter'>
								<div className='listview-item-adapter-card-load'>
									<label className='listview-item-adapter-title-load'>{'Tax Percentage : ${}%'}</label>
								</div>
							</div>;
						}} selItemIndex={-1} adapterHeight={35} />
					</div>) : (<div className='horlistview-wrapper'>
						<HorButtons ref={restTypesRef} data={restTypesData} adapter={function (i: number, item: RestaurantTypesModel | number): JSX.Element {
							const itemData = item as RestaurantTypesModel;
							return <div className='listview-item-adapter'>
								<div className='listview-item-adapter-card'>
									<label className='horlistview-item-adapter-title'>{`${itemData.name}`}</label>
									<Space size={5} isAutoResize={true}></Space>
									<CheckBox title='' initVal={selRsType.map(v => v.index).includes(i)} ovrVal={selRsType.map(v => v.index).includes(i)} onClick={(b: boolean) => {
										if (!isLoading) {
											if (selRsType.map(v => v.index).includes(i)) {
												setSelRsType(selRsType.filter(v => v.index !== i));
											} else {
												setSelRsType([...selRsType, {index: i, name: restTypesData[i].name.toLowerCase()}]);
											}
										}
									}}></CheckBox>
								</div>
							</div>;
						}} adapterHeight={35} selItemIndex={selRsType.map(v => v.index)} onSelectItem={(i: number) => {
							if (!isLoading) {
								if (selRsType.map(v => v.index).includes(i)) {
									setSelRsType(selRsType.filter(v => v.index !== i));
								} else {
									setSelRsType([...selRsType, {index: i, name: restTypesData[i].name.toLowerCase()}]);
								}
							}
						}} />
					</div>)}
				</div>
				{selRsType.find(v => v.name === 'delivery') && <div className='addrest-container-column'>
					<Space size={betWeenContainerSize} isAutoResize={false}></Space>
					<label className='addrest-label'>Delivery Details</label>
					<Space size={labelBetWeenContainerSize} isAutoResize={false}></Space>
					<div className='addrest-container' style={{padding: '1%', width: '98%'}}>
						<div className='addrest-in-container'>
							<div>
								<TextField ref={maxDelRadiusField} isLoading={isLoading}
									label={'Maximum delivery radius'} styles={TextFieldStyles.indiTitle} placeholder='I.e: 10km'
									inputType={TextFieldInputTypes.number} onChanged={(s: string) => {
										mapLocation.current?.setRadius(s);
									}}></TextField>
								<Space size={20} isAutoResize={false}></Space>
								<label className='addrest-label'>Availability (24hr Format)</label>
								<Space size={labelBetWeenContainerSize} isAutoResize={false}></Space>
								<DayTimePicker ref={delAvailability} isLoading={isLoading}></DayTimePicker>
							</div>
							<Space size={40} isAutoResize={false}></Space>
							<div className='addrest-container-column'>
								<CheckBox ref={delTaxCheck} title='Has Tax?' isLoading={isLoading} onClick={c => {
									setHasDelTax(c);
									if (c && taxData.length === 0) {
										void getTax();
									}
								}}></CheckBox>
								<Space size={2} isAutoResize={false}></Space>
								<label className='addrest-sublabel'>(only enable if the products for this restaurant does not have individual tax)</label>
								<Space size={labelBetWeenContainerSize} isAutoResize={false}></Space>
								{hasDelTax && (taxListLoading ? (<div className='listview-wrapper' style={{height: '280px'}}>
									<ListView dummy={10} adapter={function (i, item): JSX.Element {
										return <div className='listview-item-adapter'>
											<label className='listview-item-adapter-index-load'>00</label>
											<Space size={5} isAutoResize={true}></Space>
											<div className='listview-item-adapter-card-load'>
												<label className='listview-item-adapter-title-load'>{'Tax Percentage : ${}%'}</label>
											</div>
										</div>;
									}} selItemIndex={-1} adapterHeight={35} />
								</div>) : (<div className='listview-wrapper' style={{height: '280px'}}>
									<ListView ref={delTaxRef} data={taxData} adapter={function (i: number, item: TaxModel | number): JSX.Element {
										const itemData = item as TaxModel;
										return <div className='listview-item-adapter'>
											<label className='listview-item-adapter-index'>{i + 1}</label>
											<Space size={5} isAutoResize={true}></Space>
											<div className='listview-item-adapter-card'>
												<label className='listview-item-adapter-title'>{`Tax Percentage : ${itemData.percentage}%`}</label>
												<RadioButton title='' index={i} selIndex={selDelTax} onClick={(i: number) => {
													if (!isLoading) {
														setSelDelTax(i);
													}
												}}></RadioButton>
												<Space size={5} isAutoResize={true}></Space>
											</div>
										</div>;
									}} selItemIndex={selDelTax} onSelectItem={(i: number) => {
										if (!isLoading) {
											setSelDelTax(i);
										}
									}} adapterHeight={35} />
								</div>))}
							</div>
						</div>
						<div className='addrest-container-column'>
							<Space size={betWeenContainerSize} isAutoResize={false}></Space>
							<label className='addrest-label'>Delivery Charge</label>
							<Space size={labelBetWeenContainerSize} isAutoResize={false}></Space>
							{delChListLoading ? (<div className='listview-wrapper'>
								<ListView dummy={5} adapter={function (i, item): JSX.Element {
									return <div className='listview-item-adapter'>
										<label className='listview-item-adapter-index-load'>00</label>
										<Space size={5} isAutoResize={true}></Space>
										<div className='listview-item-adapter-card-load'>
											<label className='listview-item-adapter-title-load'>{'Tax Percentage : ${}%'}</label>
										</div>
									</div>;
								}} selItemIndex={-1} adapterHeight={35} />
							</div>) : (<div className='listview-wrapper'>
								<ListView ref={delCharRef} data={delChrData} adapter={function (i: number, item: DeliveryChargeModel | number): JSX.Element {
									const itemData = item as DeliveryChargeModel;
									return <div className='listview-item-adapter'>
										<label className='listview-item-adapter-index' style={{width: '2%'}}>{i + 1}</label>
										<Space size={5} isAutoResize={true}></Space>
										<div className='listview-item-adapter-card'>
											<label className='listview-item-adapter-title' style={{width: '40%'}}>{`Charge per Km : ${itemData.perKmCharge}`}</label>
											<label className='listview-item-adapter-title'>{itemData.condition ? itemData.condition.name : 'No freeship'}</label>
										</div>
										<RadioButton title='' index={i} selIndex={selDelChar} onClick={(i: number) => {
											if (!isLoading) {
												setSelDelChar(i);
											}
										}}></RadioButton>
										<Space size={5} isAutoResize={true}></Space>
									</div>;
								}} selItemIndex={selDelChar} onSelectItem={(i: number) => {
									if (!isLoading) {
										setSelDelChar(i);
									}
								}} adapterHeight={35} />
							</div>)}
						</div>
					</div>
				</div>}
				{selRsType.find(v => v.name === 'takeaway') && <div className='addrest-container-column'>
					<Space size={betWeenContainerSize} isAutoResize={false}></Space>
					<label className='addrest-label'>Takeaway Details</label>
					<Space size={labelBetWeenContainerSize} isAutoResize={false}></Space>
					<div className='addrest-container' style={{padding: '1%', width: '98%'}}>
						<div className='addrest-in-container'>
							<div>
								<label className='addrest-label'>Availability</label>
								<Space size={labelBetWeenContainerSize} isAutoResize={false}></Space>
								<DayTimePicker ref={takAvailability} isLoading={isLoading}></DayTimePicker>
							</div>
							<Space size={40} isAutoResize={false}></Space>
							<div className='addrest-container-column'>
								<CheckBox ref={taxTaxCheck} title='Has Tax?' isLoading={isLoading} onClick={c => {
									setHasTakTax(c);
									if (c && taxData.length === 0) {
										void getTax();
									}
								}}></CheckBox>
								<Space size={2} isAutoResize={false}></Space>
								<label className='addrest-sublabel'>(only enable if the products for this restaurant does not have individual tax)</label>
								<Space size={labelBetWeenContainerSize} isAutoResize={false}></Space>
								{hasTakTax && (taxListLoading ? (<div className='listview-wrapper' style={{height: '200px'}}>
									<ListView dummy={10} adapter={function (i, item): JSX.Element {
										return <div className='listview-item-adapter'>
											<label className='listview-item-adapter-index-load'>00</label>
											<Space size={5} isAutoResize={true}></Space>
											<div className='listview-item-adapter-card-load'>
												<label className='listview-item-adapter-title-load'>{'Tax Percentage : ${}%'}</label>
											</div>
										</div>;
									}} selItemIndex={-1} adapterHeight={35} />
								</div>) : (<div className='listview-wrapper' style={{height: '200px'}}>
									<ListView ref={takTaxRef} data={taxData} adapter={function (i: number, item: TaxModel | number): JSX.Element {
										const itemData = item as TaxModel;
										return <div className='listview-item-adapter'>
											<label className='listview-item-adapter-index'>{i + 1}</label>
											<Space size={5} isAutoResize={true}></Space>
											<div className='listview-item-adapter-card'>
												<label className='listview-item-adapter-title'>{`Tax Percentage : ${itemData.percentage}%`}</label>
												<RadioButton title='' index={i} selIndex={selTakTax} onClick={(i: number) => {
													if (!isLoading) {
														setSelTakTax(i);
													}
												}}></RadioButton>
												<Space size={5} isAutoResize={true}></Space>
											</div>
										</div>;
									}} selItemIndex={selTakTax} onSelectItem={(i: number) => {
										if (!isLoading) {
											setSelTakTax(i);
										}
									}} adapterHeight={35} />
								</div>))}
							</div>
						</div>
					</div>
				</div>}
				{selRsType.find(v => v.name === 'dining') && <div className='addrest-container-column'>
					<Space size={betWeenContainerSize} isAutoResize={false}></Space>
					<label className='addrest-label'>Dining Details</label>
					<Space size={labelBetWeenContainerSize} isAutoResize={false}></Space>
					<div className='addrest-container' style={{padding: '1%', width: '98%'}}>
						<div className='addrest-container-row'>
							<div>
								<label className='addrest-label'>Availability</label>
								<Space size={labelBetWeenContainerSize} isAutoResize={false}></Space>
								<DayTimePicker ref={dinAvailability} isLoading={isLoading}></DayTimePicker>
							</div>
							<Space size={40} isAutoResize={false}></Space>
							<div className='addrest-container-column'>
								<CheckBox ref={dinTaxCheck} title='Has Tax?' isLoading={isLoading} onClick={c => {
									setHasDinTax(c);
									if (c && taxData.length === 0) {
										void getTax();
									}
								}}></CheckBox>
								<Space size={2} isAutoResize={false}></Space>
								<label className='addrest-sublabel'>(only enable if the products for this restaurant does not have individual tax)</label>
								<Space size={labelBetWeenContainerSize} isAutoResize={false}></Space>
								{hasDinTax && (taxListLoading ? (<div className='listview-wrapper' style={{height: '200px'}}>
									<ListView dummy={10} adapter={function (i, item): JSX.Element {
										return <div className='listview-list-adapter'>
											<label className='listview-item-adapter-index-load'>00</label>
											<Space size={5} isAutoResize={true}></Space>
											<div className='listview-item-adapter-card-load'>
												<label className='listview-item-adapter-title-load'>{'Tax Percentage : ${}%'}</label>
											</div>
										</div>;
									}} selItemIndex={-1} adapterHeight={35} />
								</div>) : (<div className='listview-wrapper' style={{height: '200px'}}>
									<ListView ref={dinTaxRef} data={taxData} adapter={function (i: number, item: TaxModel | number): JSX.Element {
										const itemData = item as TaxModel;
										return <div className='listview-item-adapter'>
											<label className='listview-item-adapter-index'>{i + 1}</label>
											<Space size={5} isAutoResize={true}></Space>
											<div className='listview-item-adapter-card'>
												<label className='listview-item-adapter-title'>{`Tax Percentage : ${itemData.percentage}%`}</label>
												<RadioButton title='' index={i} selIndex={selDinTax} onClick={(i: number) => {
													if (!isLoading) {
														setSelDinTax(i);
													}
												}}></RadioButton>
												<Space size={5} isAutoResize={true}></Space>
											</div>
										</div>;
									}} selItemIndex={selDinTax} onSelectItem={(i: number) => {
										if (!isLoading) {
											setSelDinTax(i);
										}
									}} adapterHeight={35} />
								</div>))}
							</div>
						</div>
						<Space size={20} isAutoResize={false}></Space>
						<div className='addrest-container-row'>
							<div className='addrest-container-column' style={{width: '48%'}}>
								<label className='addrest-label'>Time Slots (24hr format)</label>
								<Space size={labelBetWeenContainerSize} isAutoResize={false}></Space>
								<div className='addrest-container' style={{padding: '1%', height: '200px'}}>
									<TimeSlots ref={timeSlots} isLoading={isLoading}></TimeSlots>
								</div>
							</div>
							<Space size={3} isAutoResize={true}></Space>
							<div className='addrest-container-column' style={{width: '48%'}}>
								<label className='addrest-label'>Facilities</label>
								<Space size={labelBetWeenContainerSize} isAutoResize={false}></Space>
								<div className='addrest-container' style={{padding: '1%', height: '200px'}}>
									<ListSlots ref={facilities} isLoading={isLoading} title={'Enter available facilities'} placeHold={'I.e: Wifi'}></ListSlots>
								</div>
							</div>
						</div>
						<Space size={labelBetWeenContainerSize} isAutoResize={false}></Space>
						<ImageImport ref={photoImg} label={'Photos (max 5)'} totalImages={5} isLoading={isLoading}></ImageImport>
						<Space size={labelBetWeenContainerSize} isAutoResize={false}></Space>
						<ImageImport ref={vibesImg} label={'Vibes (max 5)'} totalImages={5} isLoading={isLoading}></ImageImport>
					</div>
				</div>}
				{selRsType.find(v => v.name === 'direct') && <div className='addrest-container-column'>
					<Space size={betWeenContainerSize} isAutoResize={false}></Space>
					<label className='addrest-label'>Direct Details</label>
					<Space size={labelBetWeenContainerSize} isAutoResize={false}></Space>
					<div className='addrest-container' style={{padding: '1%', width: '98%'}}>
						<div className='addrest-in-container'>
							<div className='addrest-container-column'>
								<CheckBox ref={dirTaxCheck} title='Has Tax?' isLoading={isLoading} onClick={c => {
									setHasDirTax(c);
									if (c && taxData.length === 0) {
										void getTax();
									}
								}}></CheckBox>
								<Space size={2} isAutoResize={false}></Space>
								<label className='addrest-sublabel'>(only enable if the products for this restaurant does not have individual tax)</label>
								<Space size={labelBetWeenContainerSize} isAutoResize={false}></Space>
								{hasDirTax && (taxListLoading ? (<div className='listview-wrapper' style={{height: '200px'}}>
									<ListView dummy={10} adapter={function (i, item): JSX.Element {
										return <div className='listview-item-adapter'>
											<label className='listview-item-adapter-index-load'>00</label>
											<Space size={5} isAutoResize={true}></Space>
											<div className='listview-item-adapter-card-load'>
												<label className='listview-item-adapter-title-load'>{'Tax Percentage : ${}%'}</label>
											</div>
										</div>;
									}} selItemIndex={-1} adapterHeight={35} />
								</div>) : (<div className='listview-wrapper' style={{height: '200px'}}>
									<ListView ref={dirTaxRef} data={taxData} adapter={function (i: number, item: TaxModel | number): JSX.Element {
										const itemData = item as TaxModel;
										return <div className='listview-item-adapter'>
											<label className='listview-item-adapter-index'>{i + 1}</label>
											<Space size={5} isAutoResize={true}></Space>
											<div className='listview-item-adapter-card'>
												<label className='listview-item-adapter-title'>{`Tax Percentage : ${itemData.percentage}%`}</label>
												<RadioButton title='' index={i} selIndex={selDirTax} onClick={(i: number) => {
													if (!isLoading) {
														setSelDirTax(i);
													}
												}}></RadioButton>
												<Space size={5} isAutoResize={true}></Space>
											</div>
										</div>;
									}} selItemIndex={selDirTax} onSelectItem={(i: number) => {
										if (!isLoading) {
											setSelDirTax(i);
										}
									}} adapterHeight={35} />
								</div>))}
							</div>
						</div>
					</div>
				</div>}
			</div>
		</div >
	);
};

export default AddRestaurant;
