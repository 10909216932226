import axios, {type AxiosError} from 'axios';
import endpoints from '../utils/endpoints';
import messages from '../utils/messages';
import {type ResponseData, type ServiceResponse} from '../models/response';
import {serviceError, serviceSuccess} from '../utils/serviceHandler';
import {type ReqPrinter, type ResPrinter} from '../models/printer';
import {type ReqPrintData, type PrintAckData} from '../context/socketContext';

type ServerMessage = {
	message: string;
};

class PrinterService {
	get = async (): Promise<ServiceResponse<ResponseData<ResPrinter[]>>> => {
		try {
			const response = await axios.get<ResponseData<ResPrinter[]>>(endpoints.printers,
				{
					headers: {
						Authorization: `Bearer ${localStorage.getItem('AUT-OWNER')!}`,
						'Content-Type': 'application/json',
					},
				});
			await serviceSuccess();
			return {hasError: false, res: response.data};
		} catch (error) {
			const axiosError = error as AxiosError<ServerMessage>;
			if (axiosError.response?.data?.message) {
				await serviceError(axiosError.response?.data?.message);

				// Access the error message from the server response
				const errorMessage = axiosError.response.data.message;
				return {hasError: true, errorMsg: errorMessage};
			}

			return {hasError: true, errorMsg: messages.Ise};
		}
	};

	create = async (req: ReqPrinter): Promise<ServiceResponse<ResponseData<undefined>>> => {
		try {
			const response = await axios.post<ResponseData<undefined>>(endpoints.printer,
				req,
				{
					headers: {
						Authorization: `Bearer ${localStorage.getItem('AUT-OWNER')!}`,
						'Content-Type': 'application/json',
					},
				});
			await serviceSuccess();
			return {hasError: false, res: response.data};
		} catch (error) {
			const axiosError = error as AxiosError<ServerMessage>;
			if (axiosError.response?.data?.message) {
				await serviceError(axiosError.response?.data?.message);

				// Access the error message from the server response
				const errorMessage = axiosError.response.data.message;
				return {hasError: true, errorMsg: errorMessage};
			}

			return {hasError: true, errorMsg: messages.Ise};
		}
	};

	porr = async (prnId: string): Promise<ServiceResponse<ResponseData<undefined>>> => {
		try {
			const response = await axios.patch<ResponseData<undefined>>(endpoints.pauseors,
				{prnId},
				{
					headers: {
						Authorization: `Bearer ${localStorage.getItem('AUT-OWNER')!}`,
						'Content-Type': 'application/json',
					},
				});
			await serviceSuccess();
			return {hasError: false, res: response.data};
		} catch (error) {
			const axiosError = error as AxiosError<ServerMessage>;
			if (axiosError.response?.data?.message) {
				await serviceError(axiosError.response?.data?.message);

				// Access the error message from the server response
				const errorMessage = axiosError.response.data.message;
				return {hasError: true, errorMsg: errorMessage};
			}

			return {hasError: true, errorMsg: messages.Ise};
		}
	};

	default = async (prnId: string): Promise<ServiceResponse<ResponseData<undefined>>> => {
		try {
			const response = await axios.patch<ResponseData<undefined>>(endpoints.default,
				{prnId},
				{
					headers: {
						Authorization: `Bearer ${localStorage.getItem('AUT-OWNER')!}`,
						'Content-Type': 'application/json',
					},
				});
			await serviceSuccess();
			return {hasError: false, res: response.data};
		} catch (error) {
			const axiosError = error as AxiosError<ServerMessage>;
			if (axiosError.response?.data?.message) {
				await serviceError(axiosError.response?.data?.message);

				// Access the error message from the server response
				const errorMessage = axiosError.response.data.message;
				return {hasError: true, errorMsg: errorMessage};
			}

			return {hasError: true, errorMsg: messages.Ise};
		}
	};

	status = async (req: {prnId: string; status: string; isOnline: boolean}): Promise<ServiceResponse<ResponseData<undefined>>> => {
		try {
			const response = await axios.patch<ResponseData<undefined>>(endpoints.printstate,
				req,
				{
					headers: {
						Authorization: `Bearer ${localStorage.getItem('AUT-OWNER')!}`,
						'Content-Type': 'application/json',
					},
				});
			await serviceSuccess();
			return {hasError: false, res: response.data};
		} catch (error) {
			const axiosError = error as AxiosError<ServerMessage>;
			if (axiosError.response?.data?.message) {
				await serviceError(axiosError.response?.data?.message);

				// Access the error message from the server response
				const errorMessage = axiosError.response.data.message;
				return {hasError: true, errorMsg: errorMessage};
			}

			return {hasError: true, errorMsg: messages.Ise};
		}
	};

	directprint = async (ordId: string): Promise<ServiceResponse<ResponseData<undefined>>> => {
		try {
			const response = await axios.post<ResponseData<undefined>>(endpoints.directprint,
				{ordId},
				{
					headers: {
						Authorization: `Bearer ${localStorage.getItem('AUT-OWNER')!}`,
						'Content-Type': 'application/json',
					},
				});
			await serviceSuccess();
			return {hasError: false, res: response.data};
		} catch (error) {
			const axiosError = error as AxiosError<ServerMessage>;
			if (axiosError.response?.data?.message) {
				await serviceError(axiosError.response?.data?.message);

				// Access the error message from the server response
				const errorMessage = axiosError.response.data.message;
				return {hasError: true, errorMsg: errorMessage};
			}

			return {hasError: true, errorMsg: messages.Ise};
		}
	};

	local = async (req: ReqPrintData): Promise<ServiceResponse<PrintAckData>> => {
		try {
			const response = await axios.post<PrintAckData>(`${endpoints.local}${endpoints.localonprint}`,
				req,
				{
					headers: {
						'Content-Type': 'application/json',
					},
				});
			await serviceSuccess();
			return {hasError: false, res: response.data};
		} catch (error) {
			const axiosError = error as AxiosError<ServerMessage>;
			if (axiosError.response?.data?.message) {
				await serviceError(axiosError.response?.data?.message);

				// Access the error message from the server response
				const errorMessage = axiosError.response.data.message;
				return {hasError: true, errorMsg: errorMessage};
			}

			return {hasError: true, errorMsg: messages.Ise};
		}
	};
}

export default PrinterService;
