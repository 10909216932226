import React, {useEffect, useRef, useState} from 'react';
import './Purchases.css';
import Space from '../../components/Space/Space';
import OutlineButton from '../../components/OutlineButton/OutlineButton';
import ListView, {type ListViewHandle} from '../../components/ListView/ListView';
import TextField, {type TextFieldHandle} from '../../components/TextField/TextField';
import Button from '../../components/Button/Button';
import TextFieldInputTypes from '../../utils/textfield/fieldtypes';
import SnackRibbon, {type SnackRibbonHandle} from '../../components/SnackRibbon/SnackRibbon';
import {type RestaurantModel} from '../../models/restaurants';
import CheckBox from '../../components/CheckBox/CheckBox';
import {type ReqPurchasesMatModel, type PurchaseMaterialModel, type PurchasesModel} from '../../models/purchases';
import PurchasesService from '../../services/purchases';
import HorButtons from '../../components/HorButtons/HorButtons';
import RadioButton from '../../components/RadioButton/RadioButton';
import DatePicker, {type DatePickerHandle} from '../../components/DatePicker/DatePicker';
import SuppliersService from '../../services/suppliers';
import {type SuppliersModel} from '../../models/suppliers';
import {type MaterialsModel} from '../../models/materials';
import MaterialsService from '../../services/materials';
import TextFieldStyles from '../../utils/textfield/fieldstyles';
import IconButton from '../../components/IconButton/IconButton';
import Delete from '../../assets/icons/delete';
import {useAuthContext} from '../../context/authContext';
import {RestaurantContext} from '../../context/restContext';

type CustomStyleType = {
	'--justifyContent': string;
};

type ExtendedDivStyle = React.CSSProperties & CustomStyleType;

const Purchases = () => {
	const authRes = useAuthContext();

	const delIcon = new Delete();
	const [isAddPurc, setAddPurc] = useState<boolean>(false);
	const [data, setData] = useState<PurchasesModel[]>([]);
	const [suData, setSuData] = useState<SuppliersModel[]>([]);
	const [selItemIndex, setItemIndex] = useState<number>(-1);
	const [isLoading, setLoading] = useState<boolean>(true);

	const snackBarRibbon = useRef<SnackRibbonHandle>(null);
	const billId = useRef<TextFieldHandle>(null);
	const weighField = useRef<TextFieldHandle>(null);
	const priceField = useRef<TextFieldHandle>(null);
	const taxField = useRef<TextFieldHandle>(null);
	const [selRest, setSelRest] = useState<number>(-1);
	const [selSup, setSelSup] = useState<number>(-1);
	const [isWeight, setIsWeight] = useState<boolean>(false);
	const matRef = useRef<ListViewHandle>(null);
	const [matData, setMatData] = useState<MaterialsModel[]>([]);
	const [selMat, setSelMat] = useState<number>(-1);
	const [selMatData, setSelMatData] = useState<ReqPurchasesMatModel[]>([]);
	const dateRef = useRef<DatePickerHandle>(null);

	const [selMatForStock, setSelMatForStock] = useState<number[]>([]);
	const [hasWastage, setHasWastage] = useState<boolean>(false);
	const [isStockWeight, setIsStockWeight] = useState<boolean>(false);
	const stockWeighField = useRef<TextFieldHandle>(null);
	const reasonField = useRef<TextFieldHandle>(null);
	const dumDateRef = useRef<DatePickerHandle>(null);

	useEffect(() => {
		void fetchSupp();
		void fetchMaterials();
		void fetchPurchases();
	}, []);

	async function fetchSupp() {
		if (!isLoading) {
			setLoading(false);
		}

		// Const restId = authRes.restData ? authRes.restData[authRes.selRestRef]._id : undefined;
		await new SuppliersService().get(undefined).then(val => {
			if (val.hasError) {
				snackBarRibbon.current!.trigger(val.hasError, val.errorMsg!);
			} else {
				setSuData(val.res!.data!);
			}
		});
	}

	async function fetchMaterials() {
		if (!isLoading) {
			setLoading(false);
		}

		await new MaterialsService().get().then(val => {
			if (val.hasError) {
				snackBarRibbon.current!.trigger(val.hasError, val.errorMsg!);
			} else {
				setMatData(val.res!.data!);
			}
		});
	}

	async function fetchPurchases() {
		if (!isLoading) {
			setLoading(false);
		}

		// Const restId = authRes.restData ? authRes.restData[authRes.selRestRef]._id : undefined;
		await new PurchasesService().get(undefined).then(val => {
			if (val.hasError) {
				snackBarRibbon.current!.trigger(val.hasError, val.errorMsg!);
			} else {
				setData(val.res!.data!);
			}
		}).finally(() => {
			setLoading(false);
		});
	}

	const handleOnAddlick = () => {
		setAddPurc(true);
		setItemIndex(-1);
	};

	const onSave = async (rest: RestaurantModel[]) => {
		if (selMatData.length === 0) {
			snackBarRibbon.current!.trigger(true, 'Add atleast an material');
		} else if (dateRef.current!.getValue() === undefined) {
			snackBarRibbon.current!.trigger(true, 'Select the date of purchase');
		} else if (!billId.current!.validate().isValid) {
			snackBarRibbon.current!.trigger(true, billId.current!.validate().msg);
		} else if (selSup === -1) {
			snackBarRibbon.current!.trigger(true, 'Select the supplier');
		} else {
			if (!isLoading) {
				setLoading(true);
			}

			await new PurchasesService().create({
				dateOfPurchase: dateRef.current!.getValue()!,
				restId: selRest === -1 ? null : rest[selRest]._id,
				billId: billId.current!.getValue().toUpperCase(),
				supId: suData[selSup]._id,
				materials: selMatData.map(v => ({
					matId: v.matId,
					isWeight: v.isWeight,
					weight: v.weight,
					nos: v.nos,
					price: v.price,
					tax: v.tax,
				})),
			}).then(async val => {
				if (val.hasError) {
					snackBarRibbon.current!.trigger(val.hasError, val.errorMsg!);
				} else {
					await fetchPurchases();

					snackBarRibbon.current!.trigger(false, val.res!.message);

					setSelMatData([]);
					billId.current!.clearInput();
					setSelSup(-1);
				}
			}).finally(() => {
				setLoading(false);
			});
		}
	};

	const onAddMaterial = () => {
		if (!priceField.current!.validate().isValid) {
			snackBarRibbon.current!.trigger(true, 'Enter the price');
		} else if (!weighField.current!.validate().isValid) {
			snackBarRibbon.current!.trigger(true, isWeight ? 'Enter the weight' : 'Enter the nos');
		} else if (selMat === -1) {
			snackBarRibbon.current!.trigger(true, 'Select the material');
		} else {
			setSelMatData([...selMatData, {
				matName: matData[selMat].name,
				matId: matData[selMat]._id,
				isWeight,
				weight: parseFloat(weighField.current!.getValue()),
				nos: Number(weighField.current!.getValue()),
				price: parseFloat(priceField.current!.getValue()),
				tax: taxField.current!.getValue() === '' ? 0 : parseFloat(taxField.current!.getValue()),
			}]);
		}
	};

	const onUpdate = async () => {
		if (selMatForStock.length === 0) {
			snackBarRibbon.current!.trigger(true, 'Select atleast an material for update');
		} else if (!stockWeighField.current!.validate().isValid) {
			snackBarRibbon.current!.trigger(true, stockWeighField.current!.validate().msg);
		} else if (!reasonField.current!.validate().isValid) {
			snackBarRibbon.current!.trigger(true, reasonField.current!.validate().msg);
		} else if (dumDateRef.current!.getValue() === undefined) {
			snackBarRibbon.current!.trigger(true, 'Select the dumped date');
		} else {
			if (!isLoading) {
				setLoading(true);
			}

			await new PurchasesService().stockupdate({
				purId: data[selItemIndex]._id,
				materials: selMatForStock.map((v, i) => ({
					matId: data[selItemIndex].materials[i].mat._id,
					isStockFinished: true,
					wastage: {
						hasWastage,
						isWeight: isStockWeight,
						weight: isStockWeight ? parseFloat(stockWeighField.current!.getValue()) : 0,
						nos: isStockWeight ? 0 : parseFloat(stockWeighField.current!.getValue()),
						reason: reasonField.current!.getValue(),
						dumpedDate: dumDateRef.current!.getValue()!,
					},
				})),
			}).then(async val => {
				if (val.hasError) {
					snackBarRibbon.current!.trigger(val.hasError, val.errorMsg!);
				} else {
					await fetchPurchases();

					snackBarRibbon.current!.trigger(false, val.res!.message);

					setSelMatForStock([]);
				}
			}).finally(() => {
				setLoading(false);
			});
		}
	};

	const customStyle: ExtendedDivStyle = {
		'--justifyContent': (selItemIndex === -1 && !isAddPurc) ? 'center' : 'start',
	};

	const materialsUi = () => <div className='AddFood-container-column'>
		<label className='Purchases-detail-perc'>Materials</label>
		<Space size={10} isAutoResize={false}></Space>
		<div className='listview-wrapper' style={{height: '120px'}}>
			{selMatData.length === 0 ? <div style={{height: '100%', display: 'flex', justifyContent: 'center', alignItems: 'center'}}>
				<label className='AddFood-label-sub' style={{textAlign: 'center'}}>Add the materials for this purchase</label>
			</div> : <ListView data={selMatData} adapter={function (i: number, item: ReqPurchasesMatModel | number): JSX.Element {
				const itemData = item as ReqPurchasesMatModel;
				return <div className='Addfood-horlist-item-adapter'>
					<div style={{width: '100%', display: 'flex', flexDirection: 'row', alignItems: 'center'}}>
						<label className='listview-item-adapter-index' style={{width: '100%'}}>{i + 1}</label>
						<Space size={10} isAutoResize={false}></Space>
						<IconButton icon={delIcon.normal()} onClick={() => {
							setSelMatData(prevData => prevData.filter((item, j) => j !== i));
						}}></IconButton>
					</div>
					<Space size={10} isAutoResize={false}></Space>
					<label className='listview-item-adapter-title'>{`${itemData.matName}`}</label>
					<Space size={5} isAutoResize={false}></Space>
					<label className='listview-item-adapter-title'>{`${itemData.isWeight ? itemData.weight : itemData.nos}`}</label>
					<Space size={5} isAutoResize={false}></Space>
					<label className='listview-item-adapter-title'>{`${itemData.price}`}</label>
				</div>;
			}} adapterHeight={110} isHorizontal={true} />}
		</div>
		<Space size={10} isAutoResize={false}></Space>
		<div className='AddFood-container-row'>
			<div className='AddFood-container-column'>
				{isLoading ? (<div className='listview-wrapper'>
					<ListView dummy={3} adapter={function (i, item): JSX.Element {
						return <div className='listview-item-adapter'>
							<label className='listview-item-adapter-index-load'>00</label>
							<Space size={5} isAutoResize={true}></Space>
							<div className='listview-item-adapter-card-load'>
								<label className='listview-item-adapter-title-load'>{'Tax Percentage : ${}%'}</label>
							</div>
						</div>;
					}} selItemIndex={-1} adapterHeight={35} />
				</div>) : (<div className='listview-wrapper'>
					<ListView ref={matRef} data={matData} adapter={function (i: number, item: MaterialsModel | number): JSX.Element {
						const itemData = item as MaterialsModel;
						return <div className='listview-item-adapter'>
							<label className='listview-item-adapter-index'>{i + 1}</label>
							<Space size={5} isAutoResize={true}></Space>
							<div className='listview-item-adapter-card'>
								<label className='listview-item-adapter-title'>{`${itemData.name}`}</label>
								<RadioButton title={''} index={i} selIndex={selMat} onClick={(j: number) => {
									if (!isLoading) {
										setSelMat(j);
									}
								}} ></RadioButton>
								<Space size={5} isAutoResize={true}></Space>
							</div>
						</div>;
					}} adapterHeight={35} selItemIndex={selMat} onSelectItem={(j: number) => {
						if (!isLoading) {
							setSelMat(j);
						}
					}} />
				</div>)}
			</div>
			<Space size={5} isAutoResize={true}></Space>
			<div className='AddFood-container-column'>
				<CheckBox title={'Unit is Weight?'} initVal={isWeight} onClick={(b: boolean) => {
					if (!isLoading) {
						setIsWeight(b);
					}
				}}></CheckBox>
				<Space size={15} isAutoResize={false}></Space>
				<div className='AddFood-container-row'>
					<TextField ref={weighField} isLoading={isLoading}
						label={isWeight ? 'Weight' : 'Nos'} styles={TextFieldStyles.indiTitle} placeholder={isWeight ? 'I.e: 1.5' : 'I.e: 1'}
						inputType={isWeight ? TextFieldInputTypes.floatnumber : TextFieldInputTypes.number}></TextField>
					<Space size={15} isAutoResize={false}></Space>
					<TextField ref={priceField} isLoading={isLoading}
						label={'Price'} styles={TextFieldStyles.indiTitle} placeholder={'I.e: 1.5'}
						inputType={TextFieldInputTypes.floatnumber}></TextField>
				</div>
				<Space size={15} isAutoResize={false}></Space>
				<TextField ref={taxField} isLoading={isLoading}
					label={'Tax (optional)'} styles={TextFieldStyles.indiTitle} placeholder={'I.e: 1.5'}
					inputType={TextFieldInputTypes.floatnumber}></TextField>
				<Space size={15} isAutoResize={false}></Space>
				<Button onClick={function (): void {
					onAddMaterial();
				}} label={'Add Material'}></Button>
			</div>
		</div>
	</div>;

	const stockFinUi = () => <div>
		<Button isLoading={isLoading} label={'Update Stock'} onClick={onUpdate}></Button>
		<Space size={10} isAutoResize={false}></Space>
		<label className='Purchases-detail-perc'>Multiple selection for update stock will apply single data to all, if want to update only one materials stock select one</label>
		<Space size={20} isAutoResize={false}></Space>
		<CheckBox title={'Has Wastage?'} initVal={hasWastage} onClick={(b: boolean) => {
			setHasWastage(b);
		}}></CheckBox>
		<Space size={20} isAutoResize={false}></Space>
		<div className='Purchases-row'>
			<CheckBox title={'Is Weight?'} initVal={isStockWeight} onClick={(b: boolean) => {
				setIsStockWeight(b);
			}}></CheckBox>
			<Space size={20} isAutoResize={false}></Space>
			<TextField ref={stockWeighField} isLoading={isLoading}
				label={isStockWeight ? 'Weight' : 'Nos'} styles={TextFieldStyles.indiTitle} placeholder={isStockWeight ? 'I.e: 1.5' : 'I.e: 1'}
				inputType={isStockWeight ? TextFieldInputTypes.floatnumber : TextFieldInputTypes.number}></TextField>
			<Space size={20} isAutoResize={false}></Space>
			<TextField ref={reasonField} isLoading={isLoading}
				label={'Dumped Reason'} styles={TextFieldStyles.indiTitle} placeholder={'I.e: cutting waste'}
				inputType={TextFieldInputTypes.name}></TextField>
		</div>
		<Space size={20} isAutoResize={false}></Space>
		<DatePicker ref={dumDateRef} label={'Dumped Date'} hasPast={true}></DatePicker>
	</div >;

	return (
		<div className='Purchases'>
			<div className='Purchases-left-panel'>
				<div className='Purchases-left-tools'>
					<label className='Purchases-count-label'>{`Total : ${data.length}`}</label>
					<OutlineButton onClick={handleOnAddlick} label={'Add Purchase'}></OutlineButton>
				</div>
				{isLoading ? (<div className='Purchases-left-list'>
					<ListView dummy={5} adapter={function (i, item): JSX.Element {
						return <div className='Purchases-list-adapter'>
							<label className='Purchases-list-adapter-index-load'>00</label>
							<Space size={5} isAutoResize={true}></Space>
							<div className='Purchases-list-adapter-card'>
								<label className='Purchases-list-adapter-update-load'>{'Tax Percentage'}</label>
							</div>
						</div>;
					}} selItemIndex={-1} adapterHeight={50} />
				</div>) : (<div className='Purchases-left-list'>
					<ListView data={data} adapter={function (i, item): JSX.Element {
						const itemData = item as PurchasesModel;
						return <div className='Purchases-list-adapter'>
							<label className='Purchases-list-adapter-index'>{i + 1}</label>
							<Space size={5} isAutoResize={true}></Space>
							<div className='Purchases-list-adapter-card'>
								<label className='Purchases-list-adapter-perc'>{itemData.billId}</label>
								<Space size={5} isAutoResize={false}></Space>
								<label className='Purchases-list-adapter-update'>{itemData.dateOfPurchase}</label>
							</div>
						</div>;
					}} selItemIndex={selItemIndex} onSelectItem={i => {
						setAddPurc(false);
						setItemIndex(i);
					}} adapterHeight={50} />
				</div>)}
			</div>
			<RestaurantContext.Consumer>
				{rest => (<div className='Purchases-right-panel'>
					<div className='Purchases-right-content' style={customStyle}>
						{selItemIndex === -1 && !isAddPurc && <label className='Purchases-right-empty-content'>No items selected</label>}
						{selItemIndex !== -1 && !isAddPurc && <div>
							<label className='Purchases-detail-no'>{`No : ${selItemIndex + 1}`}</label>
							<Space size={5} isAutoResize={false}></Space>
							<label className='Purchases-detail-perc'>{data[selItemIndex].billId}</label>
							<Space size={5} isAutoResize={false}></Space>
							<label className='Purchases-detail-perc'>{data[selItemIndex].dateOfPurchase}</label>
							<Space size={15} isAutoResize={false}></Space>
							<div>
								<label className='Purchases-detail-perc'>Restaurant</label>
								<Space size={5} isAutoResize={false}></Space>
								<label className='Purchases-detail-perc'>{data[selItemIndex].restaurant.name}</label>
								<Space size={5} isAutoResize={false}></Space>
								<label className='Purchases-detail-perc'>{data[selItemIndex].restaurant.address}</label>
								<Space size={15} isAutoResize={false}></Space>
							</div>
							<label className='Purchases-detail-perc'>Supplier</label>
							<Space size={5} isAutoResize={false}></Space>
							<label className='Purchases-detail-perc'>{data[selItemIndex].supplier.name}</label>
							<Space size={5} isAutoResize={false}></Space>
							<label className='Purchases-detail-perc'>{data[selItemIndex].supplier.address}</label>
							<Space size={15} isAutoResize={false}></Space>
							<label className='Purchases-detail-perc'>Materials</label>
							<Space size={5} isAutoResize={false}></Space>
							<div style={{height: '250px'}}>
								<HorButtons data={data[selItemIndex].materials} adapter={function (i: number, item: PurchaseMaterialModel): JSX.Element {
									return <div className='listview-item-adapter'>
										<div className='listview-item-adapter-card' style={{flexDirection: 'column', justifyContent: 'flex-start', alignItems: 'flex-start'}}>
											<Space size={10} isAutoResize={false}></Space>
											<label className='horlistview-item-adapter-title'>{item.mat.name}</label>
											<Space size={5} isAutoResize={false}></Space>
											<label className='horlistview-item-adapter-title'>{item.mat.note}</label>
											<label className='Purchases-detail-perc'>{item.isStockFinished ? 'Stock Finished' : 'In Stock'}</label>
											<Space size={5} isAutoResize={false}></Space>
											<label className='Purchases-detail-perc'>{item.isStockFinished ? item.finishedAt : ''}</label>
											<Space size={10} isAutoResize={false}></Space>
											<label className='horlistview-item-adapter-title'>{item.isWeight ? `Weight : ${item.weight}` : `Nos : ${item.nos}`}</label>
											<Space size={5} isAutoResize={false}></Space>
											<label className='horlistview-item-adapter-title'>{`Price : ${item.price}`}</label>
											<Space size={5} isAutoResize={false}></Space>
											<label className='horlistview-item-adapter-title'>{`Tax : ${item.tax}`}</label>
											<Space size={10} isAutoResize={false}></Space>
											<label className='horlistview-item-adapter-title'>{item.wastage.hasWastage ? 'Wastage' : 'No Wastage'}</label>
											{item.wastage.hasWastage && <Space size={5} isAutoResize={false}></Space>}
											{item.wastage.hasWastage && <label className='horlistview-item-adapter-title'>{`Dumped Date : ${item.wastage.dumpedDate}`}</label>}
											{item.wastage.hasWastage && <Space size={5} isAutoResize={false}></Space>}
											{item.wastage.hasWastage && <label className='horlistview-item-adapter-title'>{item.wastage.isWeight ? `Weight : ${item.wastage.weight}` : `Nos : ${item.wastage.nos}`}</label>}
											{item.wastage.hasWastage && <Space size={5} isAutoResize={false}></Space>}
											{item.wastage.hasWastage && <label className='horlistview-item-adapter-title'>{`Reason : ${item.wastage.reason}`}</label>}
											<Space size={10} isAutoResize={false}></Space>
											{!item.isStockFinished && <CheckBox title={'Select'} initVal={selMatForStock.includes(i)} ovrVal={selMatForStock.includes(i)} onClick={(b: boolean) => {
												if (!isLoading) {
													if (selMatForStock.includes(i)) {
														setSelMatForStock(selMatForStock.filter(v => v !== i));
													} else {
														setSelMatForStock([...selMatForStock, i]);
													}
												}
											}}></CheckBox>}
										</div>
									</div>;
								}} adapterHeight={250} adapterWidth={350} selItemIndex={selMatForStock} onSelectItem={(i: number) => {
									if (!isLoading && !data[selItemIndex].materials[i].isStockFinished) {
										if (selMatForStock.includes(i)) {
											setSelMatForStock(selMatForStock.filter(v => v !== i));
										} else {
											setSelMatForStock([...selMatForStock, i]);
										}
									}
								}}></HorButtons>
							</div>
							<Space size={15} isAutoResize={false}></Space>
							{stockFinUi()}
							<Space size={50} isAutoResize={false}></Space>
						</div>}
						{isAddPurc && <div>
							<Button isLoading={isLoading} label={'Save'} onClick={() => {
								void onSave(rest.data);
							}}></Button>
							<Space size={10} isAutoResize={false}></Space>
							<label className='Purchases-detail-perc'>Select the restaurant (if this purchase not related to any restaurant just keep it not selected any restaurant)</label>
							<Space size={10} isAutoResize={false}></Space>
							{(isLoading ? (<div className='listview-wrapper'>
								<ListView dummy={3} adapter={function (i, item): JSX.Element {
									return <div className='listview-item-adapter'>
										<label className='listview-item-adapter-index-load'>00</label>
										<Space size={5} isAutoResize={true}></Space>
										<div className='listview-item-adapter-card-load'>
											<label className='listview-item-adapter-title-load'>{'Tax Percentage : ${}%'}</label>
										</div>
									</div>;
								}} selItemIndex={-1} adapterHeight={35} />
							</div>) : (<div className='listview-wrapper'>
								<ListView data={rest.data} adapter={function (i: number, item: RestaurantModel | number): JSX.Element {
									const itemData = item as RestaurantModel;
									return <div className='listview-item-adapter'>
										<label className='listview-item-adapter-index'>{i + 1}</label>
										<Space size={5} isAutoResize={true}></Space>
										<div className='listview-item-adapter-card'>
											<label className='listview-item-adapter-title'>{`${itemData.name}`}</label>
											<RadioButton title='' index={i} selIndex={selRest} onClick={(b: number) => {
												if (!isLoading) {
													setSelRest(b);
												}
											}}></RadioButton>
											<Space size={5} isAutoResize={true}></Space>
										</div>
									</div>;
								}} adapterHeight={35} selItemIndex={selRest} onSelectItem={(i: number) => {
									if (!isLoading) {
										setSelRest(i);
									}
								}} />
							</div>))}
							<Space size={10} isAutoResize={false}></Space>
							<div className='Purchases-row'>
								<DatePicker ref={dateRef} label={'Date of Purchase'} hasPast={true}></DatePicker>
								<Space size={10} isAutoResize={false}></Space>
								<TextField ref={billId} isLoading={isLoading}
									label={'Enter the bill number'}
									inputType={TextFieldInputTypes.name}></TextField>
							</div>
							<Space size={10} isAutoResize={false}></Space>
							<label className='Purchases-detail-perc'>Select the supplier</label>
							<Space size={10} isAutoResize={false}></Space>
							{isLoading ? (<div className='listview-wrapper'>
								<ListView dummy={3} adapter={function (i, item): JSX.Element {
									return <div className='listview-item-adapter'>
										<label className='listview-item-adapter-index-load'>00</label>
										<Space size={5} isAutoResize={true}></Space>
										<div className='listview-item-adapter-card-load'>
											<label className='listview-item-adapter-title-load'>{'Tax Percentage : ${}%'}</label>
										</div>
									</div>;
								}} selItemIndex={-1} adapterHeight={35} />
							</div>) : (<div className='listview-wrapper'>
								<ListView data={suData} adapter={function (i: number, item: SuppliersModel | number): JSX.Element {
									const itemData = item as SuppliersModel;
									return <div className='listview-item-adapter'>
										<label className='listview-item-adapter-index'>{i + 1}</label>
										<Space size={5} isAutoResize={true}></Space>
										<div className='listview-item-adapter-card'>
											<label className='listview-item-adapter-title'>{`${itemData.name}`}</label>
											<RadioButton title='' index={i} selIndex={selSup} onClick={(b: number) => {
												if (!isLoading) {
													setSelSup(b);
												}
											}}></RadioButton>
											<Space size={5} isAutoResize={true}></Space>
										</div>
									</div>;
								}} adapterHeight={35} selItemIndex={selSup} onSelectItem={(i: number) => {
									if (!isLoading) {
										setSelSup(i);
									}
								}} />
							</div>)}
							<Space size={10} isAutoResize={false}></Space>
							{materialsUi()}
							<Space size={50} isAutoResize={false}></Space>
						</div>}
					</div>
					<SnackRibbon ref={snackBarRibbon}></SnackRibbon>
				</div>)}
			</RestaurantContext.Consumer>
		</div >
	);
};

export default Purchases;
