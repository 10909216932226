import React, {useEffect, useRef, useState} from 'react';
import './ChangesLog.css';
import Space from '../../components/Space/Space';
import ListView from '../../components/ListView/ListView';
import SnackRibbon, {type SnackRibbonHandle} from '../../components/SnackRibbon/SnackRibbon';
import JsonDisplay from '../../components/JsonDisplay/JsonDisplay';
import {type ChangesLogModel} from '../../models/changeslog';
import ChangesLogService from '../../services/changeslog';

type CustomStyleType = {
	'--justifyContent': string;
};

type ExtendedDivStyle = React.CSSProperties & CustomStyleType;

const ChangesLog = () => {
	const [data, setData] = useState<ChangesLogModel[]>([]);
	const [selItemIndex, setItemIndex] = useState<number>(-1);
	const [isLoading, setLoading] = useState<boolean>(true);

	const snackBarRibbon = useRef<SnackRibbonHandle>(null);

	useEffect(() => {
		void fetchChanges();
	}, []);

	async function fetchChanges() {
		await new ChangesLogService().get().then(val => {
			if (val.hasError) {
				snackBarRibbon.current!.trigger(val.hasError, val.errorMsg!);
			} else {
				setData(val.res!.data!);
			}
		}).finally(() => {
			setLoading(false);
		});
	}

	const customStyle: ExtendedDivStyle = {
		'--justifyContent': selItemIndex === -1 ? 'center' : 'start',
	};

	return (
		<div className='ChangesLog'>
			<div className='ChangesLog-left-panel'>
				{isLoading ? (<div className='ChangesLog-left-list'>
					<ListView dummy={5} adapter={function (_, __): JSX.Element {
						return <div className='ChangesLog-list-adapter'>
							<label className='ChangesLog-list-adapter-index-load'>00</label>
							<Space size={5} isAutoResize={true}></Space>
							<div className='ChangesLog-list-adapter-card'>
								<label className='ChangesLog-list-adapter-perc-load'>{'Tax Percentage : ${}%'}</label>
								<Space size={10} isAutoResize={false}></Space>
								<label className='ChangesLog-list-adapter-update-load'>{'Updated : ${}'}</label>
							</div>
						</div>;
					}} selItemIndex={-1} adapterHeight={65} />
				</div>) : (<div className='ChangesLog-left-list'>
					<ListView data={data} adapter={function (i, item): JSX.Element {
						const itemData = item as ChangesLogModel;
						return <div className='ChangesLog-list-adapter'>
							<label className='ChangesLog-list-adapter-index'>{i + 1}</label>
							<Space size={5} isAutoResize={true}></Space>
							<div className='ChangesLog-list-adapter-card'>
								<label className='ChangesLog-list-adapter-perc'>{`${itemData.user.firstName} ${itemData.user.lastName}`}</label>
								<Space size={2} isAutoResize={false}></Space>
								<label className='ChangesLog-list-adapter-update' style={{fontSize: '8px'}}>{itemData.user.role}</label>
								<Space size={5} isAutoResize={false}></Space>
								<label className='ChangesLog-list-adapter-update'>{itemData.updatedAt}</label>
							</div>
						</div>;
					}} selItemIndex={selItemIndex} onSelectItem={i => {
						setItemIndex(i);
					}} adapterHeight={65} />
				</div>)}
			</div>
			<div className='ChangesLog-right-panel'>
				<SnackRibbon ref={snackBarRibbon}></SnackRibbon>
				<div className='ChangesLog-right-content' style={customStyle}>
					{selItemIndex === -1 && <label className='ChangesLog-right-empty-content'>No items selected</label>}
					{selItemIndex !== -1 && <div>
						<label className='ChangesLog-detail-no'>{`No : ${selItemIndex + 1}`}</label>
						<Space size={5} isAutoResize={false}></Space>
						<label className='ChangesLog-detail-perc'>{`${data[selItemIndex].user.firstName} ${data[selItemIndex].user.lastName}`}</label>
						<Space size={5} isAutoResize={false}></Space>
						<label className='ChangesLog-detail-perc'>{data[selItemIndex].user.role}</label>
						<Space size={5} isAutoResize={false}></Space>
						<label className='ChangesLog-detail-perc'>{data[selItemIndex].log}</label>
						<Space size={5} isAutoResize={false}></Space>
						<label className='ChangesLog-detail-perc'>{data[selItemIndex].method}</label>
						<Space size={10} isAutoResize={false}></Space>
						<label className='ChangesLog-detail-perc'>Restaurants</label>
						<JsonDisplay jsonData={data[selItemIndex].restaurants}></JsonDisplay>
						<Space size={15} isAutoResize={false}></Space>
					</div>}
				</div>
			</div>
		</div >
	);
};

export default ChangesLog;
