import React, {useEffect, useRef, useState} from 'react';
import './Addons.css';
import Space from '../../components/Space/Space';
import OutlineButton from '../../components/OutlineButton/OutlineButton';
import ListView, {type ListViewHandle} from '../../components/ListView/ListView';
import TextField, {type TextFieldHandle} from '../../components/TextField/TextField';
import Button, {type ButtonHandle} from '../../components/Button/Button';
import TextFieldInputTypes from '../../utils/textfield/fieldtypes';
import SnackRibbon, {type SnackRibbonHandle} from '../../components/SnackRibbon/SnackRibbon';
import {type AddonModel} from '../../models/addons';
import AddonService from '../../services/addons';
import Delete from '../../assets/icons/delete';
import {type RelToastHandle} from '../../components/RelToast/RelToast';
import {type Ingredients} from '../AddFood/AddFood';
import {type MaterialsModel} from '../../models/materials';
import MaterialsService from '../../services/materials';
import CheckBox from '../../components/CheckBox/CheckBox';
import TextFieldStyles from '../../utils/textfield/fieldstyles';
import RadioButton from '../../components/RadioButton/RadioButton';
import IconButton from '../../components/IconButton/IconButton';

type CustomStyleType = {
	'--justifyContent': string;
};

type ExtendedDivStyle = React.CSSProperties & CustomStyleType;

export type ReqAddon = {
	name: string;
	price: number;
	qty: number;
	ingredients: Array<{
		note: string;
		matId: string;
		isWeight: boolean;
		weight: number;
		nos: number;
		wastage: {
			hasWastage: boolean;
			isWeight: boolean;
			weight: number;
			nos: number;
		};
	}>;
};

const Addons = () => {
	const labelBetWeenContainerSize = 15;
	const betWeenContainerSize = 25;

	const delIcon = new Delete();

	const [isLoading, setLoading] = useState<boolean>(false);
	const [isAddAdo, setAddAdo] = useState<boolean>(false);
	const [data, setData] = useState<AddonModel[]>([]);
	const [selItemIndex, setItemIndex] = useState<number>(-1);
	const [isListLoading, setListLoading] = useState<boolean>(true);
	const [selIngred, setSelIngred] = useState<Ingredients[]>([]);
	const [matData, setMatData] = useState<MaterialsModel[]>([]);
	const [isMatLoading, setMatLoading] = useState<boolean>(true);
	const [selMat, setSelMat] = useState<number>(-1);
	const [isWeight, setIsWeight] = useState<boolean>(true);
	const [hasIngred, setHasIngred] = useState<boolean>(true);
	const matRef = useRef<ListViewHandle>(null);
	const weighField = useRef<TextFieldHandle>(null);
	const wasWeighField = useRef<TextFieldHandle>(null);
	const relToast = useRef<RelToastHandle>(null);

	const snackBarRibbon = useRef<SnackRibbonHandle>(null);
	const name = useRef<TextFieldHandle>(null);
	const price = useRef<TextFieldHandle>(null);
	const qty = useRef<TextFieldHandle>(null);
	const onSaveBtn = useRef<ButtonHandle>(null);

	useEffect(() => {
		void fetchAddos();
		void getMaterials();
	}, []);

	async function fetchAddos() {
		await new AddonService().get().then(val => {
			if (val.hasError) {
				snackBarRibbon.current!.trigger(val.hasError, val.errorMsg!);
			} else {
				setData(val.res!.data!);
			}
		}).finally(() => {
			setListLoading(false);
		});
	}

	const handleOnAddlick = () => {
		setAddAdo(true);
		setItemIndex(-1);
	};

	const getMaterials = async () => {
		setMatLoading(true);

		await new MaterialsService().get().then(val => {
			if (val.hasError) {
				relToast.current!.trigger(val.hasError, val.errorMsg!);
			} else {
				setMatData(val.res!.data!);
			}
		}).finally(() => {
			setMatLoading(false);
		});
	};

	const onAddIngredient = () => {
		if (!isLoading) {
			const weiFie1 = weighField.current!.validate();
			const weiFie2 = wasWeighField.current!.validate();

			if (selMat === -1) {
				relToast.current!.trigger(true, 'Select the material');
				matRef.current!.focus();
			} else if (!weiFie1.isValid || !weiFie2.isValid) {
				relToast.current!.trigger(true, weiFie2.isValid ? 'Add the weight or nos' : 'Add the wastage weight or nos');
			} else if (selIngred.some(v => v.matName === matData[selMat].name)) {
				relToast.current!.trigger(true, 'Ingredient already added');
			} else {
				const hasWastage = wasWeighField.current!.getValue() !== '0';
				setSelIngred([...selIngred, {
					note: 'n/a',
					matName: matData[selMat].name,
					matId: matData[selMat]._id,
					isWeight,
					weight: isWeight ? parseFloat(weighField.current!.getValue()) : 0,
					nos: isWeight ? 0 : parseFloat(weighField.current!.getValue()),
					wastage: {
						hasWastage,
						isWeight,
						weight: hasWastage ? (isWeight ? parseFloat(wasWeighField.current!.getValue()) : 0) : 0,
						nos: hasWastage ? (isWeight ? 0 : parseFloat(wasWeighField.current!.getValue())) : 0,
					},
				}]);
			}
		}
	};

	const onSave = async () => {
		const field1 = name.current!.validate();
		const field2 = price.current!.validate();
		const field3 = qty.current!.validate();
		if (!field1.isValid) {
			snackBarRibbon.current!.trigger(true, 'Enter the addon name');
			onSaveBtn.current!.complete();
		}

		if (!field2.isValid) {
			snackBarRibbon.current!.trigger(true, 'Enter the price');
			onSaveBtn.current!.complete();
		}

		if (!field3.isValid) {
			snackBarRibbon.current!.trigger(true, 'Enter the quantity');
			onSaveBtn.current!.complete();
		}

		if (field1.isValid && field2.isValid && field3.isValid) {
			setListLoading(true);

			await new AddonService().create({
				name: name.current!.getValue()!,
				price: Number(price.current!.getValue()!),
				qty: Number(qty.current!.getValue()!),
				ingredients: selIngred.map(v => ({
					note: v.note,
					matId: v.matId,
					isWeight: v.isWeight,
					weight: v.weight,
					nos: v.nos,
					wastage: {
						hasWastage: v.wastage.hasWastage,
						isWeight: v.wastage.isWeight,
						weight: v.wastage.weight,
						nos: v.wastage.nos,
					},
				})),
			}).then(async val => {
				if (val.hasError) {
					snackBarRibbon.current!.trigger(val.hasError, val.errorMsg!);
				} else {
					await fetchAddos();
					snackBarRibbon.current!.trigger(false, val.res!.message);
				}

				onSaveBtn.current!.complete();
				name.current!.clearInput();
				price.current!.clearInput();
			});
		}
	};

	const customStyle: ExtendedDivStyle = {
		'--justifyContent': (selItemIndex === -1 && !isAddAdo) ? 'center' : 'start',
	};

	const ingredForm = () => <div className='AddFood-container-column'>
		<div className='listview-wrapper' style={{height: '100px'}}>
			{selIngred.length === 0 ? <div style={{height: '100%', display: 'flex', justifyContent: 'center', alignItems: 'center'}}>
				<label className='AddFood-label-sub' style={{textAlign: 'center'}}>Add the ingredients for this variation</label>
			</div> : <ListView data={selIngred} adapter={function (i: number, item: Ingredients | number): JSX.Element {
				const itemData = item as Ingredients;
				return <div className='Addfood-horlist-item-adapter'>
					<div style={{width: '100%', display: 'flex', flexDirection: 'row', alignItems: 'center'}}>
						<label className='listview-item-adapter-index' style={{width: '100%'}}>{i + 1}</label>
						<Space size={10} isAutoResize={false}></Space>
						<IconButton icon={delIcon.normal()} onClick={() => {
							setSelIngred(prevData => prevData.filter((item, j) => j !== i));
						}}></IconButton>
					</div>
					<Space size={10} isAutoResize={false}></Space>
					<label className='listview-item-adapter-title'>{`${itemData.matName}`}</label>
					<Space size={5} isAutoResize={false}></Space>
					<label className='listview-item-adapter-title'>{`${itemData.isWeight ? itemData.weight : itemData.nos}`}</label>
				</div>;
			}} adapterHeight={90} isHorizontal={true} />}
		</div>
		<Space size={labelBetWeenContainerSize} isAutoResize={false}></Space>
		<div className='AddFood-container-row'>
			<div className='AddFood-container-column'>
				{isMatLoading ? (<div className='listview-wrapper'>
					<ListView dummy={3} adapter={function (i, item): JSX.Element {
						return <div className='listview-item-adapter'>
							<label className='listview-item-adapter-index-load'>00</label>
							<Space size={5} isAutoResize={true}></Space>
							<div className='listview-item-adapter-card-load'>
								<label className='listview-item-adapter-title-load'>{'Tax Percentage : ${}%'}</label>
							</div>
						</div>;
					}} selItemIndex={-1} adapterHeight={35} />
				</div>) : (<div className='listview-wrapper'>
					<ListView ref={matRef} data={matData} adapter={function (i: number, item: MaterialsModel | number): JSX.Element {
						const itemData = item as MaterialsModel;
						return <div className='listview-item-adapter'>
							<label className='listview-item-adapter-index'>{i + 1}</label>
							<Space size={5} isAutoResize={true}></Space>
							<div className='listview-item-adapter-card'>
								<label className='listview-item-adapter-title'>{`${itemData.name}`}</label>
								<RadioButton title={''} index={i} selIndex={selMat} onClick={(j: number) => {
									if (!isLoading) {
										setSelMat(j);
									}
								}} ></RadioButton>
								<Space size={5} isAutoResize={true}></Space>
							</div>
						</div>;
					}} adapterHeight={35} selItemIndex={selMat} onSelectItem={(j: number) => {
						if (!isLoading) {
							setSelMat(j);
						}
					}} />
				</div>)}
			</div>
			<Space size={5} isAutoResize={true}></Space>
			<div className='AddFood-container-column'>
				<CheckBox title={'Unit is Weight?'} initVal={isWeight} onClick={(b: boolean) => {
					if (!isLoading) {
						setIsWeight(b);
					}
				}}></CheckBox>
				<Space size={15} isAutoResize={false}></Space>
				<div className='AddFood-container-row'>
					<TextField ref={weighField} isLoading={isLoading}
						label={isWeight ? 'Weight' : 'Nos'} styles={TextFieldStyles.indiTitle} placeholder={isWeight ? 'I.e: 1.5' : 'I.e: 1'}
						inputType={isWeight ? TextFieldInputTypes.floatnumber : TextFieldInputTypes.number}></TextField>
					<Space size={15} isAutoResize={false}></Space>
					<TextField ref={wasWeighField} isLoading={isLoading}
						label={`Wastage (approx ${isWeight ? 'weight)' : 'nos)'}`} styles={TextFieldStyles.indiTitle} placeholder={isWeight ? 'I.e: 1.5' : 'I.e: 1'}
						inputType={isWeight ? TextFieldInputTypes.floatnumber : TextFieldInputTypes.number}></TextField>
				</div>
				<Space size={15} isAutoResize={false}></Space>
				<Button onClick={function (): void {
					onAddIngredient();
				}} label={'Add Ingredient'}></Button>
			</div>
		</div>
	</div>;

	return (
		<div className='Addons'>
			<div className='addons-left-panel'>
				<div className='addons-left-tools'>
					<label className='addons-count-label'>{`Total : ${data.length}`}</label>
					<OutlineButton onClick={handleOnAddlick} label={'Add Addon'}></OutlineButton>
				</div>
				{isListLoading ? (<div className='addons-left-list'>
					<ListView dummy={5} adapter={function (i, item): JSX.Element {
						return <div className='addons-list-adapter'>
							<label className='addons-list-adapter-index-load'>00</label>
							<Space size={5} isAutoResize={true}></Space>
							<div className='addons-list-adapter-card'>
								<label className='addons-list-adapter-update-load'>{'Tax Percentage'}</label>
							</div>
						</div>;
					}} selItemIndex={-1} adapterHeight={40} />
				</div>) : (<div className='addons-left-list'>
					<ListView data={data} adapter={function (i, item): JSX.Element {
						const itemData = item as AddonModel;
						return <div className='addons-list-adapter'>
							<label className='addons-list-adapter-index'>{i + 1}</label>
							<Space size={5} isAutoResize={true}></Space>
							<div className='addons-list-adapter-card'>
								<label className='addons-list-adapter-perc'>{itemData.name}</label>
							</div>
						</div>;
					}} selItemIndex={selItemIndex} onSelectItem={i => {
						setAddAdo(false);
						setItemIndex(i);
					}} adapterHeight={40} />
				</div>)}
			</div>
			<div className='addons-right-panel'>
				<SnackRibbon ref={snackBarRibbon}></SnackRibbon>
				<div className='addons-right-content' style={customStyle}>
					{selItemIndex === -1 && !isAddAdo && <label className='addons-right-empty-content'>No items selected</label>}
					{selItemIndex !== -1 && <div>
						<label className='addons-detail-no'>{`No : ${selItemIndex + 1}`}</label>
						<Space size={5} isAutoResize={true}></Space>
						<label className='addons-detail-perc'>{data[selItemIndex].name}</label>
						<Space size={5} isAutoResize={true}></Space>
						<label className='addons-detail-perc'>{`Price : ${data[selItemIndex].price}`}</label>
					</div>}
					{isAddAdo && <div>
						<Button ref={onSaveBtn} label={'Save'} onClick={onSave}></Button>
						<Space size={20} isAutoResize={false}></Space>
						<TextField ref={name} isLoading={isListLoading}
							label={'Enter the name'}
							inputType={TextFieldInputTypes.name}></TextField>
						<Space size={10} isAutoResize={false}></Space>
						<TextField ref={price} isLoading={isListLoading}
							label={'Enter the price'}
							inputType={TextFieldInputTypes.floatnumber} options={{maxLength: 5}}></TextField>
						<Space size={10} isAutoResize={false}></Space>
						<TextField ref={qty} isLoading={isListLoading}
							label={'Quantity per user'}
							inputType={TextFieldInputTypes.floatnumber} options={{maxLength: 10}}></TextField>
						<Space size={10} isAutoResize={false}></Space>
						<CheckBox title={'Has ingredients?'} initVal={hasIngred} onClick={(b: boolean) => {
							if (!isLoading) {
								setHasIngred(b);
							}
						}}></CheckBox>
						<Space size={20} isAutoResize={false}></Space>
						{hasIngred && <div className='AddFood-container-column'>
							<label className='AddFood-label'>Ingredients</label>
							<Space size={labelBetWeenContainerSize} isAutoResize={false}></Space>
							{ingredForm()}
						</div>}
					</div>}
				</div>
			</div>
		</div >
	);
};

export default Addons;
