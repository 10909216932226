import React, {forwardRef, useRef, useState} from 'react';
import './ToogleButton.css';

type Props = {
	onClick: (b: boolean) => void;
	isLoading?: boolean;
	init?: boolean;
	width?: string;
	isDisabled?: boolean;
};

export type ToogleButtonHandle = Record<string, unknown>;

type CustomStyleType = {
	'--width': string;
};

type ExtendedDivStyle = React.CSSProperties & CustomStyleType;

const ToogleButton = forwardRef<ToogleButtonHandle, Props>((props, ref) => {
	const [isOn, setIsOn] = useState(props.init ?? false);

	const handleToggle = () => {
		if (!props.isDisabled) {
			if (!props.isLoading) {
				props.onClick(!isOn);
			}

			setIsOn(!isOn);
		}
	};

	const style: ExtendedDivStyle = {
		'--width': props.width ?? '32px',
	};

	return (<div className='ToogleButton-switch' style={style} onClick={handleToggle} >
		<div className={props.isDisabled ? 'ToogleButton-switch-frame disabled' : isOn ? 'ToogleButton-switch-frame active' : 'ToogleButton-switch-frame inactive'}>
			<div className={isOn ? 'ToogleButton-switch-roll active' : 'ToogleButton-switch-roll inactive'} ></div>
		</div>
	</div>);
});

ToogleButton.displayName = 'ToogleButton';

export default ToogleButton;
